export default {
  'login.form.title': 'Đăng nhập BasicEx',
  'register.form.title': 'Đăng ký BasicEx',
  'login.form.email': 'Email',
  'login.form.phone': 'Số điện thoại',
  'login.account': 'Chưa có tài khoản?',
  'register.account': 'Đã có tài khoản?',
  'login.register': 'Đăng ký ngay',
  'register.login': 'Đăng nhập ngay',
  'login.forget': 'Quên mật khẩu',
  'login.pass': 'Mật khẩu',
  'login.form.userName.errMsg': 'Email không được để trống',
  'login.form.phone.errMsg': 'Số điện thoại không được để trống',
  'login.form.userName.placeholder': 'Vui lòng nhập email',
  'login.form.phone.userName.placeholder': 'Vui lòng nhập số điện thoại',
  'login.form.password.errMsg': 'Mật khẩu không được để trống',
  'login.form.password.placeholder': 'Vui lòng nhập mật khẩu',
  'login.form.code.errMsg': 'Mã xác thực không được để trống',
  'login.form.code.placeholder': 'Vui lòng nhập mã xác thực',
  'login.form.code.sent': 'Gửi mã xác thực',
  'login.form.code.time': 'giây để thử lại',
  'login.form.read': 'Tôi đã đọc và đồng ý với BasicEx',
  'login.form.ServiceAgreement': 'Điều khoản dịch vụ ',
  'login.form.and': 'và',
  'login.form.PrivacyPolicy': 'Chính sách bảo mật ',
  'login.form.login': 'Đăng nhập',
  'register.form.register': 'Đăng ký',
  'login.form.code.tip': 'Mã xác thực phải là 6 chữ số',
  'login.form.code.read':
    'Vui lòng đọc và đồng ý với Điều khoản dịch vụ và Chính sách bảo mật',
  'register.password.different': 'Hai mật khẩu nhập không khớp',
  'register.form': 'Hai mật khẩu nhập không khớp',
  'register.email': 'Vui lòng nhập email hợp lệ',
  'register.phone': 'Vui lòng nhập số điện thoại hợp lệ',
  'register.password':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ số và chữ cái viết hoa và thường',
  'forget.password': 'Đặt lại mật khẩu',
  'forget.input': 'Vui lòng nhập email/số điện thoại của bạn',
  'login.user': 'Email/Số điện thoại',
  'forget.input-err': 'Email/Số điện thoại không được để trống',
  'forget.newPassword': 'Mật khẩu mới',
  'forget.newPasswordCopy': 'Xác nhận mật khẩu',
  'forget.success': 'Đổi mật khẩu thành công',
  'forget.login': 'Vui lòng đăng nhập bằng mật khẩu mới',
  'ver.number': 'Xác thực bảo mật',
  'ver.OTP_CODE': 'Ứng dụng Google Authenticator',
  'ver.EMAIL_CODE': 'Mã xác thực email',
  'ver.PHONE_CODE': 'Mã xác thực điện thoại',
  'ver.EMAIL': 'Email',
  'ver.PHONE': 'Điện thoại',
  'ver.PIN_CODE': 'Mã PIN thanh toán',
  'ver.code-err': 'Mã xác thực không được để trống',
  'ver.welcome': 'Chào mừng trở lại',
  'res.text1': 'Tạo tài khoản cá nhân',
};
