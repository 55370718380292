<template>
  <a-config-provider :locale="locale">
    <router-view />
    <global-setting />
  </a-config-provider>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';

  import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn';
  import zhTW from '@arco-design/web-vue/es/locale/lang/zh-tw';
  import enUS from '@arco-design/web-vue/es/locale/lang/en-us';
  // import viVN from '@arco-design/web-vue/es/locale/lang/vi-vn';
  import GlobalSetting from '@/components/global-setting/index.vue';
  import useLocale from '@/hooks/locale';

  const { currentLocale } = useLocale();

  const locale = computed(() => {
    switch (currentLocale.value) {
      case 'zh-CN':
        return zhCN;
      case 'en':
        return enUS;
      // case 'vi':
      //   return viVN;
      case 'zh-TW':
        return zhTW;
      default:
        return enUS;
    }
  });
</script>
