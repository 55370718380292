export default {
  'login.form.title': 'BasicEx लॉगिन',
  'register.form.title': 'BasicEx रजिस्टर',
  'login.form.email': 'ईमेल',
  'login.form.phone': 'फोन नंबर',
  'login.account': 'क्या आपके पास कोई अकाउंट नहीं है？',
  'register.account': 'क्या आपके पास पहले से कोई अकाउंट है？',
  'login.register': 'अभी रजिस्टर करें',
  'register.login': 'अभी लॉगिन करें',
  'login.forget': 'पासवर्ड भूल गए',
  'login.pass': 'पासवर्ड',
  'login.form.userName.errMsg': 'ईमेल नहीं हो सकता',
  'login.form.phone.errMsg': 'फोन नंबर नहीं हो सकता',
  'login.form.userName.placeholder': 'ईमेल दर्ज करें',
  'login.form.phone.userName.placeholder': 'फोन नंबर दर्ज करें',
  'login.form.password.errMsg': 'पासवर्ड नहीं हो सकता',
  'login.form.password.placeholder': 'पासवर्ड दर्ज करें',
  'login.form.code.errMsg': 'वेरिफिकेशन कोड नहीं हो सकता',
  'login.form.code.placeholder': 'वेरिफिकेशन कोड दर्ज करें',
  'login.form.code.sent': 'वेरिफिकेशन कोड भेजा गया',
  'login.form.code.time': 'सेकंड में पुनः प्रयास करें',
  'login.form.read': 'मैंने BasicEx के',
  'login.form.ServiceAgreement': 'सेवा नियम ',
  'login.form.and': 'और',
  'login.form.PrivacyPolicy': 'प्राइवेसी पॉलिसी ',
  'login.form.login': 'लॉगिन',
  'register.form.register': 'रजिस्टर',
  'login.form.code.tip': 'वेरिफिकेशन कोड 6 अंक का होना चाहिए',
  'login.form.code.read': 'सेवा नियम और प्राइवेसी पॉलिसी को पढ़ें और सहमत हैं',
  'register.password.different': 'दो बार दर्ज किए गए पासवर्ड मेल नहीं खाते',
  'register.form': 'दो बार दर्ज किए गए पासवर्ड मेल नहीं खाते',
  'register.email': 'सही ईमेल दर्ज करें',
  'register.phone': 'सही फोन नंबर दर्ज करें',
  'register.password':
    'पासवर्ड 8 कैरेक्टर से अधिक होना चाहिए, और इसमें नंबर और लेटर (कैपिटल और स्मॉल) होने चाहिए',
  'forget.password': 'पासवर्ड रीसेट',
  'forget.input': 'अपना ईमेल/फोन नंबर दर्ज करें',
  'login.user': 'ईमेल/फोन नंबर',
  'forget.input-err': 'ईमेल/फोन नंबर नहीं हो सकता',
  'forget.newPassword': 'नया पासवर्ड',
  'forget.newPasswordCopy': 'पासवर्ड की पुष्टि कीजिए',
  'forget.success': 'पासवर्ड सफल हुआ',
  'forget.login': 'नया पासवर्ड का उपयोग कर लॉगिन कीजिए',
  'ver.number': 'सुरक्षा वेरिफिकेशन',
  'ver.OTP_CODE': 'गूगल वेरिफिकेशन एप्प',
  'ver.EMAIL_CODE': 'ईमेल वेरिफिकेशन कोड',
  'ver.PHONE_CODE': 'फोन वेरिफिकेशन कोड',
  'ver.EMAIL': 'ईमेल',
  'ver.PHONE': 'फोन',
  'ver.PIN_CODE': 'पेमेंट पिन कोड',
  'ver.code-err': 'वेरिफिकेशन कोड नहीं हो सकता',
  'ver.welcome': 'स्वागत है',
  'res.text1': 'व्यक्तिगत अकाउंट बनाएं',
};
