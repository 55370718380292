export default {
  'login.form.title': '币趣登录',
  'register.form.title': '币趣注册',
  'login.form.email': '邮箱',
  'login.form.phone': '手机号',
  'login.account': '还没账号？',
  'register.account': '已有账号？',
  'login.register': '立即注册',
  'register.login': '立即登录',
  'login.forget': '忘记密码',
  'login.pass': '密码',
  'login.form.userName.errMsg': '电子邮箱不能为空',
  'login.form.phone.errMsg': '手机号不能为空',
  'login.form.userName.placeholder': '请输入电子邮箱',
  'login.form.phone.userName.placeholder': '请输入手机号',
  'login.form.password.errMsg': '密码不能为空',
  'login.form.password.placeholder': '请输入密码',
  'login.form.code.errMsg': '验证码不能为空',
  'login.form.code.placeholder': '请输入验证码',
  'login.form.code.sent': '发送验证码',
  'login.form.code.time': '秒后重试',
  'login.form.read': '我已阅读并同意币趣的',
  'login.form.ServiceAgreement': '服务条款 ',
  'login.form.and': '和',
  'login.form.PrivacyPolicy': '隐私政策 ',
  'login.form.login': '登录',
  'register.form.register': '注册',
  'login.form.code.tip': '验证码必须为6位数字',
  'login.form.code.read': '请阅读并同意服务条款和隐私政策',
  'register.password.different': '两次输入的密码不一致',
  'register.form': '两次输入的密码不一致',
  'register.email': '请输入正确的邮箱',
  'register.phone': '请输入正确的手机号',
  'register.password': '密码需要8位以上字符组成,必须包含数字及字母大小写',
  'forget.password': '重置密码',
  'forget.input': '请输入您的邮箱/手机号码',
  'login.user': '邮箱/手机号码',
  'forget.input-err': '邮箱/手机号码不能为空',
  'forget.newPassword': '新密码',
  'forget.newPasswordCopy': '确认密码',
  'forget.success': '密码修改成功',
  'forget.login': '请使用新密码登录',
  'ver.number': '安全验证',
  'ver.OTP_CODE': '谷歌验证APP',
  'ver.EMAIL_CODE': '邮箱验证码',
  'ver.PHONE_CODE': '手机验证码',
  'ver.EMAIL': '邮箱',
  'ver.PHONE': '手机',
  'ver.PIN_CODE': '支付PIN码',
  'ver.code-err': '验证码不能为空',
  'ver.welcome': '欢迎回来',
  'res.text1': '创建个人账户',
};
