import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/fundmanagement',
  name: 'FundManagement',
  component: DEFAULT_LAYOUT,
  redirect: '/fundmanagement/balance',
  meta: {
    locale: 'navbar.index.dtm61i',
    requiresAuth: true,
    icon: 'icon-capital',
    order: 4,
    title: 'navbar.index.dtm61i',
  },
  children: [
    {
      path: 'balance',
      name: 'Balance',
      component: () => import('@/views/fundmanagement/balance/index.vue'),
      meta: {
        locale: 'menu.balance',
        hideInMenu: false,
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'coupon',
      name: 'Coupon',
      component: () => import('@/views/fundmanagement/balance/coupon.vue'),
      meta: {
        locale: 'menu.balance',
        hideInMenu: true,
        requiresAuth: true,
        roles: ['*'],
        activeMenu: 'Balance',
      },
    },
    {
      // path: 'buyCoins',
      path: 'https://basicex.com/financial',
      name: 'FinancialChild',
      component: () => import('@/views/Financial/index.vue'),
      meta: {
        locale: 'menu.financial',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'flashExchange',
      name: 'FlashExchange',
      component: () => import('@/views/fundmanagement/balance/flash.vue'),
      meta: {
        locale: 'menu.recharge',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
        activeMenu: 'Balance',
      },
    },
    {
      path: 'flashExchange',
      name: 'FlashExchange',
      component: () => import('@/views/fundmanagement/balance/flash.vue'),
      meta: {
        locale: 'menu.recharge',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
        activeMenu: 'Balance',
      },
    },
    {
      path: 'flashHistory',
      name: 'FlashHistory',
      component: () =>
        import('@/views/fundmanagement/balance/flashHistory.vue'),
      meta: {
        locale: 'menu.moneywallet',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
        activeMenu: 'Balance',
      },
    },
    {
      path: 'funRecharge',
      name: 'FunRecharge',
      component: () => import('@/views/fundmanagement/recharge/index.vue'),
      meta: {
        locale: 'menu.recharge',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      // path: 'buyCoins',
      path: 'https://p2p.basicex.com',
      name: 'BuyCoins',
      component: () => import('@/views/fundmanagement/buycoins/index.vue'),
      meta: {
        locale: 'menu.buycoins',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'collection',
      name: 'Collection',
      component: () => import('@/views/fundmanagement/collection/index.vue'),
      meta: {
        locale: 'menu.buycoins',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'transferAccounts',
      name: 'TransferAccounts',
      component: () =>
        import('@/views/fundmanagement/transferaccounts/index.vue'),
      meta: {
        locale: 'menu.transferaccounts',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'withdrawal',
      name: 'Withdrawal',
      component: () => import('@/views/fundmanagement/withdrawal/index.vue'),
      meta: {
        locale: 'menu.withdrawal',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'user',
      name: 'User',
      component: () => import('@/views/fundmanagement/withdrawal/user.vue'),
      meta: {
        requiresAuth: false,
        hideInMenu: true,
      },
    },
  ],
};

export default DASHBOARD;
