export default {
  'login.privacy.kc5k20': '隐私声明-币趣',
  'login.privacy.6tvu9q': '最后更新：2022 年 9 月 29 日',
  'login.privacy.w84kwf':
    '币趣（“币趣”、“我们”或“我们”）致力于保护客户的隐私，我们以最严肃的态度履行我们的数据保护责任。',
  'login.privacy.h5v8f9':
    '本隐私声明描述了币趣如何通过引用本隐私声明的币趣网站和应用程序收集和处理您的个人信息。币趣是指由币趣网站（其域名包括但不限于',
  'login.privacy.jt2p1r':
    ')、移动应用程序、客户端、小程序和其他为提供币趣服务而开发的应用程序，包括生态系统内独立运营的平台、网站和客户端。“币趣运营者”是指运营币趣的各方，包括但不限于提供币趣服务并对该等服务负责的法人、非法人组织和团队。本隐私声明中使用的“币趣”包括币趣运营商。',
  'login.privacy.y2v430':
    '本隐私声明适用于我们在币趣和币趣运营商的平台、网站和部门中开展的所有个人信息处理活动。',
  'login.privacy.136m95':
    '如果您是我们服务的客户或用户，则本隐私声明与任何业务条款和其他合同文件一起适用，包括但不限于我们可能与您达成的任何协议。',
  'login.privacy.8d2hxu':
    '如果您不是我们服务的相关利益相关者、客户或用户，但正在使用我们的网站，则本隐私声明也与我们的 Cookie 声明一起适用于您。',
  'login.privacy.j38v8e':
    '因此，本通知应与我们的 Cookie 通知一起阅读，后者提供了有关我们在网站上使用 cookie 的更多详细信息。我们的 Cookie 通知可在',
  'login.privacy.bwm3z8': '此处',
  'login.privacy.6iqyl1': '访问。',
  'login.privacy.7pp7ut': '1. Basicex 与您的关系',
  'login.privacy.j24gj3':
    'Hola Limited 是一家注册于 2004 N Wahsatch Ave Colorado Springs 的公司，是在全球提供 BasicEx 服务时收集的个人信息的数据控制者。',
  'login.privacy.899gsg':
    '但是，根据您的合法居住地，某些其他币趣实体可能会参与我们向您提供服务所必需的处理活动，例如了解您的客户（“KYC”）活动。例如，如果您居住在法国，Basicex France SAS 将负责运行 KYC 检查。这些币趣实体可以充当您个人信息的控制者，并根据本隐私声明使用这些信息。',
  'login.privacy.90x098':
    '2. 币趣收集和处理哪些个人信息？为什么币趣要处理我的个人信息？我们使用个人信息的法律依据是什么？',
  'login.privacy.9sy521': '币趣收集和处理哪些个人信息？',
  'login.privacy.7461j8': '- 电子邮件地址;',
  'login.privacy.4too95': '- 名称;',
  'login.privacy.33lk4c': '- 性别;',
  'login.privacy.3339bi': '- 出生日期;',
  'login.privacy.qhie5b': '- 家庭地址;',
  'login.privacy.cw62k4': '- 电话号码;',
  'login.privacy.1u0160': '- 国籍;',
  'login.privacy.y6wwu0': '- 设备编号;',
  'login.privacy.q3j1v2': '- 您的视频记录和照片；',
  'login.privacy.n97555': '- 交易信息；',
  'login.privacy.mp8l0n':
    '- 用于将您的计算机连接到互联网的互联网协议 (IP) 地址；',
  'login.privacy.88f199':
    '- 您的设备或计算机的登录名、电子邮件地址、密码和位置；',
  'login.privacy.q8tmgw':
    '- 币趣服务指标（例如，技术错误的发生、您与服务功能和内容的交互，以及您的设置偏好）；',
  'login.privacy.887248': '- 版本和时区设置；',
  'login.privacy.51168r': '- 交易历史；',
  'login.privacy.7x7mre':
    '- 来自其他来源的信息：我们可能会从其他来源收到关于您的信息，例如来自征信机构的信用历史信息；',
  'login.privacy.l61c4m':
    '- 有关您的活动的信息，我们可能出于营销和广告目的处理有关您的行为和活动的信息。',
  'login.privacy.t4t7ib': '为什么币趣要处理我的个人信息？',
  'login.privacy.g5blre':
    '- 交易服务。我们使用您的个人信息来处理您的订单，并与您就订单和服务进行沟通；',
  'login.privacy.iol4kr':
    '- 与您沟通。我们使用您的个人信息与您就币趣服务进行沟通；',
  'login.privacy.bhw512':
    '- 我们收集和处理身份信息和敏感个人数据（详见第一节），以遵守适用法律法规和反洗钱法律法规规定的了解您的客户（“KYC”）义务；',
  'login.privacy.7h61j0':
    '- 提供、排除故障并改进币趣服务。我们使用您的个人信息来提供功能、分析性能、修复错误并提高币趣服务的可用性和有效性。',
  'login.privacy.tc88dj':
    '欺诈预防和信用风险。我们处理个人信息以防止和检测欺诈和滥用行为，以保护我们的用户、币趣服务和其他人的安全。我们也可能使用评分方法来评估和管理信用风险。',
  'login.privacy.lxo82j':
    '法律义务；遵守我们在适用法律法规和反洗钱法律法规下的法律义务',
  'login.privacy.ngs86z':
    '我们的合法利益和我们用户的利益，例如，当我们检测并防止欺诈和滥用以保护我们的用户、我们自己或他人的安全时；',
  'login.privacy.371qe3':
    '- 改善我们的服务。我们处理个人信息以改善我们的服务并为您提供更好的用户体验；',
  'login.privacy.rrenk2':
    '- 推荐和个性化。我们使用您的个人信息来推荐您可能感兴趣的功能和服务，确定您的偏好，并为您提供个性化的币趣服务体验；',
  'login.privacy.4s94lw': '我们使用个人信息的法律依据？',
  'login.privacy.d2n7p9':
    '当我们向您提供产品或服务，或与您就这些产品或服务进行沟通时，',
  'login.privacy.8b3j23': '合同的履行',
  'login.privacy.v8vr6v':
    '。这包括我们使用您的个人信息接受和处理订单以及处理付款的情况。',
  'login.privacy.l08s6u': '法律义务；',
  'login.privacy.76y1f8':
    '遵守我们在适用法律法规和反洗钱法律法规下的法律义务。',
  'login.privacy.2262a5':
    '当我们征求您的同意以出于我们向您传达的特定目的处理您的个人信息时，您的同意。当您同意为特定目的处理您的个人信息时，您可以随时撤回您的同意，我们将停止为该目的处理您的个人信息。撤回同意不影响撤回前基于同意的处理的合法性。',
  'login.privacy.76ht3q': '我们的合法利益',
  'login.privacy.24o658':
    '和我们用户的利益，例如，当我们检测并防止欺诈和滥用以保护我们的用户、我们自己或他人的安全时；',
  'login.privacy.3nh31t':
    '这包括我们使用您的个人信息接受和处理订单以及处理付款的情况。',
  'login.privacy.1d6w06': '遵守我们在适用法律法规和反洗钱法律法规下的法律义务',
  'login.privacy.538m2i': '我们改善服务的',
  'login.privacy.5qqw26': '合法利益；',
  'login.privacy.wu2u8m':
    '当我们征求您的同意以出于我们向您传达的特定目的处理您的个人信息时，您的同意。当您同意为特定目的处理您的个人信息时，您可以随时撤回您的同意，我们将停止为该目的处理您的个人信息。撤回同意不影响撤回前基于同意的处理的合法性',
  'login.privacy.7c1542': '3. 儿童可以使用币趣服务吗？',
  'login.privacy.o3744e':
    '币趣不允许 18 岁以下的任何人使用币趣服务，也不会故意收集 18 岁以下儿童的个人信息。',
  'login.privacy.62766e': '4. Cookie 和其他标识符呢？',
  'login.privacy.p36e61':
    '我们使用 cookie 和类似工具来增强您的用户体验、提供我们的服务、加强我们的营销工作并了解客户如何使用我们的服务，以便我们进行改进。根据您所在地区的适用法律，浏览器上的 cookie 横幅会告诉您如何接受或拒绝 cookie。我们的 cookie 政策的副本可',
  'login.privacy.me8fdq': '在此处',
  'login.privacy.gvb101': '获得。',
  'login.privacy.om6043': '5. 币趣会分享我的个人信息吗？',
  'login.privacy.n7411y':
    '如果我们认为共享您的个人数据符合与您或我们之间的任何合同关系、适用法律、法规或法律程序，或受其要求，我们可能会与第三方（包括其他币趣实体）共享您的个人数据。在与其他币趣实体共享您的个人信息时，我们将尽最大努力确保此类实体受本隐私声明的约束，或遵循至少与本隐私声明中所述的保护措施同等的做法。例如，如果您居住在法国，basicex France SAS 将负责运行 KYC 检查。',
  'login.privacy.3be551': '我们还可能与以下人员共享个人信息：',
  'login.privacy.wxfkk9': 'i.第三方服务提供商：',
  'login.privacy.l5dwy9':
    '我们聘请其他公司和个人代表我们履行职能。示例包括分析数据、提供营销协助、处理付款、传输内容以及评估和管理信用风险。这些第三方服务提供商只能访问执行其功能所需的个人信息，但不得将其用于其他目的。此外，他们必须根据我们的合同协议并仅在适用的数据保护法允许的情况下处理个人信息。',
  'login.privacy.03ph7w': 'ii.法律机构：',
  'login.privacy.u71msj':
    '法律或法院可能要求我们向相关监管机构、执法机构和/或其他主管机构披露有关您的某些信息或我们可能与您进行的任何约定。我们将在法律规定我们有义务这样做的范围内向法律当局披露有关您的信息。我们可能还需要共享您的信息，以执行或应用我们的合法权利或防止欺诈。',
  'login.privacy.s3ag79': 'iii.业务转让：',
  'login.privacy.egltmf':
    '随着我们继续发展业务，我们可能会出售或购买其他业务或服务。在此类交易中，用户信息通常是转让的商业资产之一，但仍受任何先前存在的隐私声明中所做承诺的约束（当然，除非用户另行同意）。此外，在不太可能发生的情况下，basicex 或其几乎所有资产都被第三方收购，用户信息将成为转移的资产之一。',
  'login.privacy.8w483x': 'iiii.保护币趣和其他人：',
  'login.privacy.0882m2':
    '当我们认为发布账户和其他个人信息对于遵守法律或我们的监管义务是适当的时，我们会发布账户和其他个人信息；执行或应用我们的使用条款和其他协议；或保护币趣、我们的用户或其他人的权利、财产或安全。这包括与其他公司和组织交换信息以防止欺诈和降低信用风险。',
  'login.privacy.u0i119': '6. 个人信息的国际传输',
  'login.privacy.j5rk66':
    '为促进我们的全球运营，币趣可能会将您的个人信息转移到欧洲经济区（“EEA”）、英国和瑞士以外的地区。欧洲经济区包括欧盟国家以及冰岛、列支敦士登和挪威。欧洲经济区以外的转移有时被称为“第三国转移”。',
  'login.privacy.v8krs1':
    '我们可能会在我们遍布全球的附属机构、第三方合作伙伴和服务提供商内部传输您的个人数据。如果我们打算将个人数据传输到 EEA 以外的第三国或国际组织。币趣采取适当的技术、组织和合同保障措施（包括标准合同条款），以确保此类传输符合适用的数据保护规则，除非个人信息的传输目的地国家/地区已由欧盟委员会提供足够水平的保护。',
  'login.privacy.5m9f17':
    '我们还依赖欧盟委员会的决定，他们承认欧洲经济区以外的某些国家和地区确保对个人信息提供足够的保护。这些决定被称为“充分性决定”。我们根据日本充分性决定将个人数据传输到日本。',
  'login.privacy.h7q0ka': '7. 我的信息有多安全？',
  'login.privacy.383i4y':
    '我们在设计系统时考虑到您的安全和隐私。我们已采取适当的安全措施，以防止您的信息意外丢失、以未经授权的方式使用或访问、更改或披露。我们致力于通过使用加密协议和软件在传输和存储过程中保护您的个人信息的安全。我们维护与收集、存储和披露您的个人信息相关的物理、电子和程序保护措施。此外，我们将访问您的个人信息的权限限制为那些有业务需要知道的员工、代理人、承包商和其他第三方。',
  'login.privacy.gw69m4':
    '我们的安全程序意味着我们可能会要求您验证您的身份，以保护您免遭未经授权访问您的帐户密码。我们建议为您的币趣账户使用其他在线账户未使用的唯一密码，并在您使用完共享计算机后注销。',
  'login.privacy.2t39my': '8. 关于广告',
  'login.privacy.hos1v1':
    '为了让我们为您提供最佳的用户体验，我们可能会出于定位、建模和/或分析以及营销和广告的目的与我们的营销合作伙伴共享您的个人信息。您有权随时反对出于直接营销目的处理您的个人信息（请参阅下文第 9 节）。',
  'login.privacy.q1meb8': '9. 我有什么权利？',
  'login.privacy.3nsijb': '根据适用法律',
  'login.privacy.dfog7r':
    '，如下所述，您拥有多项与您的隐私和个人信息保护相关的权利。您有权要求访问、更正和删除您的个人信息，并要求数据可移植性。您也可以反对我们处理您的个人信息，或要求我们在某些情况下限制处理您的个人信息。此外，当您同意我们为特定目的处理您的个人信息时，您可以随时撤回您的同意。如果您想行使您的任何权利，请通过',
  'login.privacy.403iph':
    '联系我们。这些权利在某些情况下可能会受到限制 - 例如，我们可以证明我们有处理您的个人数据的法律要求。',
  'login.privacy.5qenjk': '访问权：',
  'login.privacy.yr1p1w':
    '您有权获得有关您的个人信息已被处理的确认信息，并有权获得其副本以及与其处理相关的某些信息；',
  'login.privacy.w1vnb1': '更正权：',
  'login.privacy.wvx4mx':
    '您可以要求对不准确的个人信息进行更正，也可以进行补充。您还可以随时更改您帐户中的个人信息。',
  'login.privacy.9ozu8u': '删除权：',
  'login.privacy.1xzk2i': '在某些情况下，您可以要求删除您的个人信息；',
  'login.privacy.f64j4q': '反对权：',
  'login.privacy.9368cj':
    '出于与您的特定情况相关的原因，您可以反对处理您的个人信息。例如，您有权反对我们依赖合法利益的地方或我们出于直接营销目的处理您的数据的地方；',
  'login.privacy.4nb9mx': '限制处理的权利：',
  'login.privacy.do76t6':
    '在某些情况下，您有权暂时限制我们处理您的个人信息，前提是有有效的理由。如果有必要为法律索赔辩护或适用法律允许的任何其他例外情况，我们可能会继续处理您的个人信息；',
  'login.privacy.5etd9r': '携带权：',
  'login.privacy.ie4k6t':
    '在某些情况下，您可以要求以结构化、常用和机器可读的格式接收您提供给我们的个人信息，或者在可能的情况下，我们代表您传达您的个人信息直接到另一个数据控制器；',
  'login.privacy.rni2et': '撤回您同意的权利：',
  'login.privacy.pa1i0e':
    '对于需要您同意的处理，您有权随时撤回您的同意。行使此权利不影响基于后者撤回前给予的同意的处理的合法性；',
  'login.privacy.v41p5p': '向相关数据保护机构投诉的权利：',
  'login.privacy.nsno48':
    '我们希望能够满足您对我们处理您的个人信息的方式的任何疑问。但是，如果您有未解决的疑虑，您也有权向爱尔兰数据保护委员会或您居住、工作或认为发生数据保护违规行为所在地的数据保护机构投诉。',
  'login.privacy.fuiu7u':
    '如果您对我们如何收集和处理您的个人信息有任何疑问或异议，请联系',
  'login.privacy.o71e3y': '10. 币趣会将我的个人信息保存多长时间？',
  'login.privacy.l4i2u8':
    '我们会保留您的个人信息，以便您继续使用币趣服务，只要是为了实现本隐私声明中所述的相关目的，以及法律可能要求的，例如出于税收和会计目的，合规与反洗钱法，或以其他方式传达给您。',
  'login.privacy.7f6wp3': '11.联系方式',
  'login.privacy.3bhpoy': '您可以通过',
  'login.privacy.se4tue':
    '联系我们的数据保护官，他将努力解决您在收集和处理您的个人信息方面遇到的任何问题。',
  'login.privacy.ax6wwg': '12.通知和修订',
  'login.privacy.yq6115':
    '如果您对币趣的隐私有任何疑虑，请联系我们，我们将尽力解决。您还有权联系您当地的数据保护机构。',
  'login.privacy.jps2x6':
    '我们的业务会定期更改，我们的隐私声明也可能会更改。您应该经常查看我们的网站以查看最近的更改。除非另有说明，否则我们当前的隐私声明适用于我们拥有的关于您和您的帐户的所有信息。',
  'login.service.r4q32g': '币趣使用条款',
  'login.service.l1xogv': '上次更新：2023年1月16日',
  'login.service.xnz39r':
    '本币趣使用条款由您(以下称为“您”或“您的”)和币趣经营者(定义见下)订立。您访问、下载、使用或点击“我同意”币趣(定义见下)提供的任何币趣服务(定义见下),即表示您确认已经阅读、理解并接受本使用条款(以下简称为“本使用条款”)规定的所有条款和条件,以及我们在',
  'login.service.6r4yx1':
    '公布的隐私声明。此外,在使用币趣服务的部分功能时,您可能还需遵守适用于这些功能的特定的附加条款和条件。',
  'login.service.wwkcj2': '请认真阅读本使用条款,因为其规范您对币趣服务的使用。',
  'login.service.qtu4q7':
    '本使用条款包含一些重要条文,包括要求所有索赔通过具有法律约束力的仲裁解决的仲裁规定。',
  'login.service.24oyp4':
    '仲裁条款载于以下第十条“纠纷解决：仲裁地点、仲裁、集体诉讼弃权”中。与任何资产一样,数字货币(定义见下)的价值可能会出现大幅波动,并且存在着巨大的风险,即您可能会在购买、出售、持有或投资数字货币及其衍生品时产生经济损失。您使用币趣服务,即表示您确认并同意：',
  'login.service.15p135':
    '(1)您了解与数字货币及衍生品交易相关的风险;(2)您承担与使用币趣服务和进行数字货币及其衍生品交易有关的所有风险;(3)币趣对此等风险或不利结果不承担任何责任。',
  'login.service.s7dfg5':
    '您以任何身份访问、使用或尝试使用币趣服务,即表示您确认接受并同意受本使用条款的约束。 如果您不同意,请勿访问币趣或使用币趣服务。',
  'login.service.c3ne47': '一. 定 义',
  'login.service.j085eu': '1. 币趣：',
  'login.service.eqrn6b': '指币趣网站(域名包括但不限于',
  'login.service.4shf43':
    ')、移动应用程序、客户端、小程序及其他应用程序等为提供币趣服务所创设的生态环境,包括币趣生态中独立运营的各平台、网站和客户端,上述各平台相关使用条款与本使用条款内容不一致的,以该等平台各自的适用条款约定为准。',
  'login.service.00g906': '2. 币趣经营者：',
  'login.service.73x183':
    '指经营币趣的各主体,包括但不限于提供币趣服务并为该等服务负责的法人、非法人组织、团队等。为了指代方便,除另有说明外,本使用条款中提及“币趣”、“我们”时均特指币趣经营者。',
  'login.service.uzv4wj':
    '本使用条款项下,币趣经营者可能根据币趣的业务调整而发生变更,变更后的币趣经营者与您共同履行本使用条款并向您提供服务,币趣经营者的变更不会影响您本使用条款项下的权益。此外,币趣经营者的范围还有可能因为提供新的币趣服务而扩大,如您继续使用币趣服务的,视为您同意新增的币趣经营者与您共同履行本使用条款。发生争议时,您需根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。',
  'login.service.v38c3d': '3. 币趣服务：',
  'login.service.7c6kl2':
    '指币趣基于互联网和/或区块链技术,以包括币趣网站、移动应用程序、客户端等在内的各种形态(包括未来技术发展出现的新的服务形态)向您提供的各项服务。币趣服务包括但不限于目前币趣生态中的数字资产的交易平台、币趣开发者平台、币趣法币通道、币趣钱包等目前提供的服务以及未来币趣新开设的其他服务。',
  'login.service.k1vqo1': '4. 币趣平台规则：',
  'login.service.5q7nep':
    '指包括在所有币趣已经发布及后续发布的全部规则、解读、公告、声明、同意书等内容,以及在帮助中心、产品或服务流程内发布的各类规则、实施细则、产品流程说明、公告等。',
  'login.service.yselbt': '5. 用户：',
  'login.service.gok88o':
    '指所有访问、下载或使用币趣,或使用币趣服务,并符合币趣规定的资格和条件的个人、机构或组织;如针对开发者、经销商、做市商、数字货币交易所等主体有其他协议的,应遵照该等其他协议约定执行。',
  'login.service.2b3c9w': '6. 数字货币：',
  'login.service.u2y63j':
    '指基于区块链技术和加密技术产生的,以去中心化形式发行、管理的,有一定价值的加密令牌、数字令牌或加密货币。',
  'login.service.xs3qe8': '7. 数字资产：',
  'login.service.68dd1w':
    '指数字货币、前者的衍生产品或其他种类的、有一定价值的电子化资产。',
  'login.service.802vo9': '8. 币趣账户：',
  'login.service.ri0cf6':
    '指币趣为用户开设的记录用户在币趣上使用币趣服务、进行交易、资产变化、基础资料的基础性虚拟账户,包括主账户和子账户。币趣账户是用户在币趣享有和行使各项权利的一个依据。',
  'login.service.4qjnf9': '9. 币币交易：',
  'login.service.b7f8lc': '指以数字货币交换数字货币的现货交易。',
  'login.service.r1277b': '10. 法币交易：',
  'login.service.741co2':
    '指以数字货币交换法币,或以法币交换数字货币的现货交易。',
  'login.service.dexjke':
    '指币趣在与其用户建立商业关系或进行交易之前实施的“了解您的客户”过程。作为这个过程的一部分,币趣可以做任何其认为必要的事情,以识别用户,验证他们的身份,审查和调查用户。',
  'login.service.8c3q6q': '二. 总则',
  'login.service.9by514': '1.效力范围',
  'login.service.psimg2': 'a. 主体范围',
  'login.service.c5y445':
    '本使用条款由您和币趣经营者共同缔结,对您和币趣经营者均具有合同效力。',
  'login.service.2n1ual': 'b. 补充条款',
  'login.service.55ef51':
    '由于数字货币生态和币趣生态的高速发展,您与币趣经营者缔结的本使用条款并不能完整罗列并覆盖您与币趣的所有权利与义务,现有约定不能保证完全符合未来发展的需求。因此',
  'login.service.2s9m2r':
    ',隐私声明、币趣平台规则以及您与币趣另行缔结的各项协议均为本使用条款的补充条款,与本使用条款不可分割且具有同等法律效力。如您使用币趣服务,视为您同意上述补充条款。',
  'login.service.n85y6u': 'c. 本使用条款的变更',
  'login.service.vcsy4o':
    '币趣保留随时自行决定、修改或变更本使用条款的权利。币趣将通过更新网页()上修订条款和修改本页显示的[最新修订日期]通知变更内容。',
  'login.service.l36fy5':
    '对本使用条款的任何及所有修改或变更将在网站上公布或发送给用户后立即生效。因此,您继续使用币趣服务,即表示接受修改后的协议和规则。如果您不同意对本使用条款进行的任何修改,请立即停止使用币趣服务。币趣希望您经常查看本使用条款,以确保您理解适用于您访问和使用币趣服务的条款和条件。',
  'login.service.t3oybn':
    'd. 禁止使用范围 您访问和使用币趣服务,即表示您声明并保证您未被列入任何贸易或经济制裁名单(例如联合国安全理事会制裁名单),未被OFAC (美国财政部外国资产管制办公室)指定为“特别指定国民”,或未被列入美国商务部的“拒绝交易对象名单”。币趣保留选择市场和司法管辖区经营业务的权利,并可自行决定在某些国家限制或拒绝提供币趣服务。',
  'login.service.6k888b': '2. 币趣的性质',
  'login.service.i4iis8':
    '币趣为币趣生态链的一个重要组成部分,主要作为全球性在线数字资产交易平台,向用户提供各类与数字资产有关的交易平台服务、融资服务、    技术服务和其他服务。根据以下第3条进一步详细规定,用户必须在币趣注册并开立一个账户,并在开始交易前存入数字资产。用户可以申请提取数字资产,但须遵守本使用条款规定的限制条件。',
  'login.service.9iuq7h':
    '尽管币趣一直致力于维护币趣服务上所发布的信息的准确性,但是币趣不能、也不保证通过币趣服务提供的内容的准确性、适用性、可靠性、完整性、执行性或合适性,也不对您使用这些内容可能直接或间接造成的任何损失或损害承担任何责任。有关币趣服务的信息可能会变更,而不会发出通知,并且提供这些信息的主要目的是帮助用户做出独立决定。币趣不提供任何类型的投资或咨询建议,也不对币趣或其他通信媒介上的信息的使用或解释负责。所有使用币趣服务的用户都必须了解数字资产交易存在风险。币趣希望所有用户谨慎行事,并在力所能及的范围内负责任地进行交易。',
  'login.service.hhnuh3': '3. 币趣帐户注册和要求',
  'login.service.3w11cr': 'a. 注册',
  'login.service.c1xch7': '所有用户在使用币趣服务之前必须在a',
  'login.service.63f38r':
    'www.basicex.com/zh-CN/register申请注册一个币趣帐户。在注册币趣帐户时,您必须提供本第3段中所述的信息或币趣要求的其他信息,并接受本使用条款、隐私声明和其他币趣平台规则。币趣可以自行决定拒绝为您开立币趣账户。您同意在开立币趣账户时提供完整且准确的信息,并同意及时更新您向币趣提供的任何信息,以维持信息的完整性和准确性。每一名用户(包括自然人、企业或法人实体)在任何时候只能保留一个主账户。但经币趣同意可在主账户项下开立一个或多个子账户。依据本使用条款或补充条款的规定,在您使用部分币趣服务时,我们可能要求您另行开设独立于币趣账户的其他专项账户。除本使用条款或补充条款另有规定外,注册、使用、保护和管理该等交易账户应同等适用本条和第六条的规则。',
  'login.service.j259mq': 'b. 资格',
  'login.service.jis7mf':
    '通过注册使用币趣账户,您声明并保证您(a)如为个人,已经年满18周岁或达到根据适用法律订立具有约束力合约的法定年龄;(b)是具有订立本使用条款的完全法律行为能力和授权的个人、法人或其他组织;(c)以前未被中止或取消使用币趣平台或币趣服务的资格;(d)您目前没有币趣账户;(e)您并非居住于、位于受限地区或从受限地区以其他方式访问币趣平台或币趣服务,或以其他方式代表居住或位于受限地区的个人或法人实体行事。就本使用条款而言,“受限区域”应包括美国、马来西亚、安大略(加拿大)以及币趣运营商根据本使用条款不时指定的区域”的其他地点。(f)如果您担任法律实体的雇员或代理人,并以此身份代表其签订本使用条款,您声明并保证您拥有约束该法律实体,并代表该法律实体访问和使用币趣平台和币趣服务的所有必要权利和授权(g)您使用币趣平台和币趣服务不会触犯您或您所代表的法律实体的所适用的所有法律法规,包括但不限于反洗钱、反贪腐、反恐怖主义融资等相关规定。',
  'login.service.9dyn6c': 'c. 用户身份验证',
  'login.service.35ijpn':
    '您在币趣注册币趣帐户,即表示您同意提供为进行身份验证而要求提供的个人信息。这些信息将确认用户身份,用于发现在币趣进行的洗钱、恐怖主义融资、欺诈和其他金融犯罪活动的线索,或币趣声明的其他合法目的。我们将根据我们发布的《隐私声明》收集、使用和共享这些信息。除了提供这些信息外,为了遵守全球数据存储的行业标准,您同意允许我们在您的币趣账户存续期内以及账户关闭后的5年内保留这些信息的记录。您还授权我们直接或通过第三方开展核实您的身份或保护您和/或我们免受金融犯罪(如欺诈)侵害所必需的调查。 我们要求提供的身份验证信息可能包括但不限于您的：姓名、电子邮件地址、联系信息、电话号码、用户名、政府签发的身份证件、出生日期以及在帐户注册时收集的其他信息。在提供这些要求的信息时,您确认这些信息真实准确。',
  'login.service.1082u2':
    '注册后,您必须保证信息真实、完整,同时如有任何变化,及时予以更新。如果有任何理由怀疑您提供的任何信息是错误的、不真实、过时的或不完整,币趣有权向您发送通知,要求更正,直接删除相关信息,以及(视情况而定)终止向您提供的全部或部分币趣服务。如果无法通过所提供的联系资料联系到您,您应对使用币趣服务期间产生的币趣的任何损失或费用承担全部责任。您在此确认并同意,如有任何变化,您有义务随时更新所有信息。通过注册账户,您特此授权币趣直接或通过第三方开展币趣认为必要的调查,以核实您的身份或保护您、其他用户和/或币趣免遭欺诈或其他金融犯罪侵害,并根据调查结果采取币趣认为必要的行动。 您亦确认并同意您的个人信息可能会被披露给征信机构和欺诈预防或预防金融犯罪部门,这些机构可能会对我们的调查做出全面回应。',
  'login.service.l0s8g6': 'd. 账户使用要求',
  'login.service.881f65':
    '币趣账户只能由账户注册人使用。币趣保留暂停、冻结或取消账户注册人以外的人士使用币趣账户的权利。如果您怀疑或发现未经授权使用您的用户名和密码的情况,应立即通知币趣。币趣对您或任何第三方(无论是否经您授权)使用您的币趣账户造成的任何损失或损害概不负责。',
  'login.service.3im2qq': 'e. 账户安全',
  'login.service.q6w78a':
    '币趣一直致力于维护用户委托资金的安全,并对币趣服务实施了行业标准保护。但是,个别用户的行为会产生风险。您同意将您的访问凭证(如用户名和密码)视为机密信息,不将此等信息透露给任何第三方。您亦同意独自负责采取必要的安全措施保护您自己的币趣账户和个人信息的安全。',
  'login.service.x72wd6':
    '您应独自负责保管您的币趣账户和密码,同时对币趣账户下的所有往来活动负责,币趣对经授权或未经授权使用您的账户凭证造成的任何损失或后果概不承担任何责任,包括但不限于信息披露、信息发布、通过点击网站同意或提交各种规则和协议、在线续展协议等。',
  'login.service.1aktk8': '您创建币趣账户,即表示您特此同意：',
  'login.service.c1234z':
    'i.如果您知道任何人未经授权使用您的币趣账户和密码或有任何其他违反安全规则的行为,您将立即通知币趣;',
  'login.service.8n81bu':
    'ii.您将严格遵守币趣的全部安全、认证、交易、收费、退出机制或程序;',
  'login.service.jm2tyq':
    'iii.且每次访问结束时, 您均会采取适当的步骤从币趣上注销退出。',
  'login.service.57k158': '三. 币趣服务',
  'login.service.hy1i75':
    '经完成币趣账户注册及认证手续后,您可按照本使用条款(包括币趣平台规则和其他单项协议)的规定,使用各项币趣服务,包括但不限于在币趣开展币币交易、法币交易、合约交易、杠杆交易,使用币趣宝服务、持仓返利服务,取得币趣公布的市场数据、研究和其他信息,参加币趣举办的用户活动等。币趣有权：',
  'login.service.4ks6s7': '1. 自行决定开展、修改或终止提供任何币趣服务;',
  'login.service.7lwag3':
    '2. 以及根据有关币趣平台规则,允许或禁止部分用户使用任何币趣服务。',
  'login.service.io1y17':
    '币趣拥有决定哪些数字资产在平台上上市的独家权力,并可自行决定不时从平台上添加或删除数字资产。币趣还可以更改每个数字资产的订单大小。对于该等添加、删除或修改,币趣可以(但没有义务)提前通知用户,币趣不对用户承担与该等添加、删除或修改有关的责任。',
  'login.service.qge6s8': '1. 服务使用指南',
  'login.service.7dx5l7': 'a. 许可',
  'login.service.7gjx0v':
    '在您持续遵守本使用条款明确规定的条款和条件的前提下,币趣赋予您撤销、有限、免特许权使用费、非独占、不可转让且不可分许可的许可,以通过您的计算机或互联网兼容设备为自己个人/内部目的访问和使用币趣服务。您不得将币趣服务用于转售或商业用途,包括代表他人或其他实体进行交易。上述所有使用行为均明令禁止,并构成严重违反本使用条款。币趣服务的内容编排、格式、功能和访问权限应由币趣自行规定。币趣特此保留本使用条款未明确授予的所有权利。因此,特此禁止您以本使用条款未明确授权的任何方式使用币趣服务。',
  'login.service.3l80yx':
    '本使用条款仅授予访问和使用币趣服务的有限许可。因此,您特此同意在您使用币趣服务时,币趣不会向您或任何其他人转让币趣服务或币趣任何知识财产中的所有权或知识产权。所有文本、图形、用户界面、视觉界面、照片、声音、工艺图、计算机代码(包括html代码)、程序、软件、产品、信息和文件,以及包含在服务中或通过币趣服务提供的任何内容的设计、结构、选择、协调、表达、“界面外观”和安排,均由币趣经营者或其成员、母公司、许可方或关联方独家所有、控制和/或许可。',
  'login.service.73o78r':
    '币趣将拥有您通过email、币趣服务或其他方式提供的,关于币趣或币趣服务的任何反馈、建议、想法或其他信息或材料(“反馈”)。您将特此反馈的所有权利、所有权和利益以及所有相关的知识产权转让给币趣。您将无权并特此放弃根据任何反馈或根据任何反馈做出的任何修改提出确认或赔偿要求。',
  'login.service.wq2eiq': 'b. 限制',
  'login.service.5hg230': '当您使用币趣服务时,您同意并承诺遵守以下规定：',
  'login.service.d88094':
    'i.在使用币趣服务期间,您开展的所有活动都将符合适用法律、法规的要求,本使用条款,以及币趣的各种指导原则;',
  'login.service.cp936b':
    'ii.您使用币趣服务不会违反公共利益、公共道德或其他人的合法利益,包括采取任何会干扰、扰乱、负面影响或禁止其他用户使用币趣服务的行动;',
  'login.service.d336o6':
    'iii.您同意不使用这些服务进行市场操纵(例如拉抬价格、冲销交易、自我交易、抢先交易、塞单、幌骗或分层下单,无论法律是否禁止);',
  'login.service.17jbft':
    'iv.除非获得币趣的书面同意,否则禁止以下使用币趣数据的商业行为：',
  'login.service.73ch03': '1)使用币趣报价或行情公告牌信息的交易服务。',
  'login.service.07yk77': '2)利用币趣的任何市场数据的数据馈送或数据流服务。',
  'login.service.31xcj3':
    '3)对从币趣获得的市场数据收费或以其他方式进行商业盈利(包括通过广告或转介费用)的任何其他网站/应用程序/服务。',
  'login.service.d6kp8c':
    'v.未经币趣事先书面同意,您不得修改、复制、复印、拷贝、下载、存储、进一步传输、传播、转让、拆解、广播、发布、移除或更改任何版权声明或标签,许可、再许可、出售、镜像、设计、出租、租赁、私人标签、授予财产或财产的任何部分的担保权益、创作其衍生作品或以其他方式利用其任何部分。',
  'login.service.x77d8j':
    'vi.您不得(i)使用任何“深层链接”、“页面抓取”、“机器人”、“蜘蛛”或其他自动设备、程序、脚本、算法或方法,或任何类似或同等的手动程序来访问、获取、复制或监控财产的任何部分,或以任何方式复制或绕过币趣服务的导航结构或演示,以获取或试图通过币趣服务非有意提供的任何方式获取任何材料、文档或信息;(ii)试图通过黑客攻击、密码“挖掘”或任何其他非法或禁止手段,未经授权访问财产的任何部分或功能,或连接到币趣服务或任何币趣服务器或通过该服务提供的任何币趣服务的任何其他系统或网络;(iii)探测、扫描或测试币趣服务或连接到财产的任何网络的漏洞,违反币趣服务或连接到币趣服务的任何网络上的安全或认证措施;(iv)反向查找、跟踪或寻求跟踪币趣服务的任何其他用户或访问者的任何信息;(v)采取对币趣服务或币趣的系统或网络或连接到币趣服务的任何系统或网络的基础设施施加不合理或不成比例的大负载的任何行动;(vi)使用任何设备、软件或例行程序,以干扰币趣服务的正常运行或币趣服务上进行的任何交易,或任何其他人对币趣服务的使用;(vii)伪造抬头、冒充他人或以其他方式操纵标识符,以掩盖您的身份或您发送给币趣的任何消息或传输的来源,或(viii)以非法方式使用币趣服务。',
  'login.service.2zi5xe':
    '您访问币趣服务,即表示您同意币趣有权调查任何违反本使用条款的行为,单方面确定您是否违反本使用条款,并在不征得您同意或事先未通知的情况下采取行动以适用相关法规。此等行动的例子包括但不限于：',
  'login.service.hwd27r': '1.阻止和关闭订单请求;',
  'login.service.1uw8ge': '2.冻结账户;',
  'login.service.57mj94': '3.向当局报告事件;',
  'login.service.7gb4x1': '4.公布被指控的违规行为和已经采取的行动;',
  'login.service.ns15wc': '5.删除您发布的任何违规信息。',
  'login.service.86o0pb': '2. 法币交易',
  'login.service.254qma':
    '在进行法币交易前,您应单独阅读并同意与币趣合作的各OTC平台的《用户协议》并遵守《法律声明》、《隐私政策》、《P2P用户交易规则》等其他法币交易相关的专项币趣平台规则以及该等合作方的业务规则,并经完成币趣账户注册及认证手续后开设币趣OTC平台账户(如适用)。',
  'login.service.2j5xk2': '四. 责任',
  'login.service.o8r6vj': '1. 保证免责声明',
  'login.service.5sv6ck':
    '在适用法律允许的最大范围内,币趣服务、币趣的材料以及由币趣或代表币趣提供的任何产品、服务或其他项目均是在“现状”和“可用”的基础上提供的,同时币趣明确放弃,您亦放弃,所有任何种类的其他保证,无论是明示的还是默示的,包括但不限于对于适销性、特定用途适合性、所有权或不侵权的保证,或者因履行过程、交易过程或交易中使用而产生的保证。在不限制上述规定的情况下,币趣对网站、币趣服务或币趣材料的准确性、完整性、可靠性、有效性、无错误或无病毒或其他有害成分不做任何声明或保证。币趣不保证任何订单会被执行、接受、记录或保持未完成状态。除本使用条款规定的明确声明、协议或规则外,您在此确认并同意,您在使用和获取币趣服务方面没有依赖任何其他书面或口头声明或协议。在不限制上述规定的情况下,您特此理解并同意币趣对以下原因引起的或与之相关的任何损失或损害概不负责：(A)数字资产价格数据的不准确性、缺陷或遗漏;(B)此类数据传输中的任何错误或延迟;(C)此等数据的中断,(D)币趣的定期或不定期维护,以及由此产生的服务中断、服务变化;(E)其他用户的行为、不作为或违反本使用条款的行为造成的任何损害;(F)其他第三方的不法行为或非币趣授权行为造成的任何损害;以及(G)币趣发布的免责声明、币趣平台规则中提及的其他免责事项。 如果被您居住的司法管辖区的适用法律所禁止,则本使用条款所载默示保证免责声明可能不适用。',
  'login.service.6t2642': '2. 损害免责声明和责任限制',
  'login.service.y4fip6':
    '在适用法律允许的最大范围内,币趣、其隶属机构以及各自的股东、成员、董事、高级职员、雇员、律师、代理人、代表、供应商或承包商在任何情况下均对因币趣服务、币趣服务的任何履行或不履行或由币趣及其隶属机构提供或代表币趣提供的其他产品、服务或其他项目引起的任何附带、间接、特殊、惩罚性、后果性或类似的损害或责任(包括但不限于数据、信息、收入、利润或其他业务或财务利益的损失)概不负责,无论是根据合同、法规、严格责任还是其他理论,即使币趣已经被告知存在损害的可能性,除非最终司法裁定此等损害是因币趣的重大过失、欺诈、故意不当行为或故意违法行为造成的。一些司法管辖区不允许排除或限制附带或后果性损害,因此上述限制可能不适用于您。 尽管有上述规定,在任何情况下,币趣、其隶属机构及其各自的股东、成员、董事、高级职员、雇员、律师、代理人、代表、供应商或承包商因币趣或其关联方提供或代表币趣或起关联方提供的服务、服务的任何履行或不履行、或任何其他产品、服务或其他项目而产生的责任,无论是根据合同、法规、严格责任还是其他理论,均不会超过在引起责任索赔的事件发生之前的十二个月内您根据本使用条款支付给币趣的费用金额。',
  'login.service.85gi4c': '3. 赔偿',
  'login.service.r4u256':
    '您同意赔偿币趣经营者、其隶属机构、承包商、许可方及其各自的董事、高级职员、雇员和代理人,并使它们免受因以下原因引致或与以下原因相关的任何索赔、诉讼、程序、调查、要求、诉讼、成本、费用和损害(包括律师费、罚金或任何监管机关收取的罚款)：(i)您使用币趣服务或与币趣服务相关的行为;(ii)您违反币趣服务条款或我们执行币趣服务条款;或(iii)您在使用币趣服务期间违反任何适用法律、法规或任何第三方的权利。如果根据币趣服务条款,您有义务赔偿币趣经营者、其隶属机构、承包商、许可人及其各自的董事、高级职员、雇员或代理人,币趣将有权自行控制任何诉讼或程序,并确定币趣是否希望和解,如果愿意,基于什么条款和解。',
  'login.service.9115hn': '五. 公告',
  'login.service.52wq75': '请注意,币趣所有官方公告、新闻、推广、都将发布在',
  'login.service.8a6d07':
    '. 用户承诺将定期且及时地查阅这些资料。如果用户因对公告的无知或疏忽而遭受个人损失,币趣将不承担任何赔偿责任。',
  'login.service.1p53hd': '六. 协议终止',
  'login.service.r4u3d2': '1. 暂停账户',
  'login.service.j2710v':
    '您同意币趣有权以任何理由立即暂停您的币趣账户(以及关联实体或关联方实益拥有的任何账户),冻结或锁定所有此等账户中的数字资产或资金,并暂停您对币趣的访问,包括因怀疑任何此等币趣账户违反了本使用条款、我们的隐私声明或任何适用法律法规。您同意币趣对您的币趣账户的任何永久或临时修改、或暂停或终止访问全部或部分服务不负任何责任。币趣有权保存和使用与币趣账户有关交易的数据或其他信息。上述账户控制也可适用于以下情况：',
  'login.service.1t128d':
    '1.该币趣账户受到政府程序、刑事调查或其他未决诉讼的约束;',
  'login.service.fl4tmq': '2.我们发现币趣账户中有异常活动;',
  'login.service.bctfn1': '3.我们检测到对币趣账户存在非法访问;',
  'login.service.s6168d':
    '4.根据法院命令或监管/政府机关的命令,我们有采取该等措施的必要性',
  'login.service.51s186': '2. 撤销账户',
  'login.service.ia0o87':
    '如果发生以下任何事件,币趣有权通过取消您的币趣账户直接终止本使用条款,并有权但无义务永久冻结(取消)您就账户授予币趣的授权,并撤销相应的币趣账户：',
  'login.service.30h447': 'i.币趣终止向您提供服务后;',
  'login.service.jsq3ta':
    'ii.您被指认再次以任何其他人的名义直接或间接注册为币趣用户;',
  'login.service.2eoyw9': 'iii.您提供的信息不真实、不准确、过时或不完整;',
  'login.service.883e2o':
    'iv.在修改币趣服务条款时,您通过申请注销币趣账户或其他方式表示您不愿意接受修改后的条款;',
  'login.service.h8v45g': 'v.您请求终止服务;',
  'login.service.407057': 'vi.币趣认为应该终止服务的任何其他情况。',
  'login.service.k1qyya':
    '如果您的币趣账户被终止,满足数据保留标准的账户和交易信息将安全保存5年。此外,如在账户终止过程中有交易尚未完成,币趣有权告知您的交易对方当时的情况。您同意用户发起的账户退出(GDPR或其他同等法规下的擦除权)也将受到上述终止协议的约束。 如果币趣被告知您币趣账户中的任何数字资产或资金被盗或并非您合法拥有,币趣可以但没有义务对受影响的资金和您的币趣账户进行管理控制。如果确实需要对部分或全部资金或账户进行管理控制,则币趣可以继续持有,直到纠纷得到解决,并且币趣可以接受的争议解决的证据已经以币趣可以接受的形式提供给币趣。币趣不会参与任何此类纠纷或纠纷的解决。您同意币趣对任何此类管控,或您在任何管控期间无法提取数字资产或资金或执行交易,概不负责。',
  'login.service.uhx61q': '3. 账户终止后的剩余资金',
  'login.service.g9g6kb':
    '除以下第4款规定外,一旦币趣账户关闭/撤销,币趣账户上的所有余额(包括欠币趣的费用和债务)将立即支付给币趣。向币趣支付所有未清费用后(如果有),用户将有5个工作日时间从账户中提取所有数字资产或资金。',
  'login.service.1u39if':
    '4. 账户因欺诈、违法或违反本使用条款而终止后的剩余资金',
  'login.service.cgppoj':
    '在因欺诈调查、违法调查或违反本使用条款导致币趣账户暂停/关闭的情况下,币趣保留对币趣账户中所有数字资产、资金和用户数据/信息的完全保管权,并且这些数字资产、资金和用户数据/信息可能会移交给政府机关。',
  'login.service.awbqxl': '5.休眠账户',
  'login.service.5689e1':
    '尽管本第六条有任何规定,币趣可提供书面通知,要求您在通知发出后30天内平仓所有未平仓头寸,并从币趣账户中撤回所有数字资产。如果您未能这样做,币趣可以在不事先通知您的情况下,根据其绝对酌情权：',
  'login.service.63idug': '(a)将您的币趣账户视为休眠账户;',
  'login.service.kq3xa5': '(b)平仓任何币趣产品的任何未平仓头寸;',
  'login.service.5u3n69':
    '(c)将数字资产转换为不同类型的数字资产(例如,从BTC转换为USDT)。为免生疑问,币趣经营者均不对您因该等转换而产生的任何利润损失、税务义务或任何其他损失、损害或费用承担责任;',
  'login.service.r0i5p3':
    '(d)在币趣认为有合理必要的情况下,将该等休眠账户(包括其中包含的任何数字资产)转让给币趣运营商的附属机构、任何第三方托管人或单独的钱包。在发生此类转移的情况下,您有权在满足币趣验证要求的前提下,从其中检索您的数字资产,包括完成KYC;',
  'login.service.971852':
    '(e)向币趣经营者、其关联公司或任何第三方收取休眠账户费用,以支付其维护资产的成本,该费用应按月直接从休眠账户中提取;',
  'login.service.7nnt2b':
    '(f)随时关闭休眠账户,币趣不对您因关闭休眠账户而产生的任何损失、损害或费用承担责任,除非币趣存在欺诈或故意违约行为。这些休眠账户中的任何资产将按照上文第5(d)段转移。休眠账户关闭后,您将无法重新激活该账户(即如果您希望继续使用币趣服务,您需要注册一个新的币趣账户)。',
  'login.service.8293z2': '七. 非财务建议',
  'login.service.tx7u2l':
    '币趣不是您的经纪人、中介、代理或顾问,在您使用币趣服务进行的任何交易或其他决策或活动中,币趣与您不存在信托关系或者信托义务。币趣向您提供的任何通信或信息均不作为或者也不应视为或解释为投资建议、财务建议、交易建议或任何其他类型的建议。除本使用条款另行规定外,所有交易均基于 您的订单说明的参数和公布的交易执行程序自动执行,您应根据个人投资目标、财务状况和风险承受能力来确定任何投资、投资策略或相关交易是否适合您,并独自承担任何损失或责任。关于您的具体情况,您应该咨询法律或税务专业人士的意见。币趣不向您推荐购买、获取、出售或持有任何数字资产。在做出购买、出售或持有任何数字资产的决定之前,您应该进行尽职调查,并在做出任何投资决定之前咨询您的财务顾问。币趣对您根据币趣提供的信息做出的购买、出售或持有数字资产的决定概不负责。',
  'login.service.0h2h9e': '八. 遵守当地法律',
  'login.service.s378n2':
    '用户有责任遵守与其在当地使用币趣服务有关的当地法律及其他适用于该用户的法律法规。用户还必须根据当地法律考虑税务的所有方面,包括预扣、代收、报告和汇款给其相应的税务机关。',
  'login.service.ouw125':
    '币趣服务的所有用户均确认并声明其资金来源合法,并非产生于非法活动;用户同意币趣依据相关法律或政府命令,要求用户提供或另行收集必要的信息和资料,以验证其资金来源和用途的合法性。',
  'login.service.9s87ei':
    '币趣秉承与全球执法机构合作的态度,并将毫不犹豫地扣押、冻结、终止法律授权注销或调查的用户账户和资金。',
  'login.service.qcqh4g': '九. 隐私声明',
  'login.service.v41yvu': '访问币趣服务需要提交部分个人身份信息。 请阅读',
  'login.service.9t9leu':
    '公布的币趣隐私声明,获取币趣与收集和使用个人身份信息有关的指引总结。',
  'login.service.8v6f17': '十. 纠纷解决：仲裁地点、仲裁、集体诉讼弃权',
  'login.service.183p3x':
    '请仔细阅读本条款,因为它涉及放弃某些提起法律诉讼的权利,包括集体诉讼。',
  'login.service.4ot7r5': '1. 索赔通知和争议解决期。',
  'login.service.1schi9':
    '请先联系币趣！币趣希望在不诉诸正式法律程序的情况下解决您的问题。如果您与币趣有纠纷,请联系币趣,我们会给您一个票号。币趣将尝试尽快在内部解决您的争议。双方同意以真诚的态度协商解决争议(有关讨论应保密,并受适用规则的约束,以保护和解讨论不被用作任何法律程序中的证据)。',
  'login.service.eqegh1':
    '如果争议无法令人满意地解决,而您希望对币趣提出法律索赔,则作为向币趣发出的一种提前通知形式,您同意以书面形式在“索赔通知”中阐明此类索赔的基础。索赔通知必须(1)描述索赔或争议的性质和依据,(2)列出寻求的具体救济,(3)提供原始票号,以及(4)包括您的币趣账户邮箱。索赔通知应提交到您与币趣通信中提供的电子邮件地址或超链接。在您向币趣提供索赔通知后,币趣或您可以根据下文本条第2段的规定将索赔通知中提及的争议提交仲裁。为避免疑问,将争议提交币趣内部解决并向币趣交付索赔通知是启动仲裁程序(或任何其他法律程序)的先决条件。仲裁期间,您或币趣提出的任何和解协议的金额不得向仲裁员披露。',
  'login.service.64y25g': '2. 仲裁协议及适用法律',
  'login.service.rsfkm3':
    '您与币趣经营者同意,根据上文第1段,您与币趣(和/或币趣经营者)之间因本使用条款或以任何方式与币趣(和/或币趣经营者)作为币趣服务用户的关系(无论是基于合同、侵权、法规、欺诈、虚假陈述或任何其他法律理论)产生的任何纠纷、索赔或争议,以及索赔是否发生在本使用条款有效期内或终止之后)将由强制性的、最终的、有约束力的个人(而非集体)仲裁决定,仲裁协议的例外情况下的规定除外。您与币趣经营者进一步同意,仲裁员将拥有对其自身管辖权作出裁决的排他性权力,包括但不限于对仲裁协议的存在、范围或有效性,或对任何索赔或反索赔的可仲裁性提出的任何异议。仲裁比法庭诉讼更加非正式。',
  'login.service.w7cx49':
    '在仲裁中没有法官或陪审团,法院对仲裁裁决的审查是有限制的。',
  'login.service.lopr8n':
    '比起在法庭上,可能会有更多有限的发现。仲裁员必须遵守本协议,并可以裁定与法院相同的损害赔偿和救济(包括,如适用,律师费),但仲裁员不得裁定对仲裁双方以外的任何人有利的宣告性或禁令性救济。本条款中规定的仲裁协议在本使用条款终止后仍然有效。',
  'login.service.e3271x':
    '仲裁规则。在仲裁通知提交时,或按本第十条修改时,仲裁应遵循届时生效的HKIAC适用的仲裁规则(HKIAC 规则)。仲裁将由香港国际仲裁中心(HKIAC)进行管理。除非双方另有约定,否则只能根据HKIAC规则指定一名仲裁员。任何仲裁将以英语进行。无论仲裁以何种方式进行,仲裁员应出具一份合理的书面决定,足以说明该决定和裁决(如有)所依据的基本发现和结论。',
  'login.service.4un6jx':
    '对任何仲裁裁决的判决可以由对该裁决的当事人(或当事人的财产)有管辖权的任何法院作出。提交时间:任何针对币趣运营商的仲裁必须在主张索赔的一方首先知道或合理地应该知道导致索赔的行为、不作为或违约之日起一(1)年内通过提交仲裁请求开始;对于未在该期限内提出的任何索赔,不得要求任何救济。这一年的时效包括上文本条第1段规定的内部争端解决程序。对于未在该期限内提出的任何索赔,不得要求任何救济。',
  'login.service.968c2f':
    '如果适用法律禁止一年的索赔时效期,则任何索赔必须在适用法律允许的最短时间内提出。',
  'login.service.c55747':
    '程序;通知:在上文第1段规定的争议解决期届满后,任何一方如欲申请仲裁,必须根据HKIAC规则向HKIAC提交请求。如果我们要求对您申请仲裁,我们会通过您提供的电子邮件地址或邮寄地址通知您。您同意,发送到此电子邮件或邮寄地址的任何通知均应被视为在所有目的下有效,包括但不限于确定送达是否充分。您有义务确保与币趣存档的电子邮件地址和/或邮寄地址是最新和准确的。仲裁地点：仲裁地点应为香港。聆讯地点：除非双方另有约定,任何亲自进行仲裁聆讯的地点均应为香港。适用法律：本使用条款(包括本仲裁协议)应受香港法律管辖,并根据香港法律进行解释。保密性：当事人同意对仲裁进行保密。仲裁的存在、仲裁中提供的任何非公开信息,以及仲裁中提交的任何文件、命令或裁决(合称为“保密信息”)不得向任何非仲裁一方披露,但仲裁庭、HKIAC、双方、其律师、专家、证人、会计师和审计师、保险公司和再保险公司,以及进行仲裁所需的任何其他人士除外。尽管有上述规定,一方仍可以披露保密信息,如果披露是为了履行法律义务,保护或追求法律权利,或在善意法律诉讼中执行或质疑裁决。本保密条款在本使用条款终止以及根据本使用条款提起的任何仲裁终止后仍然有效。',
  'login.service.2rqx41': '3. 放弃集体诉讼',
  'login.service.0pv0r8':
    '您和币趣同意,任何关于本使用条款或者作为币趣服务的用户与币趣的关系的索赔(无论是否基于合同、侵权、法令,欺诈,欺诈、或任何其他法律理论,以及索赔出现在本使用条款有效期内或终止之后)均应当以个人名义对另一方提出仲裁,而不是作为拟议的集体诉讼或代表诉讼的原告或成员。您和币趣进一步同意,在适用法律允许的范围内,放弃以集体、共同、代表或私人总检察长(private attorney general action)诉讼方式提起、审理或仲裁此类索赔的任何权利。未经包括币趣在内的各方同意,不得将单个仲裁整合或合并为一个仲裁。',
  'login.service.krs9o1': '4.修改',
  'login.service.4d7331':
    '币趣保留就双方仲裁协议更新、修改、修订、暂停或对第十条进行任何未来变更的权利,但须遵守适用法律。您在此同意并认可,您有责任确保您对本节的理解是最新的。根据适用法律,您继续使用您的币趣账户将被视为您接受关于双方仲裁协议的第十条的任何修改。您同意,如果您反对对第十条的修改,币趣可能会在您的账户关闭之前屏蔽您的账户访问。在这种情况下,修改前的使用条款将在您的账户关闭之前保持完全有效。',
  'login.service.whxc56': '5. 可分割性',
  'login.service.fmn834':
    '如果本使用条款的任何部分因任何原因或在任何程度上被判定为无效或不可执行,本条款的其余部分将继续有效和可执行,而无效或不可执行的部分将在法律允许的最大范围内生效。',
  'login.service.06omkq': '十一. 杂项',
  'login.service.fheews': '1. 独立当事方',
  'login.service.3cu418':
    '在履行本使用条款时,币趣是独立的承包商,而不是您的代理人。本使用条款不应被解释为双方之间联合、合资、合伙或特许经营的事实或证据。',
  'login.service.lcxy1q': '2. 完整协议',
  'login.service.p5k4hz':
    '本使用条款构成双方关于使用币趣服务的完整协议,并将取代双方之前的所有书面或口头协议。双方之间不使用交易或其他常规做法或交易方法来修改、解释、补充或变更本使用条款的条款。',
  'login.service.yyj7ca': '3. 解释与修订',
  'login.service.dmd8r1':
    '币趣保留随时修改、修订、修改和/或更改本使用条款的权利。所有变更在网站上公布时立即生效。您有责任定期查看我们网站/应用程序上的相关页面,以便确认本使用条款的当前版本。如果您不同意任何此类修改,您的唯一补救措施是终止您对币趣服务的使用并注销您的帐户。您同意,除非本使用条款另有明确规定,否则币趣不对您或任何第三方对币趣服务的任何修改或终止,或暂停或终止您对服务的访问承担责任。',
  'login.service.8066u8': '4. 语言及翻译',
  'login.service.yo9e6g':
    '币趣可自行决定将本使用条款翻译为英语以外的其他语言。您同意,任何此类翻译仅为您的方便,如果在英文文本和任何翻译文本之间出现任何歧义、差异或遗漏,应以英文文本为准。',
  'login.service.10ard2': '5.不可抗力',
  'login.service.9x4rp6':
    '币趣对由于币趣无法合理控制的任何原因或状况而导致的任何延迟或未按照本使用条款的要求履约不承担任何责任。',
  'login.service.2p413q': '6. 可分割条款',
  'login.service.ozdvyl':
    '如果本使用条款的任何部分被认定为无效或不可执行,此无效或不可执行不会影响本使用条款的其他条款,这些条款将保持完全有效,同时无效或不可执行的部分将在最大范围内有效。',
  'login.service.0ja5sf': '7. 转让',
  'login.service.820ovr':
    '未经币趣事先书面同意,您不得转让或让与使用币趣服务的任何权利或您在本使用条款项下的任何权利或义务,包括因法律施行或与控制权变更相关的权利或义务。币趣可以全部或部分转让或让与其在本使用条款项下的任何或全部权利或义务,无需通知或获得您的同意或批准。',
  'login.service.99s52x': '8. 弃权',
  'login.service.70064o':
    '一方未要求任何规定的履行不影响该方此后任何时候要求履行的权利,同时,一方放弃追究对方违反本使用条款或者本适用条款任何规定的行为或违规行为并不构成该方对任何对方后续违约或违规行为的放弃或对条款本身的放弃。',
  'login.service.q6jrf8': '9. 第三方网站免责声明',
  'login.service.21274u':
    '币趣服务中指向第三方网站的任何链接并不意味着币趣认可其中提供的任何产品、服务、信息和免责声明,同时币趣亦不保证其中所载信息的准确性。您使用该等第三方产品及服务受到损失的,币趣不承担任何责任。此外,由于币趣无法控制第三方网站的使用条款或隐私政策,因此您应该仔细阅读和理解这些政策。',
  'login.service.pn33fq': '10. Apple Inc.相关事项',
  'login.service.6pk847':
    '如您使用任何Apple Inc.设备,通过币趣服务参加的任何商业活动或者奖励活动,由币趣提供,均与Apple Inc.无关。',
  'login.service.q74557': '11. 联系方式',
  'login.service.t35sxh':
    '有关币趣更多信息,您可以参考币趣网站上的公司和许可信息。如果您对本使用条款有任何疑问,请随时通过我们的客户支持团队联系币趣要求澄清,网址',
};