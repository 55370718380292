export default {
  'home.coin': 'Tài sản tiền điện tử trong tầm kiểm soát',
  'home.wallet': 'Ví tiền điện tử/Thanh toán BasicEx/Mua tiền điện tử một chạm',
  'home.there': 'Tất cả tại',
  'home.Basic': 'BasicEx',
  'home.userName': 'Đăng ký bằng email/số điện thoại',
  'home.down': 'Tải xuống ngay',
  'home.free': 'Miễn phí',
  'home.collection': 'Thanh toán',
  'home.frees': 'Phí 0',
  'home.sell': 'Mua/bán BCNY, thuận tiện và nhanh chóng.',
  'home.pay': 'Chuyển khoản/nhận tiền giữa người dùng BasicEx không mất phí.',
  'home.register': 'Đăng ký ví',
  'home.payment': 'Thanh toán BasicEx',
  'home.introduce': 'Giới thiệu',
  'home.tip1': 'Thanh toán BasicEx là hệ thống thanh toán tiền điện tử không tiếp xúc, không biên giới do BasicEx phát triển, an toàn và đáng tin cậy.',
  'home.tip2': 'Kích hoạt dịch vụ thanh toán cho công ty của bạn, thực hiện bán hàng trực tuyến và trực tiếp bằng USDT và các đồng tiền chính khác.',
  'home.see': 'Xem thêm',
  'home.basicWallet': 'Ví BasicEx',
  'home.chain': 'Ví đa chuỗi',
  'home.manage': 'Quản lý tài sản đa chuỗi dễ dàng',
  'home.interface': 'Giao diện thanh toán',
  'home.api': 'API/SDK thông minh cho mọi nền tảng',
  'home.C2C': 'Thị trường C2C',
  'home.buy': 'Mua/bán tài sản tiền điện tử một chạm',
  'home.help': 'Cần trợ giúp',
  'home.chat1': 'Hỗ trợ trò chuyện 24/7',
  'home.chat2': 'Nhận hỗ trợ trò chuyện 24/7, đại diện dịch vụ khách hàng thân thiện sẵn sàng phục vụ bạn.',
  'home.chat3': 'Trò chuyện trực tuyến',
  'home.chat4': 'Câu hỏi thường gặp',
  'home.chat5': 'Xem câu hỏi thường gặp, nhận hướng dẫn chi tiết về các tính năng cụ thể.',
  'home.chat6': 'Tìm hiểu thêm',
  'footer.about.text1': 'Về chúng tôi',
  'footer.about.text2': 'Cơ hội nghề nghiệp',
  'footer.about.text3': 'Liên hệ kinh doanh',
  'footer.about.text4': 'Điều khoản dịch vụ',
  'footer.about.text5': 'Chính sách bảo mật',
  'footer.about.text6': 'Cảnh báo rủi ro',
  'footer.about.text7': 'Trung tâm thông báo',
  'footer.about.text8': 'Cài đặt Cookie',
  'footer.product.text1': 'Giao dịch C2C',
  'footer.service.text1': 'Trở thành đối tác',
  'footer.service.text2': 'Tham gia làm đại lý',
  'footer.service.text3': 'Chương trình giới thiệu',
  'footer.help.text1': 'Trung tâm trợ giúp',
  'footer.help.text2': 'Hỗ trợ trực tuyến',
  'footer.help.text3': 'Góp ý và phản hồi',
  'footer.help.text4': 'Pháp lý BasicEx',
  'footer.self.text1': 'Đặt lại mật khẩu',
  'footer.self.text2': 'Mở khóa tài khoản',
  'footer.self.text3': 'Đặt lại xác thực điện thoại',
  'footer.self.text4': 'Thay đổi email',
  'footer.self.text5': 'Đặt lại Google authenticator',
  'footer.self.text6': 'Nạp tiền chưa nhận được',
  'footer.self.text7': 'Yêu cầu xóa tài khoản',
  'footer.product.text': 'Sản phẩm',
  'footer.service.text': 'Dịch vụ',
  'footer.help.text': 'Trợ giúp',
  'footer.self.text': 'Tự phục vụ',
  'down.down1': 'Bắt đầu giao dịch',
  'down.down2': 'mọi lúc mọi nơi',
  'down.down3': 'Tải ứng dụng BasicEx, tất cả giao dịch trong tầm tay.',
  'down.down4': 'Phiên bản beta iOS',
  'down.down4-1': 'Để cài đặt phiên bản beta, trước tiên',
  'down.down5': 'Tải từ Android',
  'down.down6': 'Tải từ Google Play',
  'down.down7': 'Google',
  'down.down8': 'Authenticator',
  'down.down9': 'Google Authenticator tạo mã xác thực hai bước. Với xác thực hai bước, tài khoản BasicEx của bạn sẽ an toàn hơn.',
  'down.down10': 'Tải từ App Store',
  'down.down12': 'Tải từ Google Play',
  'down.down13': 'Quét mã QR để tải iOS & Android',
  'down.footerText1': 'Số giấy phép MSB Hoa Kỳ',
};
