import localeLogin from '@/views/login/locale/vn';
import localeHome from '@/views/down/locale/vn';
import localeSettings from './vn/settings';
import localeAll from './vn/all';
import localePri from './vn/pri';
import simple from './vn/simple';

export default {
  'menu.required': 'Vui lòng điền vào tất cả các mục bắt buộc',
  'menu.locale': 'Tiếng Việt',
  'menu.dark': 'Chế độ ban đêm',
  'menu.balance': 'Số dư',
  'menu.recharge': 'Nạp tiền',
  'menu.buycoins': 'Mua coin',
  'menu.financial': 'Tài chính',
  'menu.transferaccounts': 'Chuyển khoản',
  'menu.withdrawal': 'Rút tiền',
  'menu.c2corder': 'Đơn hàng C2C',
  'menu.loginOut': 'Đăng xuất khỏi đăng nhập',
  'menu.payhis': 'Giao dịch lịch sử',
  'menu.wallethistory': 'Lịch sử ví',
  'menu.title': `BasicEx là nhà cung cấp dịch vụ thanh toán tiền điện tử hàng đầu thế giới`,
  'navbar.action.locale': 'Chuyển sang tiếng Việt',
  'public.next': 'Tiếp theo',
  'public.submit': 'gửi đến',

  ...localeSettings,
  ...localeLogin,
  ...localeHome,
  ...localeAll,
  ...localePri,
  ...simple,
};
