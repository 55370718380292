export default {
  'components.simple.756g85': '1. Tổng quan',
  'components.simple.08c0iq':
    '1.1 Dịch vụ kiếm tiền trên chuỗi (「Dịch vụ này」) là sản phẩm do Hola Limited và các công ty liên kết của nó (gọi chung là 「BasicEx」) phát hành, cho phép bạn tham gia cầm cố và nhận phần thưởng từ tiền điện tử hoặc tài sản số (「Tài sản số này」) trên chuỗi và có thể rút theo một số điều khoản và điều kiện. BasicEx chịu trách nhiệm về thiết kế và quản lý sản phẩm cũng như các dịch vụ liên quan đến Dịch vụ này. Để sử dụng Dịch vụ này, bạn cần đọc và tuân thủ Thỏa thuận sử dụng kiếm tiền trên chuỗi (「Thỏa thuận này」). Hãy đọc kỹ và hiểu rõ các điều khoản trước khi đồng ý.',
  'components.simple.u4s2bs':
    '1.2 Trừ khi bạn đã đọc và chấp nhận tất cả các điều khoản trong Thỏa thuận này, bạn không có quyền hoặc không nên sử dụng Dịch vụ này. Hành động chấp nhận Thỏa thuận này và sử dụng Dịch vụ này của bạn sẽ được coi là bạn đã đọc, hiểu và đồng ý với Thỏa thuận này, Thỏa thuận này có hiệu lực pháp lý đối với bạn.',
  'components.simple.7kr04z':
    '1.3 Thỏa thuận này sẽ bổ sung cho Điều khoản dịch vụ của BasicEx. Nếu bất kỳ điều khoản hoặc quy định nào trong Thỏa thuận này xảy ra xung đột hoặc không nhất quán với Điều khoản dịch vụ của BasicEx, sẽ giải quyết xung đột hoặc không nhất quán đó bằng cách ưu tiên xem xét Thỏa thuận này. Tất cả các quy định khác trong Điều khoản dịch vụ của BasicEx mà không được sửa đổi bởi Thỏa thuận này vẫn có hiệu lực đầy đủ.',
  'components.simple.i612nw': '2. Định nghĩa',
  'components.simple.pd935k':
    '2.1. Kiếm tiền trên chuỗi là việc khóa và giữ tài sản số trên mạng chuỗi để tham gia giao thức chuỗi đồng thuận của nó và nhận phần thưởng. Kiếm tiền trên chuỗi có thể bao gồm nhiều mạng chuỗi và công nghệ. Các chức năng và công nghệ cụ thể có thể thay đổi tùy thuộc vào mạng chuỗi liên quan. Kiếm tiền trên chuỗi có thể bao gồm nhiều mạng chuỗi và công nghệ, nó chủ yếu liên quan đến việc tham gia giao thức chuỗi đồng thuận, chẳng hạn như chứng minh cổ phần (「PoS」), để bảo vệ mạng và xác minh giao dịch.',
  'components.simple.ur1w4y':
    '2.2 Dịch vụ này là giải pháp thực hiện giao thức chuỗi PoS của mạng chuỗi áp dụng, liên quan đến cầm cố trên chuỗi để nhận phần thưởng.',
  'components.simple.quu3go':
    '2.3 Các định nghĩa khác liên quan đến dịch vụ bao gồm:',
  'components.simple.6o03hk':
    'Vốn: Số lượng tài sản số mà bạn đã gửi vào tài khoản của bạn để sử dụng Dịch vụ này.',
  'components.simple.114i84':
    'Rút: Việc rút tài sản số cầm cố cần một khoảng thời gian/ số ngày hợp lý để rút vào tài khoản của bạn.',
  'components.simple.2588q2':
    'Ngày tính phần thưởng: Có ý nghĩa như trong mục 4.4(a).',
  'components.simple.131i2v':
    'Phí dịch vụ: Là số tiền mà bạn cần thanh toán cho BasicEx khi mua hoặc tham gia Dịch vụ này, bao gồm nhưng không giới hạn trong việc thanh toán phí hoa hồng cho nút xác minh, phí mỏ đào trên chuỗi (phí gasfee) và các loại phí khác.',
  'components.simple.5y670y':
    'Cầm cố: Trong Dịch vụ này, BasicEx sẽ đóng băng một số lượng tài sản số của bạn và nhận được token hoặc tài sản số khác',
  'components.simple.6rlo4p':
    'Là PoS. Sau khi cầm cố, bạn sẽ không thể rút hoặc rút tài sản trước khi hết hạn rút.',
  'components.simple.7w4en4':
    'Ngày T: Là ngày tự nhiên tính theo giờ khu vực UTC+8.',
  'components.simple.x831kf':
    'Tỷ lệ cầm cố có thể thay đổi: Có ý nghĩa như trong mục 4.4(c) và có ý nghĩa được hiển thị và trích dẫn trên nền tảng BasicEx trong thời gian bạn tham gia Dịch vụ này.',
  'components.simple.e612g4': '3. Điều kiện sử dụng Dịch vụ này',
  'components.simple.vzb4x4': '3.1 Bạn đồng ý và xác nhận:',
  'components.simple.v6iq7u':
    'a. BasicEx sẽ cầm cố toàn bộ hoặc một phần tài sản số của bạn;',
  'components.simple.53c448':
    'b. BasicEx sẽ làm nhiệm vụ xác minh trên mạng chuỗi;',
  'components.simple.48k24p':
    'c. Bạn sẽ ủy quyền bất kỳ quyền bỏ phiếu liên quan đến tài sản số mà bạn đã cầm cố cho BasicEx;',
  'components.simple.08kj10':
    'd. BasicEx có thể từ thời gian này đến thời gian khác chỉ định dịch vụ,',
  'components.simple.v3c613':
    'số lượng tài sản số tối thiểu cần cầm cố để tham gia Dịch vụ này;',
  'components.simple.jnp1we': 'thời hạn rút tài sản số;',
  'components.simple.fafb6a': 'hạn chế rút tài sản số; và/hoặc',
  'components.simple.t2cfu8': 'hạn chế tham gia Dịch vụ này.',
  'components.simple.4zwvtu': '3.2 Bạn cũng hiểu và xác nhận：',
  'components.simple.nuu7bv':
    'Bạn đã đồng ý tuân thủ các điều khoản dịch vụ của BasicEx, đồng thời bạn có đủ điều kiện và không bị cấm sử dụng các dịch vụ được định nghĩa trong đó; các dịch vụ này bao gồm nhưng không giới hạn các hoạt động được quy định trong các điều khoản dịch vụ của BasicEx.',
  'components.simple.21xq44':
    'Nếu bạn đang đăng ký sử dụng dịch vụ này với tư cách cá nhân, để có thể chấp nhận hiệu quả các điều khoản này, bạn phải đủ 18 tuổi, là một cá nhân có đầy đủ năng lực pháp lý và năng lực hành vi, có đủ quyền và năng lực cần thiết để ký kết các điều khoản này, các điều khoản này sẽ có hiệu lực ràng buộc đối với bạn một khi được ký kết;',
  'components.simple.18143v':
    'Nếu bạn là đại diện của một pháp nhân đăng ký sử dụng dịch vụ này, để có thể chấp nhận hiệu quả các điều khoản này, bạn tuyên bố và đảm bảo rằng (i) pháp nhân này được thành lập và tồn tại hợp pháp theo luật pháp áp dụng; (ii) bạn đã được ủy quyền chính thức của pháp nhân này, có quyền đại diện cho pháp nhân này trong các giao dịch.',
  'components.simple.izihsy':
    'Bạn có khả năng nhận biết và chấp nhận rủi ro liên quan đến việc sử dụng dịch vụ này, có kinh nghiệm và kiến thức về các sản phẩm tài sản số không đảm bảo và các sản phẩm phái sinh của chúng;',
  'components.simple.4a2i9n':
    'Để sử dụng dịch vụ này, bạn có khả năng thực hiện các hoạt động tương ứng trên internet;',
  'components.simple.dl4sk2':
    'Các điều khoản này không mâu thuẫn với luật pháp của quốc gia hoặc khu vực bạn đang ở, bạn đảm bảo sẽ tuân thủ luật pháp của quốc gia hoặc khu vực bạn đang ở;',
  'components.simple.l2eqy3':
    'Bạn là chủ sở hữu hợp pháp của tài sản số trong tài khoản của bạn trên nền tảng BasicEx, và đảm bảo rằng nguồn gốc của các tài sản số này là hợp pháp.',
  'components.simple.830685': '3.3 Bạn cũng đồng ý và cam kết：',
  'components.simple.oowst3':
    'Bạn sẽ không sử dụng dịch vụ này cho bất kỳ mục đích bất hợp pháp nào, không thực hiện, khởi xướng hoặc thúc đẩy bất kỳ hình thức nào của thao túng thị trường, thao túng giá, giao dịch nội gián, bóp méo thị trường, hoặc bất kỳ hành vi xấu khác trên thị trường, bao gồm nhưng không giới hạn các hoạt động như cờ bạc bất hợp pháp, rửa tiền, lừa đảo, tống tiền, tiết lộ dữ liệu, tài trợ khủng bố, các hoạt động bạo lực khác hoặc bất kỳ hoạt động thương mại bị pháp luật cấm.',
  'components.simple.26iroe':
    '3.4 Bạn hiểu và chấp nhận rằng việc sử dụng dịch vụ này có thể mang lại rủi ro và tổn thất cho bạn, bao gồm nhưng không giới hạn các rủi ro và tổn thất sau：',
  'components.simple.gcf336':
    'Bạn có thể không thể giao dịch các tài sản số đã được cầm cố do bạn không thể giao dịch các tài sản số đã được đóng băng, bạn sẵn sàng chấp nhận rủi ro tài chính và tổn thất kinh tế có thể xảy ra do cầm cố tài sản số;',
  'components.simple.tc17i7':
    'BasicEx trên trang web hiển thị lợi nhuận dự kiến của người dùng là lợi nhuận lý thuyết dự kiến chứ không phải là lợi nhuận thực tế được BasicEx đảm bảo hoặc cam kết, lợi nhuận thực tế có thể không phù hợp với lợi nhuận dự kiến, lợi nhuận cuối cùng của bạn sẽ được tính toán dựa trên lợi nhuận thực tế nhận được, BasicEx sẽ tính toán lợi nhuận thực tế của người dùng bằng cách trừ đi chi phí, phí và chi tiêu phát sinh từ các hoạt động cầm cố liên quan của BasicEx, trừ đi phí dịch vụ cầm cố được BasicEx thu, BasicEx sẽ không đảm bảo hoặc cam kết bất kỳ lợi nhuận thực tế nào cho bạn;',
  'components.simple.0ph46i':
    'Biến động giá cao của tài sản số và các sản phẩm phái sinh của chúng có thể gây ra tổn thất lớn hoặc toàn bộ trong một thời gian ngắn;',
  'components.simple.pj8gsy':
    'Có thể xảy ra các vấn đề kỹ thuật, điều này có thể trì hoãn hoặc ngăn cản bạn sử dụng dịch vụ này hoặc thực hiện các giao dịch liên quan, điều này có thể gây ra tổn thất cho tài sản số của bạn;',
  'components.simple.c3wp4v':
    'Có thể xảy ra các vấn đề thị trường, điều này có thể gây ra tổn thất cho tài sản số của bạn；',
  'components.simple.4642wm':
    'Để duy trì sức khỏe tổng thể của thị trường, BasicEx cần không ngừng thêm, xóa hoặc sửa đổi các chính sách, các điều khoản của các thỏa thuận dịch vụ hoặc các quy định liên quan. Các hành động này có thể có lợi hoặc có hại cho các khách hàng cá nhân như bạn.',
  'components.simple.49x4f1': '4. Sử dụng dịch vụ này',
  'components.simple.19w0n6':
    '4.1 Bạn có thể thông qua khách hàng của BasicEx để phát lệnh cầm cố,赎回, v.v. Một khi lệnh đã được hoàn thành, bạn sẽ không thể hủy, thu hồi hoặc sửa đổi lệnh.',
  'components.simple.3cix8w':
    '4.2 Sau khi cầm cố thành công, bạn sẽ tự động ủy quyền và cho phép BasicEx thực hiện các hoạt động bao gồm nhưng không giới hạn các hoạt động như bỏ phiếu, tăng trưởng tài sản số trên tài khoản bị khóa của bạn.',
  'components.simple.fkx5je':
    '4.3 Một khi tài sản số đã được cầm cố hoặc khóa, BasicEx sẽ phát hành token hoặc các tài sản số khác cho bạn dưới dạng phần thưởng. Việc sử dụng các tài sản số này phải tuân theo các quy tắc và điều khoản được BasicEx công bố không định kỳ trên nền tảng BasicEx, BasicEx có quyền tự quyết định cho phép, xem xét, sửa đổi hoặc hủy bỏ bất kỳ sử dụng nào của các tài sản số này trên nền tảng BasicEx cho bất kỳ mục đích nào.',
  'components.simple.73e58y': '4.4 Phần thưởng cầm cố',
  'components.simple.1l6su3':
    'a. Phần thưởng đặt cược sẽ bắt đầu tích lũy dựa trên thời gian tích lũy của một giao thức cụ thể trên chuỗi, điều này có thể khác nhau tùy thuộc vào chuỗi khối hoặc mạng lưới bạn đặt cược. Tích lũy phần thưởng sẽ bắt đầu từ ngày bạn đăng ký dịch vụ (được gọi là "ngày tính toán phần thưởng") và sẽ được thực hiện theo lịch trình tích lũy được giao thức trên chuỗi quy định. Phần thưởng đặt cược sẽ được tính toán dựa trên số ngày đã qua trong một năm 365 ngày. Xin lưu ý rằng bạn sẽ không nhận được bất kỳ phần thưởng đặt cược nào vào ngày đầu tiên bạn đăng ký; phần thưởng của bạn sẽ bắt đầu tích lũy từ ngày thứ hai, phù hợp với thời gian tích lũy hàng ngày được giao thức trên chuỗi xác định;',
  'components.simple.60u1s3':
    'b. Phần thưởng đặt cược sẽ được phân phối vào tài khoản tiền của bạn dựa trên lịch trình phân phối của một giao thức cụ thể trên chuỗi, điều này có thể khác nhau tùy thuộc vào chuỗi khối hoặc mạng lưới bạn đặt cược. Những phần thưởng này sẽ được ghi vào tài khoản tiền của bạn vào ngày hôm sau của mỗi ngày tính toán phần thưởng, với điều kiện là phù hợp với thời gian phân phối được giao thức trên chuỗi quy định. Xin lưu ý rằng thời gian và tần suất phân phối chịu sự ràng buộc của các quy tắc và tiêu chuẩn được giao thức trên chuỗi quy định;',
  'components.simple.kjo2z0':
    'c. Tham khảo tỷ lệ lợi nhuận đặt cược có thể thay đổi được tính toán vào ngày phần thưởng đặt cược được tạo ra. Để tránh nghi ngờ, loại "tỷ lệ lợi nhuận có thể thay đổi" này, tức là tỷ lệ APY có thể thay đổi mỗi ngày dưới tình hình lợi nhuận trên chuỗi, nên được công bố trên nền tảng BasicEx và có thể thay đổi không định kỳ;',
  'components.simple.trfh0l':
    'd. Tính toán và làm tròn đến 8 chữ số sau dấu phẩy.',
  'components.simple.8q8zh6':
    'Để tránh nghi ngờ, BasicEx có thể giữ lại một phần của phần thưởng trên chuỗi. Số tiền này có thể khác với phần thưởng đặt cược. BasicEx không đảm bảo rằng bạn sẽ nhận được phần thưởng đặt cược theo tỷ lệ lợi nhuận có thể thay đổi được hiển thị khi bạn đăng ký. Về bất kỳ tuyên bố nào về lợi nhuận có thể nhận được vào thời điểm bạn đăng ký (i) chỉ là ước tính hướng dẫn và không phải là đảm bảo, (ii) dựa trên tỷ lệ lợi nhuận có thể thay đổi, tỷ lệ này có thể thay đổi, và (iii) có thể cao hơn hoặc thấp hơn phần thưởng chuỗi;',
  'components.simple.3e5int': '4.5 Quy tắc hoàn trả',
  'components.simple.mp83y6':
    'a. Số tài sản kỹ thuật số được hoàn trả vào tài khoản người dùng phải tuân theo quy tắc trao đổi của giao thức trên chuỗi, thời gian cụ thể có thể khác nhau. Xin lưu ý rằng thời gian hoàn trả có thể khác nhau tùy thuộc vào loại tài sản kỹ thuật số. Bạn hiểu và đồng ý rằng trong khoảng thời gian từ khi hoàn trả đến khi tài sản kỹ thuật số thực sự đến tài khoản, bạn sẽ không thể thực hiện bất kỳ hoạt động phản hồi, chuyển khoản hoặc chỉ dẫn nào đối với tài sản kỹ thuật số đã được hoàn trả.',
  'components.simple.8q351n':
    'b. Vào ngày hoàn trả hoặc sau đó, BasicEx có thể chỉ cho phép hoàn trả một số lượng tài sản kỹ thuật số hạn chế mỗi ngày.',
  'components.simple.u54482':
    'c. Một khi bạn đã nộp đơn yêu cầu hoàn trả, bạn sẽ không còn nhận được bất kỳ phần thưởng đặt cược nào liên quan đến tài sản kỹ thuật số bạn đã yêu cầu hoàn trả. Để tránh nghi ngờ, hành động hoàn trả tài sản kỹ thuật số không đáp ứng bất kỳ điều kiện nào của phần thưởng đặt cược. Một khi bạn đã nộp và/hoặc xác nhận đơn hoàn trả, đơn đó sẽ không thể bị hủy, thu hồi hoặc sửa đổi.',
  'components.simple.30rpa8':
    'd. Bạn nên có thể nhận được tài sản kỹ thuật số trong một khoảng thời gian hợp lý sau khi nộp đơn yêu cầu hoàn trả.',
  'components.simple.7x1wdy':
    'e. Tất cả các yêu cầu hoàn trả đều được BasicEx xử lý theo nguyên tắc "đến trước phục vụ trước" (FIFO) và được BasicEx xử lý vào cuối ngày (UTC+8 đóng cửa).',
  'components.simple.zr617j':
    'f.BasicEx có quyền thu phí dịch vụ cho việc hoàn trả tài sản kỹ thuật số, phí dịch vụ này sẽ được công bố trên nền tảng BasicEx và có thể được BasicEx sửa đổi không định kỳ.',
  'components.simple.o87n4p':
    'g. Bạn hiểu và thừa nhận rằng bạn sẽ không nhận được phần thưởng đặt cược trong thời gian hoàn trả.',
  'components.simple.46u02b':
    '4.6 Bạn sẽ có thể xem lịch sử tài khoản của mình trên nền tảng BasicEx. Bạn phải xem xét kỹ lịch sử tài khoản của mình, nếu bạn phát hiện bất kỳ mục nào bạn không nhận ra hoặc bạn cho rằng không đúng, hãy thông báo cho BasicEx ngay lập tức. BasicEx có thể sửa chữa bất kỳ sai sót nào trong lịch sử tài khoản của bạn và có quyền hủy bỏ hoặc thu hồi bất kỳ giao dịch nào:',
  'components.simple.7wu1il':
    'a. Liên quan đến hoặc phát sinh từ sai sót rõ ràng (tức là bất kỳ sai sót, thiếu sót hoặc tham chiếu sai (dù là sai sót của BasicEx hay của bất kỳ bên thứ ba nào), bao gồm sai sót của bất kỳ đại diện nào của BasicEx sau khi xem xét thị trường hiện tại và giá cả quảng cáo hiện tại, hoặc bất kỳ sai sót nào về thông tin, nguồn gốc, kết quả chính thức hoặc các kết quả khác); hoặc',
  'components.simple.a45t8y':
    'b. Phản ánh các chi tiết giao dịch được chúng tôi cho là đúng đắn hoặc công bằng.',
  'components.simple.6o3ll0':
    '4.7 Theo luật pháp và quy định áp dụng, BasicEx có thể cần chia sẻ thông tin về tài khoản và việc sử dụng nền tảng BasicEx của bạn với các bên thứ ba. Bạn xác nhận, đồng ý và cho phép chúng tôi có quyền tiết lộ thông tin này.',
  'components.simple.g9h47v': '4.8 Phí dịch vụ',
  'components.simple.4ky6gb':
    'a. BasicEx có thể thu phí dịch vụ tương ứng với dịch vụ mà nó cung cấp cho bạn. Trừ khi có quy định khác trên nền tảng BasicEx, phí dịch vụ mà BasicEx thu phí là một phần trăm cụ thể của thu nhập thực tế của bạn dưới dạng lợi nhuận thực tế (bao gồm cả lãi suất và phần thưởng), và BasicEx sẽ trừ phí dịch vụ tương ứng từ thu nhập thực tế của bạn như một phương thức thanh toán phí dịch vụ.',
  'components.simple.bvm49h':
    'b. Bạn đồng ý và ủy quyền cho BasicEx, BasicEx có quyền trừ tài sản từ tài khoản của bạn để trả phí dịch vụ cần thanh toán, nếu không, BasicEx có quyền không cung cấp dịch vụ theo hợp đồng này cho bạn.',
  'components.simple.ccy320': '5. Tạm ngừng hoặc hủy đăng ký của bạn',
  'components.simple.db7x1a':
    '5.1. Theo các điều khoản dịch vụ của BasicEx, BasicEx có thể tạm ngừng, đình chỉ hoặc hủy quyền truy cập của bạn vào nền tảng BasicEx, áp đặt hạn chế đối với bất kỳ tài khoản BasicEx nào, tạm ngừng hoặc hủy quyền đăng ký của bạn cho dịch vụ này, hoặc trì hoãn một số giao dịch: ',
  'components.simple.v196mj':
    'a. Bạn không cung cấp thông tin bổ sung theo yêu cầu của BasicEx để xác minh danh tính hoặc nguồn gốc tài chính của bạn;',
  'components.simple.18id34':
    'b. BasicEx tuân theo lệnh của tòa án, để tuân theo bất kỳ luật pháp hoặc quy định nào áp dụng (bao gồm cả luật chống rửa tiền hoặc trừng phạt);',
  'components.simple.26trc8':
    'c. BasicEx tuân theo bất kỳ chỉ dẫn hoặc hướng dẫn nào của cơ quan hoặc tổ chức chính phủ;',
  'components.simple.z13es2':
    'd. BasicEx hợp lý xác định rằng bất kỳ thông tin nào bạn cung cấp cho BasicEx là sai, không chính xác, lỗi thời hoặc không đầy đủ; hoặc',
  'components.simple.5h7y44':
    'e. Bạn không cung cấp thông tin bổ sung theo yêu cầu của BasicEx một cách hợp lý, đầy đủ hoặc kịp thời theo yêu cầu của BasicEx.',
  'components.simple.79g0s2': 'Hạn chế trách nhiệm',
  'components.simple.43vw58':
    '6.1. Bạn đồng ý và chấp nhận: BasicEx hiển thị thu nhập dự kiến của người dùng trên trang web là thu nhập lý thuyết dự kiến chứ không phải là thu nhập thực tế mà BasicEx đảm bảo hoặc cam kết, thu nhập thực tế có thể không tương xứng hoặc thấp hơn thu nhập dự kiến, thu nhập cuối cùng của bạn sẽ dựa trên thu nhập thực tế. BasicEx không đảm bảo hoặc cam kết bất kỳ thu nhập dự kiến hoặc thu nhập thực tế mà bạn có thể nhận được.',
  'components.simple.ad2oq2':
    '6.2. Bạn đồng ý và chấp nhận: Khi bạn rút tiền, thời gian nhận tiền của một số loại tiền có thể khác nhau, vui lòng xem trên trang web của loại tiền, BasicEx không chịu trách nhiệm về bất kỳ thiệt hại nào do sự tồn tại và sự khác biệt về thời gian nhận tiền gây ra.',
  'components.simple.474yoj':
    '6.3. Bạn đồng ý và chấp nhận: BasicEx có quyền tự quyết định sửa đổi nội dung của hợp đồng này bất kỳ lúc nào, nếu bạn có bất kỳ hiểu lầm nào về hợp đồng này hoặc bất kỳ sửa đổi nào của hợp đồng này mà bạn không hiểu hoặc không đọc kịp thời, BasicEx không chịu trách nhiệm về bất kỳ thiệt hại nào do điều này gây ra cho bạn.',
  'components.simple.r52ir9':
    '6.4. Bạn đồng ý và chấp nhận: Khi hệ thống nền tảng của BasicEx không thể hoạt động bình thường hoặc gây ra gián đoạn dịch vụ, làm bạn không thể sử dụng dịch vụ hoặc không thể ra lệnh hoặc thực hiện các giao dịch liên quan khác, bao gồm nhưng không giới hạn vào lỗi, trì hoãn, gián đoạn, dừng, không phản hồi, phản hồi chậm của hệ thống hoặc các trường hợp không thể sử dụng dịch vụ bình thường, BasicEx không chịu trách nhiệm về bất kỳ thiệt hại nào, trường hợp này bao gồm nhưng không giới hạn vào:',
  'components.simple.3438jn': 'Thời gian bảo trì hệ thống nền tảng BasicEx;',
  'components.simple.3w0472':
    'Lỗi thiết bị viễn thông không thể truyền dữ liệu;',
  'components.simple.2zleu8':
    'Do các yếu tố như bão, động đất, sóng thần, lũ lụt, dịch bệnh, mất điện, chiến tranh, bạo loạn, hành động của chính phủ, tấn công khủng bố và các sự kiện bất ngờ khác, gây ra sự cố hệ thống của nền tảng BasicEx không thể thực hiện các giao dịch；',
  'components.simple.i2y243':
    'Do các nguyên nhân như tấn công của hacker, virus máy tính xâm nhập hoặc phát tán, điều chỉnh hoặc lỗi kỹ thuật của các bộ phận viễn thông, nâng cấp website, vấn đề của ngân hàng, và các vấn đề tạm thời do kiểm soát của chính phủ gây ra, ảnh hưởng đến hoạt động kinh doanh bình thường của mạng lưới, dẫn đến gián đoạn hoặc chậm trễ dịch vụ；',
  'components.simple.gn8q3r':
    'Do nền tảng BasicEx bị phá hủy, tê liệt hoặc không thể sử dụng bình thường, gây ra gián đoạn hoặc chậm trễ dịch vụ；',
  'components.simple.c466p0':
    'Do các vấn đề kỹ thuật không thể dự đoán hoặc không thể giải quyết được, gây ra tổn thất；',
  'components.simple.j1764b':
    'Do sai lầm hoặc chậm trễ của bên thứ ba, gây ra tổn thất cho bạn hoặc các bên thứ ba khác；',
  'components.simple.p66n4w':
    'Do thay đổi của luật pháp hoặc các chỉ thị của chính phủ, các cơ quan quản lý hoặc thi hành pháp luật, gây ra tổn thất cho bạn hoặc các bên thứ ba khác；',
  'components.simple.q6x7d8':
    'Do các sự kiện bất ngờ, không thể dự đoán, không thể tránh khỏi và không thể khắc phục, gây ra tổn thất cho bạn hoặc các bên thứ ba khác。',
  'components.simple.jk53lr':
    'Bạn đồng ý và chấp nhận: các nguyên nhân trên có thể dẫn đến các giao dịch bất thường, gián đoạn thị trường, và các tình huống bất thường khác, BasicEx có quyền không thực hiện các chỉ thị của bạn dựa trên tình hình thực tế. Bạn hiểu và đồng ý rằng BasicEx không chịu trách nhiệm về bất kỳ tổn thất nào (bao gồm nhưng không giới hạn các tổn thất trực tiếp hoặc gián tiếp, tổn thất thực tế hoặc tổn thất lợi nhuận có thể có) do các tình huống trên gây ra cho bạn。',
  'components.simple.18dj4m':
    '6.5 Bạn đồng ý và chấp nhận: đối với bất kỳ tổn thất nào (bao gồm nhưng không giới hạn các tổn thất trực tiếp hoặc gián tiếp, tổn thất thực tế hoặc tổn thất lợi nhuận có thể có) do bạn gánh chịu do các rủi ro được nêu trong điều 3.3 của thỏa thuận này, BasicEx không chịu trách nhiệm về bất kỳ tổn thất nào.',
  'components.simple.m7quwg': 'Tuyên bố miễn trừ trách nhiệm kỹ thuật',
  'components.simple.o7k2x3':
    '7.1 BasicEx có thể tạm dừng truy cập của bạn vào tài khoản BasicEx, nền tảng BasicEx, dịch vụ này và/hoặc bất kỳ dịch vụ BasicEx nào khác để thực hiện bảo trì định kỳ và khẩn cấp. BasicEx sẽ nỗ lực hợp lý để đảm bảo xử lý kịp thời các giao dịch trên nền tảng BasicEx, nhưng BasicEx không đưa ra bất kỳ tuyên bố hoặc đảm bảo nào về thời gian cần thiết để hoàn thành các giao dịch này, điều này phụ thuộc vào nhiều yếu tố không thể kiểm soát được của BasicEx.',
  'components.simple.e4dxgz':
    '7.2 Mặc dù BasicEx đã nỗ lực hợp lý để cập nhật thông tin trên nền tảng BasicEx, nhưng BasicEx không đưa ra bất kỳ tuyên bố, đảm bảo hoặc bảo lãnh nào về tính chính xác, đầy đủ hoặc mới nhất của nội dung trên nền tảng BasicEx, bao gồm các thông tin liên quan đến dịch vụ này và bất kỳ dịch vụ BasicEx nào khác.',
  'components.simple.ebq62o':
    '7.3 Bạn phải chịu trách nhiệm về quyền truy cập internet cần thiết để sử dụng dịch vụ này hoặc bất kỳ dịch vụ BasicEx nào khác. Bạn phải chịu trách nhiệm về việc có được và cập nhật các phần cứng hoặc thiết bị tương thích cần thiết để truy cập và sử dụng dịch vụ này, bất kỳ dịch vụ BasicEx nào khác, nền tảng BasicEx và bất kỳ cập nhật nào của chúng. BasicEx không đảm bảo rằng bất kỳ dịch vụ BasicEx nào sẽ hoạt động trên bất kỳ phần cứng hoặc thiết bị cụ thể nào. Dịch vụ BasicEx có thể bị gián đoạn và chậm trễ do sử dụng internet và truyền thông điện tử.',
  'components.simple.50o29k': 'Biện pháp cưỡng chế',
  'components.simple.8vo15i':
    'Bạn đồng ý và chấp nhận: BasicEx nghiêm cấm các hành vi giao dịch không công bằng. Nếu bạn tham gia vào các hành vi sau, BasicEx có quyền tự quyết định áp dụng các biện pháp kiểm soát cao hơn đối với tài khoản của bạn hoặc đóng tài khoản của bạn:',
  'components.simple.716s6i':
    'Tham gia vào các hoạt động thao túng thị trường, thao túng giá, giao dịch nội gián, làm sai lệch thị trường, hoặc bất kỳ hoạt động thị trường xấu khác;',
  'components.simple.y7tut2':
    'Có thể thông qua việc lợi dụng các lỗ hổng của dịch vụ này hoặc các phương pháp không công bằng khác để gây hại cho các người dùng khác hoặc BasicEx;',
  'components.simple.syju60':
    'Tham gia vào bất kỳ hoạt động nào khác được BasicEx coi là có hại cho thị trường；',
  'components.simple.tvfc1s': 'Tham gia vào các hoạt động vi phạm pháp luật;',
  'components.simple.a24892':
    'Để loại bỏ bất kỳ tác động bất lợi nào đối với sức khỏe tổng thể của thị trường, BasicEx giữ quyền tự quyết định thực hiện các biện pháp sau, bao gồm nhưng không giới hạn việc đóng tài khoản của bạn, hạn chế hoặc hủy bỏ chỉ thị. Bạn hiểu và đồng ý rằng BasicEx không chịu trách nhiệm về bất kỳ tổn thất nào (bao gồm nhưng không giới hạn bất kỳ tổn thất trực tiếp hoặc gián tiếp, tổn thất thực tế hoặc tổn thất lợi nhuận có thể có) do các biện pháp trên gây ra cho bạn.',
  'components.simple.reg83l': 'Trách nhiệm bồi thường',
  'components.simple.61co62':
    '9.1 Bạn nên thực hiện mọi biện pháp có thể để đảm bảo BasicEx được miễn trừ khỏi bất kỳ thiệt hại nào phát sinh từ việc bạn sử dụng dịch vụ này hoặc bất kỳ hành vi nào khác liên quan đến tài khoản BasicEx của bạn, nếu không bạn sẽ phải chịu trách nhiệm bồi thường cho BasicEx.',
  'components.simple.q08qt1':
    '9.2 Bạn xác nhận và đồng ý rằng, đối với bất kỳ khiếu nại, yêu cầu hoặc tuyên bố nào (bao gồm tất cả các chi phí pháp lý hợp lý hoặc các chi phí khác) phát sinh từ hành vi vi phạm thỏa thuận này của bạn, hành vi sử dụng không đúng đắn dịch vụ của BasicEx, hành vi vi phạm pháp luật hoặc xâm phạm quyền của bên thứ ba, hoặc do bất kỳ hành vi hoặc không hành vi nào của bên thứ ba được ủy quyền sử dụng tài khoản BasicEx của bạn hoặc truy cập vào website, ứng dụng khách hàng, hệ thống của BasicEx (bao gồm bất kỳ website, ứng dụng khách hàng và hệ thống nào được vận hành bởi BasicEx, hoặc được vận hành cho BasicEx, hoặc được vận hành để cung cấp dịch vụ của BasicEx, và không kể các website, ứng dụng khách hàng và hệ thống đó là của BasicEx hay không), bạn sẽ phải bảo vệ, bồi thường và thực hiện mọi biện pháp để đảm bảo BasicEx không bị thiệt hại.',
  'components.simple.vsc184': 'Các vấn đề khác',
  'components.simple.71o7m1':
    '10.1 Nội dung của thỏa thuận này bao gồm các quy định và quy tắc của BasicEx, các thỏa thuận hoặc quy tắc khác được đính kèm trong thỏa thuận này, các thỏa thuận hoặc quy tắc khác có liên quan đến dịch vụ này được BasicEx phát hành định kỳ, v.v. Tất cả các nội dung trên một khi được công bố chính thức sẽ trở thành một phần không thể tách rời của thỏa thuận này, bạn đồng ý rằng bạn sẽ đọc kỹ các thỏa thuận hoặc quy tắc đó và tuân thủ các quy định có liên quan của các thỏa thuận hoặc quy tắc đó, bạn đồng ý rằng các thỏa thuận hoặc quy tắc đó có hiệu lực pháp lý đối với bạn. Nếu có bất kỳ xung đột nào giữa các nội dung trên và thỏa thuận này, thỏa thuận này sẽ được ưu tiên.',
  'components.simple.drg5f9':
    '10.2 Nếu cần thiết, BasicEx có quyền tự quyết định thay đổi nội dung của thỏa thuận này vào bất kỳ lúc nào, và BasicEx không cần thông báo trước cho bạn. Nếu BasicEx thay đổi nội dung của thỏa thuận này, các thay đổi sẽ được công bố ngay lập tức trên website của BasicEx, và vào thời điểm đó, vui lòng xem và đọc kỹ các thông tin mới nhất trên website đó. Sau khi các thay đổi được công bố, bạn hiểu và đồng ý rằng nếu bạn tiếp tục sử dụng dịch vụ này, sẽ được coi là bạn đã đồng ý với các thay đổi của thỏa thuận này và các thay đổi đó có hiệu lực pháp lý đối với bạn. Nếu bạn không đồng ý với các thay đổi của thỏa thuận này, bạn nên ngừng sử dụng dịch vụ này.',
  'components.simple.8i03ph':
    '10.3 Bạn nên đọc kỹ thỏa thuận này và bất kỳ nội dung nào khác được đề cập trong điều 10.1 và 10.2 của thỏa thuận này. Nếu bạn có bất kỳ câu hỏi nào về các nội dung trên, vui lòng liên hệ với trung tâm hỗ trợ khách hàng của chúng tôi, BasicEx sẽ cung cấp các giải thích hợp lý và cần thiết. Trừ khi BasicEx có yêu cầu khác, bạn nên liên hệ với BasicEx bằng email và thực hiện các giao dịch tài liệu với BasicEx bằng email (「giao tiếp chính thức」). Bạn cũng có thể liên hệ với BasicEx không chính thức qua điện thoại (「giao tiếp không chính thức」). Trừ khi có thỏa thuận khác, tất cả các giao tiếp chính thức đều phải được thực hiện bằng tiếng Anh. Nếu có bất kỳ sự không nhất quán nào giữa các giao tiếp bằng tiếng Anh và không bằng tiếng Anh, nội dung của giao tiếp bằng tiếng Anh sẽ được ưu tiên.',
  'components.simple.x726be':
    '10.4 Nếu bất kỳ điều khoản nào của thỏa thuận này được tuyên bố là vô hiệu, trái pháp luật hoặc không thể thi hành, hiệu lực, tính pháp lý và khả năng thi hành của các điều khoản khác của thỏa thuận này sẽ không bị ảnh hưởng hoặc suy yếu dưới bất kỳ hình thức nào. Tuy nhiên, nếu bất kỳ điều khoản nào của thỏa thuận này được tuyên bố là vô hiệu, trái pháp luật hoặc không thể thi hành trong bất kỳ khu vực pháp lý nào dưới bất kỳ luật pháp nào, thì đối với khu vực pháp lý đó, điều khoản đó sẽ được coi là đã được sửa đổi để tuân thủ các yêu cầu pháp lý tối thiểu, hoặc nếu không được coi là đã được sửa đổi, điều khoản đó sẽ chỉ vô hiệu, trái pháp luật hoặc không thể thi hành trong phạm vi vô hiệu, trái pháp luật hoặc không thể thi hành, và hiệu lực, tính pháp lý và khả năng thi hành của các điều khoản khác của thỏa thuận này sẽ không bị ảnh hưởng dưới bất kỳ hình thức nào.',
  'components.simple.34r56h':
    '10.5 BasicEx không thực hiện hoặc chậm trễ thực hiện bất kỳ quyền lợi hoặc biện pháp khắc phục nào theo thỏa thuận này sẽ không được coi là từ bỏ các quyền lợi hoặc biện pháp khắc phục đó. BasicEx chỉ có thể xác nhận thỏa thuận này bằng văn bản, nếu không sẽ không có hiệu lực. Việc thực hiện một lần hoặc một phần của bất kỳ quyền lợi hoặc biện pháp khắc phục nào sẽ không ngăn cản việc thực hiện thêm các quyền lợi hoặc biện pháp khắc phục khác, hoặc thực hiện các quyền lợi hoặc biện pháp khắc phục khác. Tất cả các quyền lợi và biện pháp khắc phục được quy định trong thỏa thuận này đều có tính chất bổ sung, không loại trừ bất kỳ quyền lợi hoặc biện pháp khắc phục nào được pháp luật quy định.',
  'components.simple.8yus3r':
    '10.6 Các điều khoản này, việc bạn sử dụng dịch vụ này, và bất kỳ tuyên bố, phản tố hoặc tranh chấp nào phát sinh trực tiếp hoặc gián tiếp từ các điều khoản này sẽ được điều chỉnh và giải thích theo luật pháp của Anh và xứ Wales, và sẽ không tính đến các quy tắc xung đột pháp luật. &nbsp;',
  'components.simple.lo454v': '10.7 Quy định và giải quyết tranh chấp',
  'components.simple.567hub':
    'a. Bạn xác nhận và đồng ý rằng, nếu xảy ra bất kỳ tranh chấp, tranh luận, khác biệt hoặc tuyên bố quyền lợi nào, bao gồm sự tồn tại, hiệu lực, giải thích, thực hiện, vi phạm hoặc chấm dứt của các điều khoản này hoặc bất kỳ tranh chấp nào phát sinh trực tiếp hoặc gián tiếp từ các điều khoản này («tranh chấp»), cả hai bên đều phải trước tiên theo quy định của Quy tắc hòa giải của Trung tâm Trọng tài Quốc tế Hồng Kông («HKIAC») để nộp tranh chấp lên HKIAC.',
  'components.simple.8gqyqi':
    'b. Nếu sau 90 ngày kể từ khi nộp đơn yêu cầu hòa giải theo quy định của điều 8.7(i) mà tranh chấp không được giải quyết bằng cách ký kết và giải quyết thỏa thuận, thì tranh chấp đó phải được nộp lên HKIAC, theo quy định của Quy tắc Trọng tài của Trung tâm Trọng tài Quốc tế Hồng Kông có hiệu lực tại thời điểm nộp thông báo trọng tài để giải quyết tranh chấp một cách cuối cùng. Luật pháp áp dụng cho các điều khoản này là luật pháp của Đặc khu Hành chính Hồng Kông của Cộng hòa Nhân dân Trung Hoa («Hồng Kông»).',
  'components.simple.v5k5kn':
    'c. Bạn đồng ý rằng địa điểm trọng tài là Hồng Kông. Số lượng trọng tài viên là ba (3) người. BasicEx sẽ chỉ định một (1) trọng tài viên, bạn sẽ chỉ định một (1) trọng tài viên. Trọng tài viên thứ ba sẽ được Chủ tịch của HKIAC chỉ định. Các trọng tài viên này sẽ được tự do lựa chọn, và các bên không bị hạn chế bởi bất kỳ danh sách chỉ định nào khi lựa chọn trọng tài viên. Thủ tục trọng tài sẽ được tiến hành bằng tiếng Anh.',
  'components.simple.pu2327':
    'd. Bạn đồng ý rằng BasicEx không nên được yêu cầu cung cấp thông tin khám phá tài liệu chung, mà chỉ có thể được yêu cầu cung cấp các tài liệu cụ thể, được xác định và có ý nghĩa thực chất đối với kết quả tranh chấp.',
  'components.simple.w86687':
    'e. Bất kỳ phán quyết trọng tài nào cũng sẽ là phán quyết cuối cùng, có hiệu lực ràng buộc đối với các bên và có thể được thi hành tại bất kỳ tòa án nào có thẩm quyền.',
  'components.simple.bw64il':
    '10.8 Không có sự đồng ý trước bằng văn bản của BasicEx, bạn không được chuyển nhượng, cập nhật hoặc cho thuê toàn bộ hoặc một phần của thỏa thuận này và các quyền và nghĩa vụ theo thỏa thuận này. BasicEx có quyền chuyển nhượng, thay thế hoặc cho thuê các quyền hoặc nghĩa vụ theo thỏa thuận này bằng cách gửi thông báo bằng văn bản cho bạn, thông báo đó sẽ có hiệu lực kể từ ngày được gửi.',
  'components.simple.137yu5':
    '10.9 Tất cả các tiêu đề của các điều khoản trong thỏa thuận này chỉ để tiện đọc, không có ý nghĩa thực chất và không thể được sử dụng làm cơ sở giải thích ý nghĩa của thỏa thuận này.',
  'components.simple.co7ivn':
    '10.10 Nếu có bất kỳ xung đột nào giữa nội dung của thỏa thuận này và các phiên bản dịch sang ngôn ngữ khác, phiên bản tiếng Anh của thỏa thuận này sẽ được coi là chuẩn.',
  'components.simple.4i87f9':
    '10.11 Quyền giải thích cuối cùng của thỏa thuận này thuộc về BasicEx.',
};
