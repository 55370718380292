export default {
  'settings.title': 'पृष्ठ सेटिंग्स',
  'settings.themeColor': 'थीम रंग',
  'settings.content': 'सामग्री क्षेत्र',
  'settings.search': 'सर्च',
  'settings.language': 'भाषा',
  'settings.navbar': 'नेविगेशन बार',
  'settings.menuWidth': 'मेनू चौड़ाई (px)',
  'settings.navbar.theme.toLight':
    'प्रकाश मोड में स्विच करने के लिए क्लिक करें',
  'settings.navbar.theme.toDark': 'अंधेरा मोड में स्विच करने के लिए क्लिक करें',
  'settings.navbar.screen.toFull':
    'पूर्ण स्क्रीन मोड में स्विच करने के लिए क्लिक करें',
  'settings.navbar.screen.toExit':
    'पूर्ण स्क्रीन मोड से बाहर निकलने के लिए क्लिक करें',
  'settings.navbar.alerts': 'संदेश सूचना',
  'settings.menu': 'मेनू बार',
  'settings.tabBar': 'मल्टीपेज टैब',
  'settings.footer': 'पाद',
  'settings.otherSettings': 'अन्य सेटिंग्स',
  'settings.colorWeak': 'रंग दुर्बलता मोड',
  'settings.alertContent':
    'सेटिंग्स करने के बाद केवल अस्थायी प्रभाव होता है, यदि आप वास्तव में प्रोजेक्ट पर प्रभाव डालना चाहते हैं, तो नीचे दिए गए "सेटिंग्स कॉपी करें" बटन पर क्लिक करें, और सेटिंग्स को settings.json में प्रतिस्थापित करें।',
  'settings.copySettings': 'सेटिंग्स कॉपी करें',
  'settings.copySettings.message':
    'कॉपी की गई, कृपया src/settings.json फाइल में पेस्ट करें।',
  'settings.close': 'बंद करें',
  'settings.color.tooltip':
    'थीम रंग के आधार पर 10 ग्रेडिएंट रंग (सेटिंग्स को प्रोजेक्ट में कॉपी करने से ही थीम रंग प्रकाश / अंधेरा मोड दोनों पर प्रभाव डाल सकता है)',
  'settings.menuFromServer': 'मेनू पीछे से आता है',
};
