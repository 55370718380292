export default {
  'overview.index.y55nvv': 'Bạn chưa xác minh danh tính!',
  'overview.index.vm3ll4': 'Xác minh ngay',
  'overview.index.7630kj':
    'Chúc mừng, bạn đã hoàn thành xác minh danh tính cơ bản!',
  'overview.index.6gv796':
    'Chúc mừng, bạn đã hoàn thành xác minh danh tính nâng cao!',
  'overview.index.w42owo': 'Tổng giá trị tài sản',
  'overview.index.nbd725':
    'Tài khoản của bạn hiện không có tài sản nào, vui lòng hoàn thành xác minh danh tính trước khi nạp tiền vào tài khoản.',
  'overview.index.6b7683':
    'Tài khoản của bạn hiện không có tài sản nào, vui lòng nạp tiền.',
  'overview.index.49nwm5': 'Mua tiền',
  'overview.index.d48b5i': 'Mua tiền điện tử một chạm',
  'overview.index.1850v5': 'Chọn từ khu vực nhanh/khu vực yêu thích',
  'overview.index.9t7e25': 'Mua ngay',
  'overview.index.fd5w2s': 'Nạp tiền',
  'overview.index.r376u3': 'Nạp tiền điện tử đã có vào BasicEx',
  'overview.index.66x2y5': 'Rút tiền từ ví ngoài vào BasicEx',
  'overview.index.d0et7a': 'Nạp ngay',
  'overview.index.y774k3': 'Nhận tiền',
  'overview.index.2yn505': 'Nhận chuyển khoản từ tài khoản BasicEx khác',
  'overview.index.8ybxdp': 'Phí gửi/nhận 0',
  'overview.index.b46qtd': 'Nhận ngay',
  'components.assets-value.18yr1j': 'Số tiền đóng băng:',
  'components.assets-value.2b3517': 'Số dư khả dụng:',
  'components.assets-value.8478m6': 'Chuyển khoản',
  'components.assets-value.687r4q': 'Rút tiền',
  'components.assets-value.w22527': 'Ngày',
  'components.assets-value.ge43pz':
    'Chúng tôi sẽ cập nhật dữ liệu tài sản trước 16:00 giờ Hồng Kông hàng ngày.',
  'components.assets-value.zghh33': 'Thu nhập',
  'components.assets-value.a1dr9x': 'Chi tiêu',
  'components.assets-value.ly1k52':
    'Số tiền đóng băng bao gồm số tiền chờ thanh toán và số tiền bị hệ thống đóng băng',
  'components.assets-value.j70i73':
    'Gửi tiền điện tử đến người dùng BasicEx, phí 0',
  'components.assets-value.jj3hg4': 'Thu nhập',
  'components.assets-value.i6j148': 'Chi tiêu',
  'components.header-info.12t323': 'BasicEx ID',
  'components.header-info.um3476': 'Loại người dùng',
  'components.header-info.4m03sm': 'Thời gian đăng nhập gần nhất',
  'components.header-info.3787t3':
    'Lưu ý: Biệt danh chỉ có thể thay đổi một lần, vui lòng xác nhận cẩn thận trước khi thay đổi!',
  'components.header-info.pj4n5u': 'Chưa xác minh',
  'components.header-info.zqw278': 'Xác minh cơ bản',
  'components.header-info.jd9962': 'Xác minh nâng cao',
  'components.header-info.43h887': 'Người dùng cá nhân',
  'components.header-info.yqi9ko': 'Người dùng doanh nghiệp',
  'components.header-info.e2tp8h': 'Chỉnh sửa biệt danh',
  'components.header-info.1f7mrx': 'Đặt biệt danh tùy chỉnh cho hồ sơ của bạn.',
  'components.header-info.5noe2d': 'Biệt danh',
  'components.header-info.p63d76': 'Vui lòng nhập biệt danh bạn muốn thay đổi',
  'components.header-info.xwpc25': 'Biệt danh không được để trống',
  'components.revence-data.9713y6': 'Chi tiết thu chi',
  'components.revence-data.npd4wy': 'Thêm',
  'components.revence-data.ce4131': 'Yêu cầu rút tiền đã được gửi',
  'components.revence-data.9752t3': 'Đang xử lý hệ thống',
  'components.revence-data.62xf33': 'Đã hoàn thành',
  'components.revence-data.2iv5ux': 'Trạng thái',
  'components.revence-data.3y5g5i': 'Loại tiền',
  'components.revence-data.4wmme1': 'Số tiền rút',
  'components.revence-data.tyfp3z': 'Phí giao dịch',
  'components.revence-data.s8967j': 'Mạng chuyển khoản',
  'components.revence-data.do87js': 'Số dư khả dụng',
  'components.revence-data.q5vlq3': 'Số tiền đóng băng',
  'components.revence-data.46f9tr': 'Địa chỉ',
  'components.revence-data.tle9fw':
    'Lưu ý: Chúng tôi sẽ gửi email thông báo khi hoàn thành rút tiền. Tại sao khoản rút tiền của tôi',
  'components.revence-data.zfuugj': 'chưa được nhận?',
  'components.revence-data.rfkndq': 'Số tiền',
  'components.revence-data.61xy2u': 'Từ',
  'components.revence-data.354789': 'Đến',
  'components.revence-data.2uv9jf': 'Thời gian tạo',
  'components.revence-data.rfyt9g': 'Mã đơn hàng',
  'components.revence-data.3hb0uk': 'Ghi chú',
  'components.revence-data.35h0kp': 'Thành công',
  'components.revence-data.7h5jsb': 'Thời gian',
  'components.revence-data.j8k864': 'Số tiền nạp',
  'components.revence-data.543v48': 'Đang xử lý',
  'components.revence-data.5s4r08': 'Đang duyệt',
  'components.revence-data.dyxzu8': 'Đã hủy',
  'components.revence-data.868ebs': 'Thất bại',
  'components.revence-data.ltoesk': 'Chờ thanh toán',
  'components.revence-data.g1qbzi': 'Đã thanh toán, chờ xác nhận',
  'components.revence-data.kq9g21': 'Đang khiếu nại',
  'components.revence-data.jfjmj4': 'Không có dữ liệu',
  'components.revence-data.268t86': 'Chi tiết nạp tiền',
  'components.revence-data.k51q8g': 'Chi tiết rút tiền',
  'components.revence-data.4l1jq3': 'Chi tiết chuyển khoản',
  'components.revence-data.9sc1cn': 'Loại',
  'components.revence-data.hpr4p5': 'Số lượng',
  'accaction.index.87m503': 'Bảo mật tài khoản',
  'accaction.index.5565bk': 'Hoạt động tài khoản gần đây',
  'accaction.index.p55715': 'Tài khoản bất thường?',
  'accaction.index.v7gjvv': 'Vô hiệu hóa tài khoản',
  'accaction.index.y3gm71': 'Hoạt động đăng nhập',
  'accaction.index.ey2758': 'Lịch sử hoạt động bảo mật',
  'accaction.index.qznrs3': 'Nguồn',
  'accaction.index.75e7l9': 'Địa chỉ IP',
  'address.batchaddaddress.6b7h2u': 'Thêm nhiều địa chỉ',
  'address.batchaddaddress.lx0837': 'Thêm',
  'address.batchaddaddress.5g5at6': 'Hủy',
  'address.batchaddaddress.0c092d': 'Lưu',
  'address.batchaddaddress.7u2hjf':
    'Địa chỉ bạn đã nhập chưa được lưu, bạn có muốn hủy lưu không?',
  'address.batchaddaddress.s47gk6': 'Tiếp tục chỉnh sửa',
  'address.batchaddaddress.s319i3': 'Ghi chú địa chỉ',
  'address.batchaddaddress.kttf6p': 'Nguồn địa chỉ',
  'address.batchaddaddress.qe79l9': 'Thao tác',
  'address.batchaddaddress.8313nb': 'Không cần điền',
  'address.index.6p7di6': 'Quản lý địa chỉ',
  'address.index.1n20p9': 'Đã tắt giới hạn danh sách trắng',
  'address.index.59zoxy': 'Cài đặt danh sách trắng',
  'address.index.af4395': 'Tất cả',
  'address.index.tf8k5s': 'Danh sách trắng',
  'address.index.pqk179': 'Tìm kiếm',
  'address.index.jrqg1h': 'Xuất hàng loạt',
  'address.index.xih4ay': 'Thêm địa chỉ',
  'address.index.t6l0wx': 'Đã chọn',
  'address.index.o0w7e4': 'địa chỉ, bạn muốn:',
  'address.index.39qj58': 'Thêm vào danh sách trắng',
  'address.index.83dxt5': 'Xóa khỏi danh sách trắng',
  'address.index.s01fov': 'Xóa',
  'address.index.80651m': 'Thêm địa chỉ rút tiền',
  'address.index.86kz67':
    'Lưu làm địa chỉ chung, có thể áp dụng cho nhiều loại tiền điện tử',
  'address.index.vwcg4v': 'Địa chỉ danh sách trắng',
  'address.index.n6u1h8':
    'Sau khi bật tính năng danh sách trắng, bạn chỉ có thể rút tiền đến các địa chỉ trong danh sách trắng.',
  'address.index.5tmj7t': 'Tìm kiếm địa chỉ',
  'address.index.u5b9jk': 'Địa chỉ không trong danh sách trắng',
  'address.index.x4cqzf': 'Không được để trống!',
  'address.index.2k9010':
    'Vui lòng nhập ghi chú từ 4-20 ký tự, ví dụ: Tài khoản BasicEx của Minh',
  'address.index.eii4u3': 'Địa chỉ rút tiền',
  'address.index.bdqqx0': 'Vui lòng nhập địa chỉ rút tiền',
  'address.index.871rhx':
    'Sau khi bật danh sách trắng, tài khoản của bạn chỉ có thể rút tiền đến các địa chỉ trong danh sách trắng, giúp bảo vệ tài sản của bạn.',
  'address.index.31c20i': 'Bạn có chắc chắn muốn xóa địa chỉ này không?',
  'address.index.7uq2y9': 'Sổ địa chỉ',
  'address.index.r539eg': 'Mạng chuyển khoản không được để trống',
  'address.index.kw2845': 'Địa chỉ chung',
  'address.index.fp19tw': 'Địa chỉ thông thường',

  'changepassword.index.u8w3oi': 'Đổi mật khẩu',
  'changepassword.index.8473bq': 'Gửi',
  'changepassword.index.6qts82': 'Đổi mật khẩu thành công',
  'changepassword.index.7bh14e':
    'Mật khẩu của bạn đã được thay đổi, vui lòng đăng nhập bằng mật khẩu mới',
  'changepassword.index.awho4e': 'Đăng nhập',
  'changepassword.index.157jed': 'Mật khẩu cũ',
  'changepassword.index.2rye5l': 'Vui lòng nhập',
  'changepassword.index.7yf04o': 'Mật khẩu mới',
  'changepassword.index.51j37z': 'Xác nhận mật khẩu mới',
  'changepassword.index.6382f8':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm số và chữ cái viết hoa và thường',
  'changepassword.index.o2u857': 'Hai mật khẩu không khớp',
  'changepassword.index.71mx3u': 'Mật khẩu không đúng!',
  'device.index.338736': 'Thiết bị:',
  'device.index.6f1n27': 'Quản lý thiết bị',
  'device.index.b30ayt':
    'Những thiết bị này đã được ủy quyền để đăng nhập vào tài khoản của bạn',
  'device.index.r0pnkc': 'Thiết bị',
  'device.index.3o1ncj': 'Vị trí đăng nhập',
  'disableacc.index.i6lc27': 'Hoạt động tài khoản',
  'disableacc.index.e6oy6i':
    'Vô hiệu hóa tài khoản của bạn sẽ dẫn đến những kết quả sau:',
  'disableacc.index.o45g18':
    'Nếu bạn quyết định kích hoạt lại tài khoản, vui lòng đăng nhập sau hai giờ vô hiệu hóa để thực hiện quy trình mở khóa',
  'disableacc.index.u61qfr': 'Xóa tài khoản này',
  'disableacc.index.t3d7tt': 'Bạn có chắc chắn muốn xóa tài khoản này không?',
  'disableacc.index.sk863i':
    'Tất cả các chức năng giao dịch và đăng nhập sẽ bị vô hiệu hóa',
  'disableacc.index.893l4o': 'Xóa tất cả thiết bị tin cậy',
  'disableacc.index.56bm0l':
    'Tất cả các lệnh rút tiền đang chờ xử lý sẽ bị hủy',
  'disableacc.index.n4932t': 'Tất cả các lệnh hiện tại sẽ bị hủy',
  'disableacc.index.803x4k': 'Thông tin xác minh danh tính sẽ không bị xóa',

  'email.bindemail.6oti7n': 'Xác minh liên kết email',
  'email.bindemail.77it3v': 'Nhận mã xác minh',
  'email.bindemail.n0705g': 'Nhận sau s',
  'email.bindemail.2k8gfj': 'Mã xác minh liên kết email',
  'email.bindemail.6275ov': 'Email mới',
  'email.bindemail.vx440f': 'Email không được để trống!',
  'email.bindemail.4r361n': 'Vui lòng nhập địa chỉ email mới',
  'email.bindemail.5y253x': 'Mã xác minh email',
  'email.bindemail.d7l1pb': 'Mã xác minh email không được để trống!',
  'email.bindemail.q0840y': 'Vui lòng nhập mã xác minh email mới',
  'email.changeemail.rm3mkt': 'Xác minh thay đổi email',
  'email.changeemail.sui27i': 'Mã xác minh thay đổi email',
  'email.changeemail.fpep15': 'Mã xác minh email mới',
  'email.unbindemail.igv777': 'Xác minh hủy liên kết email',
  'email.unbindemail.1467zm': 'Xác minh bảo mật',
  'email.unbindemail.6un89v': 'Quay lại',
  'fish.changefish.09en1y': 'Thay đổi mã chống lừa đảo',
  'fish.changefish.1f4oi3': 'Mã chống lừa đảo xuất hiện ở đâu?',
  'fish.changefish.x47u89':
    'Vui lòng nhập 4-10 ký tự, không bao gồm ký tự đặc biệt.',
  'fish.changefish.dteu66': 'Xác nhận',
  'fish.changefish.x30e85':
    'Không chia sẻ mật khẩu, mã xác minh Google hoặc SMS với bất kỳ ai, kể cả nhân viên hỗ trợ BasicEx.',
  'fish.changefish.545pu5': 'Mã chống lừa đảo cũ',
  'fish.changefish.lkyri4': 'Mã chống lừa đảo mới',
  'fish.changefish.bry0ys':
    'Không được chứa ký tự Trung Quốc và ký tự đặc biệt!',
  'fish.changefish.go8188': 'Yêu cầu từ 4-20 ký tự',
  'fish.setfinsh.61569g': 'Thiết lập mã chống lừa đảo',
  'fish.setfinsh.o8230x': 'Mã chống lừa đảo là gì?',
  'fish.setfinsh.6t45bq':
    'Mã chống lừa đảo là chuỗi ký tự do bạn tự thiết lập, giúp bạn nhận biết các trang web hoặc email giả mạo BasicEx',
  'fish.setfinsh.7cf26c': 'Mã chống lừa đảo xuất hiện ở đâu?',
  'fish.setfinsh.1rske9':
    'Sau khi thiết lập mã chống lừa đảo, mọi email BasicEx gửi cho bạn sẽ chứa chuỗi ký tự này.',
  'fish.setfinsh.29c19t': 'Tạo mã chống lừa đảo',
  'fish.setfinsh.bk3ekb': 'Mã chống lừa đảo',
  'components.alert.172xn1':
    'Để bảo vệ tài sản của bạn, sau khi thay đổi hoặc hủy liên kết xác minh, tài khoản sẽ bị vô hiệu hóa dịch vụ thanh toán, rút tiền và bán C2C trong 24 giờ.',
  'google.bindgoogle.bj1319': 'Liên kết xác minh Google',
  'google.bindgoogle.t70ckm': 'Tải và cài đặt ứng dụng xác thực',
  'google.bindgoogle.1c6pys': 'Làm thế nào để sử dụng Google Authenticator?',
  'google.bindgoogle.y8n99f': 'Tiếp theo',
  'google.bindgoogle.7v5exp':
    'Quét mã QR này bằng ứng dụng Google Authenticator',
  'google.bindgoogle.orfs9q':
    'Nếu bạn không thể quét mã QR này, vui lòng nhập chuỗi ký tự này vào ứng dụng',
  'google.bindgoogle.cl439i': 'Quay lại',
  'google.bindgoogle.yvvt3q': 'Sao lưu và lưu khóa bí mật ở nơi an toàn',
  'google.bindgoogle.l8ru6j':
    'Nếu bạn mất điện thoại, khóa này có thể được sử dụng để khôi phục trình xác thực của bạn.',
  'google.bindgoogle.7vi621':
    'Nếu không, việc đặt lại xác thực BasicEx/Google với sự trợ giúp của bộ phận hỗ trợ sẽ mất ít nhất 7 ngày.',
  'google.bindgoogle.p80e6s':
    'Bạn cần xác minh tài khoản trước khi liên kết Google Authenticator',

  'google.bindgoogle.255n21':
    'Vui lòng nhập mã xác minh 6 chữ số từ Google Authenticator',
  'google.bindgoogle.l4i130': 'Trình xác thực đã được thay đổi',
  'google.bindgoogle.32j9s6':
    'Bây giờ bạn có thể đăng nhập bằng trình xác thực mới.',
  'google.bindgoogle.k8240u': 'Mã xác minh BasicEx/Google',
  'google.bindgoogle.5wml8v': 'Tải ứng dụng',
  'google.bindgoogle.35sm5m': 'Quét mã QR mới',
  'google.bindgoogle.4z51qi': 'Sao lưu khóa mới',
  'google.bindgoogle.55623i': 'Kích hoạt trình xác thực mới',
  'google.bindgoogle.5nl6v0': 'Mã xác minh phải là 6 chữ số',
  'google.changegoogle.bp2ib0': 'Thay đổi Google Authenticator',
  'google.changegoogle.0150qu':
    'Bạn cần xác minh tài khoản trước khi hủy liên kết trình xác thực cũ',
  'google.changegoogle.892net': 'Hủy liên kết trình xác thực cũ',
  'google.unbindgoogle.067sm6': 'Hủy liên kết Google Authenticator cũ',
  'phone.bindphone.6y56ed': 'Xác minh liên kết điện thoại',
  'phone.bindphone.14257g': 'Vui lòng nhập mã xác minh SMS',
  'phone.bindphone.rgfl35': 'Số điện thoại',
  'phone.bindphone.cjdlml': 'Vui lòng nhập số điện thoại',
  'phone.bindphone.xel50u': 'Mã xác minh điện thoại',
  'phone.bindphone.3c27v0': 'Số điện thoại không được để trống!',
  'phone.changephone.12r3e5': 'Xác minh thay đổi điện thoại',
  'phone.changephone.e5x5v7':
    'Để bảo vệ tài sản của bạn, sau khi thay đổi hoặc hủy liên kết xác minh, tài khoản sẽ bị vô hiệu hóa dịch vụ thanh toán, rút tiền và bán C2C trong 24 giờ.',
  'phone.changephone.7wjyni': 'Vui lòng nhập mã đã gửi đến',
  'phone.changephone.780e3h': 'mã xác minh 6 chữ số',
  'phone.changephone.k33121': 'Mã xác minh thay đổi điện thoại',
  'phone.changephone.r34o47': 'Số điện thoại mới',
  'phone.changephone.4end8x': 'Số điện thoại không được để trống!',
  'phone.unbindphone.4yg1w8': 'Xác minh hủy liên kết điện thoại',
  'reset.index.6089f8': 'Đặt lại xác minh bảo mật',
  'reset.index.li0671': 'Vui lòng chọn mục bảo mật không khả dụng:',
  'reset.index.qihc2i': 'Xác nhận đặt lại',
  'reset.index.j3x4d5': 'mã xác minh SMS 6 chữ số',
  'reset.index.wu2244': 'Bạn đang yêu cầu các thao tác đặt lại sau:',
  'reset.index.albrnw': 'Xác minh số điện thoại mới',
  'reset.index.315820': 'Mã xác minh điện thoại mới',
  'reset.index.vs4ka9': 'Vui lòng nhập email mới bạn muốn kích hoạt xác minh',
  'reset.index.92fyx1': 'Đặt lại số điện thoại',
  'reset.index.3qyt3q': 'Đặt lại email',
  'reset.index.307sy0': 'Tắt Google Authenticator',
  'reset.index.3gs1p2': 'Google Authenticator không khả dụng, yêu cầu đặt lại.',
  'reset.index.6odl42': 'Vui lòng chọn ít nhất một phương thức không khả dụng!',
  'reset.index.41hjjt': 'Số điện thoại {0} không khả dụng, yêu cầu đặt lại.',
  'reset.index.jsuwbx': 'Email {0} không khả dụng, yêu cầu đặt lại.',
  'accountsecurity.index.jeh767': 'Xác minh hai yếu tố (2FA)',
  'accountsecurity.index.6i7fi7': 'Gặp sự cố?',
  'accountsecurity.index.3vr5uk': 'Liên kết',
  'accountsecurity.index.yh5rux': 'Thay đổi',
  'accountsecurity.index.g4x63c': 'Hủy liên kết',
  'accountsecurity.index.qlf4hm': 'Bảo mật nâng cao',
  'accountsecurity.index.1h64q4': 'Bật',
  'accountsecurity.index.l73sj8': 'Tắt',
  'accountsecurity.index.i33811': 'Đăng nhập gần đây',
  'accountsecurity.index.mc345k': 'Rút tiền nhanh',
  'accountsecurity.index.wi2qnw': 'Hạn mức miễn xác minh trong 24 giờ:',
  'accountsecurity.index.9m6b36':
    'Để bảo vệ an toàn tài sản của bạn, ngay cả khi bạn đã bật rút tiền nhanh, chúng tôi vẫn có thể yêu cầu bạn xác thực bảo mật dựa trên mức độ an toàn tài khoản của bạn.',
  'accountsecurity.index.j7591y':
    'Giới hạn thời gian cấm rút tiền cho địa chỉ danh sách trắng mới',
  'accountsecurity.index.4nrynd':
    'Nếu tắt tính năng này, chức năng rút tiền của bạn sẽ bị cấm trong 24 giờ',
  'accountsecurity.index.j7bqkc':
    'Sau khi tắt tính năng này, địa chỉ danh sách trắng mới thêm có thể được sử dụng ngay để rút tiền.',
  'accountsecurity.index.797yxm':
    'Vui lòng xem xét cấp độ bảo mật tài khoản của bạn trước khi tắt tính năng này.',
  'accountsecurity.index.688s59':
    'Các thao tác như rút tiền cần bật hai bước xác minh bảo mật, chỉ giữ một bước sẽ ảnh hưởng đến việc rút tiền!',

  'accountsecurity.index.98r7qd': 'Chưa liên kết',
  'accountsecurity.index.to6o94': 'Google Authenticator (Khuyến nghị)',
  'accountsecurity.index.zm5d58': 'Xác minh điện thoại',
  'accountsecurity.index.1fmg25': 'Xác minh email',
  'accountsecurity.index.2qgw1x': 'Danh sách trắng rút tiền',
  'accountsecurity.index.r8g22r': 'Giới hạn bảo mật địa chỉ danh sách trắng',
  'accountsecurity.index.mxdyyw': 'Mật khẩu đăng nhập',
  'accountsecurity.index.b47e47': 'Quản lý',
  'accountsecurity.index.s7684g': 'Hạn mức miễn xác minh một lần',
  'accountsecurity.index.2w8i2e': '24 giờ',
  'accountsecurity.index.705k7v': '48 giờ',
  'accountsecurity.index.4ee8so': '72 giờ',
  'accountsecurity.index.528m37': 'Xác minh danh tính',
  'accountsecurity.index.yx274i':
    'Bảo vệ giao dịch và bảo mật tài khoản của bạn',
  'accountsecurity.index.21goo8': 'Kích hoạt',
  'accountsecurity.index.2t85w6':
    'Dùng để quản lý mật khẩu đăng nhập tài khoản của bạn',
  'accountsecurity.index.z43335': 'Cài đặt rút tiền',
  'accountsecurity.index.7bfk11':
    'Sau khi bật tính năng này, bạn có thể miễn xác minh bảo mật khi rút số tiền nhỏ đến địa chỉ danh sách trắng',
  'accountsecurity.index.530ut2':
    'Khi kích hoạt tính năng này, tài khoản của bạn chỉ có thể rút tiền đến địa chỉ trong danh sách trắng',
  'accountsecurity.index.i27mc3':
    'Bằng cách thiết lập mã chống lừa đảo, bạn có thể xác định xem email bạn nhận có phải từ BasicEx hay không',
  'accountsecurity.index.q786rj':
    'Thiết bị đã được ủy quyền cho phép đăng nhập tài khoản',
  'accountsecurity.index.6968fe': 'Đã bật rút tiền nhanh!',
  'accountsecurity.index.13dw21': 'Đã bật thành công danh sách trắng!',
  'accountsecurity.index.g2c5k3': 'Đã tắt danh sách trắng!',
  'accountsecurity.index.4p2g2d': 'Bạn có chắc chắn muốn thay đổi',
  'accountsecurity.index.ks8y15': 'Bạn có chắc chắn muốn hủy liên kết',
  'accountsecurity.index.s123zs': 'Google Authenticator?',
  'accountsecurity.index.747q6p': 'xác minh điện thoại?',
  'accountsecurity.index.bu8j46': 'xác minh email?',
  'accountsecurity.index.zxk4z3':
    'Để bảo vệ tài sản của bạn, sau khi thay đổi hoặc hủy liên kết xác minh, tài khoản sẽ bị vô hiệu hóa dịch vụ thanh toán, rút tiền và bán C2C trong 24 giờ.',
  'accountsecurity.index.k9sv5l': 'Bật danh sách trắng rút tiền',
  'accountsecurity.index.5v4733':
    'Khi kích hoạt tính năng này, tài khoản của bạn chỉ có thể rút tiền đến địa chỉ trong danh sách trắng.',
  'accountsecurity.index.4n64be':
    'Vui lòng xác nhận bạn có muốn "tắt giới hạn bảo mật địa chỉ danh sách trắng" không?',
  'accountsecurity.index.b1sj33':
    'Email đã bị hủy liên kết hoặc thay đổi không được phép đăng ký trong 30 ngày và không được phép liên kết hoặc thay đổi sang tài khoản khác trong 30 ngày.',
  'accountsecurity.index.2399b8':
    'Bạn sẽ có thể rút tiền đến bất kỳ địa chỉ nào, điều này có thể dẫn đến rủi ro lớn hơn. Bạn có chắc chắn muốn vô hiệu hóa danh sách trắng rút tiền không?',
  'accountsecurity.index.6c8350': 'Xác nhận xóa thiết bị này?',
  'accountsecurity.index.44c15i':
    'Sau khi vô hiệu hóa, vui lòng đảm bảo tài khoản an toàn trước khi mở khóa lại.',
  'accountsecurity.index.x8b688': 'Tắt rút tiền nhanh',
  'accountsecurity.index.yoo2x8': 'Tắt danh sách trắng rút tiền',
  'accountsecurity.index.77we05':
    'Vui lòng xác nhận bạn có muốn "bật giới hạn bảo mật địa chỉ danh sách trắng" không?',
  'accountsecurity.index.5c1bpu': 'Thay đổi thời gian cấm rút tiền',
  'accountsecurity.index.p95b65': 'Thay đổi thời gian cấm rút tiền',
  'accountsecurity.index.r5318m':
    'Vui lòng liên kết email trước khi hủy liên kết số điện thoại',
  'accountsecurity.index.y27sd3':
    'Vui lòng liên kết số điện thoại trước khi hủy liên kết email',
  'accountsecurity.index.8t87mw':
    'Đã bật "Giới hạn bảo mật địa chỉ danh sách trắng", địa chỉ danh sách trắng mới thêm sẽ bị cấm rút tiền trong {0} giờ.',
  'identityauthentication.enterpriseerr.f1lrt4': 'Xác thực danh tính thất bại',
  'identityauthentication.enterpriseerr.z33fdl':
    'Vui lòng bắt đầu lại quy trình xác minh và kiểm tra trước xem giấy tờ của bạn có hợp lệ và rõ ràng không.',
  'identityauthentication.enterpriseerr.71f92d': 'Xác minh lại',
  'identityauthentication.enterpriseidcard.h5u4s1': 'Xác minh doanh nghiệp',
  'identityauthentication.enterpriseidcard.cu8i9m':
    'Thông tin cơ bản doanh nghiệp',
  'identityauthentication.enterpriseidcard.qvft88': 'Khu vực cư trú',
  'identityauthentication.enterpriseidcard.ln74wk':
    'Chọn quốc gia hoặc khu vực bạn đang sinh sống. Bạn chỉ cần trả lời các câu hỏi theo yêu cầu của luật pháp địa phương.',
  'identityauthentication.enterpriseidcard.0hze6m': 'Quốc tịch',
  'identityauthentication.enterpriseidcard.816vg3': 'Họ tên',
  'identityauthentication.enterpriseidcard.mwg4s7': 'Chọn giấy tờ',
  'identityauthentication.enterpriseidcard.n927hl': 'Số giấy tờ',
  'identityauthentication.enterpriseidcard.6u8b6m':
    'Tại sao tôi cần xác minh danh tính?',
  'identityauthentication.enterpriseidcard.dtocnj':
    'Xác minh danh tính cho phép bạn có hạn mức rút tiền và giao dịch cao hơn. Hạn mức rút tiền tối đa trong 24 giờ là 50K USDT.',
  'identityauthentication.enterpriseidcard.4kk33v': 'Trung Quốc',
  'identityauthentication.enterpriseidcard.d81gjt': 'Vui lòng nhập họ tên',
  'identityauthentication.enterpriseidcard.840u6c': 'CMND',
  'identityauthentication.enterpriseidcard.9vrkn4': 'Vui lòng nhập số giấy tờ',
  'identityauthentication.enterpriseidcard.q64mg8': 'Hoa Kỳ',
  'identityauthentication.enterprisesuccess.j1u6ev':
    'Xác minh doanh nghiệp đã hoàn tất',
  'identityauthentication.enterprisesuccess.8coosb':
    'Bạn đã vượt qua xác minh KYC doanh nghiệp và có thể giao dịch tiền điện tử không giới hạn',
  'identityauthentication.enterprisesuccess.8qnd58': 'Bắt đầu giao dịch',
  'identityauthentication.enterpriseverification.ns7yt4': 'Xác minh tài liệu',
  'identityauthentication.enterpriseverification.88kdei':
    'Tải lên giấy phép kinh doanh',
  'identityauthentication.enterpriseverification.ey7dp3':
    'Vui lòng tải lên tệp định dạng .jpg/.jpeg/.png dưới 10MB',
  'identityauthentication.enterpriseverification.s64287': 'Sử dụng camera',
  'identityauthentication.enterpriseverification.hpuo9s':
    'Tải lên CMND của người đại diện pháp luật',
  'identityauthentication.enterpriseverification.e3mkb4':
    'Mặt trước CMND (mặt có ảnh)',
  'identityauthentication.enterpriseverification.cd94nk':
    'Mặt sau CMND (mặt có quốc huy)',
  'identityauthentication.enterpriseverification.0hxcb7':
    'Đảm bảo giấy tờ đáp ứng tất cả các tiêu chuẩn sau:',
  'identityauthentication.enterpriseverification.587dk6':
    'Có thể đọc được và rõ ràng',
  'identityauthentication.enterpriseverification.5yiwid':
    'Không bị chói sáng, không mờ',
  'identityauthentication.enterpriseverification.3qir5b':
    'Không phải ảnh đen trắng, không bị chỉnh sửa',
  'identityauthentication.enterpriseverification.f5d5nf':
    'Vui lòng đặt giấy tờ trên nền đơn sắc',
  'identityauthentication.idcard.rb3w44': 'Xác minh cá nhân',
  'identityauthentication.idcard.0qc2n1': 'Quốc gia/Khu vực',
  'identityauthentication.idcard.83ui8q':
    'Vui lòng truy cập ứng dụng di động BasicEx để xác minh danh tính',
  'identityauthentication.idcard.7nqj15':
    'Bạn có thể giao dịch tối đa 50K USDT. Để có thêm hạn mức, vui lòng thực hiện xác minh video nâng cao.',
  'identityauthentication.idcard.7nqj14':
    'Bạn có thể giao dịch tiền điện tử tối đa 50K USDT. Để có hạn mức cao hơn, vui lòng',
  'identityauthentication.idcard.51831f':
    'truy cập ứng dụng BasicEx để xác minh video nâng cao',
  'identityauthentication.idcard.n8e533': 'Tải ứng dụng BasicEx',
  'identityauthentication.idcard.xjw8i8':
    'Vui lòng chọn hoặc tìm kiếm quốc gia/khu vực',
  'identityauthentication.idcard.0gcncv': 'Vui lòng chọn loại giấy tờ',
  'identityauthentication.idcard.d6pvi4':
    'Vui lòng điền tất cả các mục bắt buộc',
  'identityauthentication.idsuccess.5o2663':
    'Xác minh thông tin cá nhân đã hoàn tất',
  'identityauthentication.index.c3zv6w':
    'Bắt đầu giao dịch ngay sau khi xác minh danh tính!',
  'identityauthentication.index.t582wo': 'Xác minh cá nhân',
  'identityauthentication.index.1hjssv':
    'Giao dịch với tư cách cá nhân, cung cấp thông tin cá nhân.',
  'identityauthentication.index.y28m4x':
    'Tôi có thể tạo cả tài khoản cá nhân và doanh nghiệp không?',
  'identityauthentication.index.6752e4':
    'Xin lỗi, vui lòng chọn cẩn thận loại tài khoản của bạn, hiện không hỗ trợ nhiều loại tài khoản',
  'identityauthentication.personal.ve2gkh':
    'Khuyến nghị nâng cấp xác minh nâng cao để tăng hạn mức giao dịch.',
  'identityauthentication.personal.ht7x5k':
    'Bạn đã vượt qua xác minh nâng cao và có thể sử dụng tất cả dịch vụ.',
  'identityauthentication.personal.b1468c':
    'Xác minh cơ bản - Thông tin cá nhân',
  'identityauthentication.personal.c64mk9':
    'Hạn mức rút tiền thông thường, giới hạn 24 giờ 50K USDT',
  'identityauthentication.personal.2d1typ': 'Hạn mức giao dịch C2C 50K USDT',
  'identityauthentication.personal.1b931w': 'Xác minh ngay',
  'identityauthentication.personal.877chb': 'Đã xác minh',
  'identityauthentication.personal.c3i45p':
    'Xác minh nâng cao - Xác minh video',
  'identityauthentication.personal.cl5cd4':
    'Tăng hạn mức rút tiền, giới hạn 24 giờ 8M USDT',
  'identityauthentication.personal.23n4n6':
    'Tăng hạn mức giao dịch C2C 8M USDT',
  'identityauthentication.personal.bc8i1z':
    'Tại sao tôi phải xác minh danh tính?',
  'identityauthentication.personal.t13x7g':
    'Xác minh danh tính là để tuân thủ các quy định tài chính hoặc chính sách liên quan của địa phương. Xác minh danh tính giúp bảo vệ tài khoản của bạn tốt hơn và tránh một số hình thức gian lận;',
  'identityauthentication.personal.e4025p': 'Xác minh có an toàn không?',
  'identityauthentication.personal.642u24':
    'Xác minh video chỉ thực hiện thông qua giao diện, BasicEx không lưu giữ bất kỳ thông tin nhận dạng cá nhân nào của bạn, ngoài ra, chúng tôi cũng sẽ bảo mật nghiêm ngặt thông tin của bạn.',
  'identityauthentication.personal.63dlpu':
    'Tạm thời không hỗ trợ những quốc gia và khu vực nào?',
  'identityauthentication.personal.067qd0':
    'Do yêu cầu tuân thủ và quy định, tạm thời không hỗ trợ các quốc gia và khu vực sau - Cuba, Iran, Triều Tiên, Crimea, Malaysia, Syria, Hoa Kỳ [bao gồm tất cả các lãnh thổ Hoa Kỳ như Puerto Rico, American Samoa, Guam, Northern Mariana Islands và U.S. Virgin Islands (St. John, St. Croix và St. Thomas)], Bangladesh, Bolivia, Malta, tỉnh Quebec Canada, tỉnh Ontario Canada, Singapore, v.v.',
  'identityauthentication.personal.1xnj58':
    'Vui lòng truy cập ứng dụng di động BasicEx để xác minh video',
  'identityauthentication.remake.83au88': 'Sau khi nhận dạng, ảnh của bạn:',
  'identityauthentication.remake.ky8q8l': 'Có thể đọc được, rõ ràng không mờ',
  'identityauthentication.remake.i1ev9d':
    'Ánh sáng đầy đủ, không tối không chói',
  'identityauthentication.remake.mp31d8':
    'Vui lòng không sử dụng ảnh đen trắng',
  'identityauthentication.remake.ui8830': 'Đồng thời đảm bảo:',
  'identityauthentication.remake.2v4801': 'Giấy tờ không bị chỉnh sửa',
  'identityauthentication.remake.lto47s':
    'Vui lòng đặt giấy tờ trên nền đơn sắc',
  'identityauthentication.remake.721tfj': 'Chụp lại',
  'identityauthentication.uploaded.zh4393': 'Ảnh giấy tờ đã được tải lên',
  'identityauthentication.verification.60c2cl': 'Tải lên CMND cá nhân',
  'alipay.index.pu3gld': 'Thêm phương thức thanh toán',
  'alipay.index.r7zk37': 'Alipay',
  'alipay.index.wp509f': 'Tải lên',
  'alipay.index.184371': '(Hỗ trợ định dạng JPG/JPEG/PNG/BMP, dưới 1MB)',
  'alipay.index.34512m': 'Lưu ý',
  'alipay.index.83il88':
    'Khi bạn bán tiền điện tử, phương thức thanh toán bạn chọn sẽ được hiển thị cho người mua. Vui lòng xác nhận thông tin chính xác. Vui lòng sử dụng tài khoản thanh toán trùng khớp với thông tin KYC của bạn trên BasicEx.',
  'alipay.index.k4b512': 'Xác nhận',
  'alipay.index.ar7ke7': 'Tài khoản Alipay',
  'alipay.index.5gw17n': 'Tài khoản Alipay không được để trống',
  'alipay.index.d434d5': 'Vui lòng nhập tài khoản Alipay của bạn',
  'alipay.index.i6773c': 'Mã QR thanh toán (tùy chọn)',
  'alipay.index.31jn63': 'Vui lòng điền các mục bắt buộc',
  'alipay.index.68ff1b':
    'Kích thước tệp vượt quá giới hạn, vui lòng sửa đổi và tải lên lại',
  'alipay.index.g0167s': 'Tải lên thành công',
  'alipay.index.i1b1c3': 'Tải lên thất bại',
  'bankcard.index.122668': 'Thẻ ngân hàng',
  'bankcard.index.j3lm0r': 'Lưu ý đặc biệt!',
  'bankcard.index.36w4tq':
    'Vui lòng đảm bảo thêm số thẻ ngân hàng của bạn để thanh toán/nhận tiền ngay lập tức. Không bao gồm thông tin chi tiết của ngân hàng hoặc phương thức thanh toán khác. Bạn phải thêm thông tin thanh toán/nhận tiền của ngân hàng đã chọn.',
  'bankcard.index.v3v417': 'Số tài khoản/thẻ ngân hàng',
  'bankcard.index.oh2r4b': 'Số tài khoản/thẻ ngân hàng không được để trống',
  'bankcard.index.55v5l4': 'Vui lòng nhập số tài khoản/thẻ ngân hàng',
  'bankcard.index.kjx038': 'Tên ngân hàng',
  'bankcard.index.6tj74o': 'Tên ngân hàng không được để trống',
  'bankcard.index.439w4o': 'Vui lòng nhập tên ngân hàng của bạn',
  'bankcard.index.7863u2': 'Chi nhánh ngân hàng (tùy chọn)',
  'bankcard.index.su0qyl': 'Vui lòng nhập chi nhánh ngân hàng của bạn',
  'wechat.index.4q6p3g': 'WeChat',
  'wechat.index.1jw088':
    'Khi bạn bán tiền điện tử, phương thức thanh toán bạn chọn sẽ được hiển thị cho người mua. Vui lòng xác nhận thông tin chính xác. Vui lòng sử dụng tài khoản thanh toán trùng khớp với thông tin KYC của bạn trên BasicEx.',
  'wechat.index.323592': 'Tài khoản WeChat',
  'wechat.index.l3prca': 'Vui lòng nhập ID WeChat của bạn',
  'paymentmethod.index.m2t022': 'Phương thức thanh toán',
  'paymentmethod.index.1a7s4d':
    'Phương thức thanh toán: Phương thức thanh toán C2C bạn thêm vào sẽ được hiển thị cho người mua khi bạn bán tiền điện tử trong giao dịch C2C. Vui lòng đảm bảo sử dụng tài khoản đứng tên chính bạn để người mua có thể chuyển tiền cho bạn một cách thuận lợi.',
  'paymentmethod.index.0opj97':
    'Quản lý phương thức thanh toán trên trang "thẻ tín dụng và thẻ ghi nợ của bạn" trong mục mua tiền điện tử',
  'paymentmethod.index.y9sc13': 'Chuyển khoản ngân hàng',
  'paymentmethod.index.xqm1v7': 'Tên xác thực',
  'paymentmethod.index.jdpo9n': 'Số thẻ',
  'paymentmethod.index.26qcyf': 'Ngân hàng phát hành',
  'paymentmethod.index.v1al7g': 'Chỉnh sửa',
  'paymentmethod.index.6h4u03': 'Chuyển khoản Alipay',
  'paymentmethod.index.33vdu3': 'Tài khoản',
  'paymentmethod.index.nb557l': 'Mã QR',
  'paymentmethod.index.2v2sr4': 'Không có',
  'paymentmethod.index.64j352': 'Chuyển khoản WeChat',
  'paymentmethod.index.ipl281': 'Bạn chưa có phương thức thanh toán nào',
  'paymentmethod.index.8o4t13': 'Chưa có phương thức thanh toán',
  'paymentmethod.index.4o3t7g':
    'Bạn có chắc chắn muốn xóa phương thức thanh toán này không?',
  'paymentmethod.index.4yuz54': 'Phương thức thanh toán C2C',
  'paymentmethod.index.kr8k8g': 'Mua nhanh tiền điện tử',
  'paymentmethod.index.g7423o': 'Vui lòng hoàn thành xác minh danh tính trước',
  'paymentmethod.index.8r14e6':
    'Phương thức thanh toán này đang có đơn hàng đang xử lý, không thể chỉnh sửa',
  'paymentmethod.index.r0jme1':
    'Phương thức thanh toán này đang có đơn hàng đang xử lý, không thể xóa',
  'setupp.index.eg8e11': 'Cài đặt',
  'setupp.index.452t1d': 'Hồ sơ của tôi',
  'setupp.index.qd87c0': 'Ảnh đại diện',
  'setupp.index.233vb3':
    'Vui lòng chọn ảnh đại diện để cá nhân hóa tài khoản của bạn',
  'setupp.index.vj214g': 'Thông báo hoạt động',
  'setupp.index.8p3tf4': 'Thông báo hệ thống',
  'setupp.index.51f41n': 'Tin tức BasicEx',
  'setupp.index.5cgiog': 'Thông báo giao dịch',
  'setupp.index.82461m': 'Chọn tất cả',
  'setupp.index.7xr0nm': 'Ngôn ngữ thông báo',
  'setupp.index.w81d03': 'Vui lòng chọn ngôn ngữ',
  'setupp.index.m79c1f': 'Chọn ảnh đại diện',
  'setupp.index.353554': 'Chọn một ảnh đại diện làm ảnh hồ sơ của bạn',
  'setupp.index.y74j4e': 'Chọn ngôn ngữ cho email và thông báo đẩy ứng dụng',
  'setupp.index.xdhjw5': 'Tin nhắn nội bộ',
  'setupp.index.ps0nd8':
    'Khi bật, bạn sẽ nhận được thông báo liên quan trên ứng dụng và trang web.',
  'forget.step2.2tb10c': 'Gửi mã xác minh',
  'forget.step2.t728co': 'Gửi lại sau',
  'forget.step2.xktgq7': 'Mã xác minh 6 chữ số đã được tạo',
  'forget.step2.23m35e': 'Chuyển sang phương thức xác minh khác',
  'forget.step2.127tlh': 'Chọn một phương thức xác minh',
  'forget.step3.234452':
    '*Để bảo vệ tài sản của bạn, tài khoản sẽ bị vô hiệu hóa dịch vụ thanh toán, rút tiền và bán C2C trong 24 giờ sau khi thay đổi hoặc hủy liên kết xác minh.',
  'balance.flash.avg5ow': 'BasicEx chuyển đổi nhanh',
  'balance.flash.16e5dd': 'Lịch sử quy đổi',
  'balance.flash.7yxk65': 'Số dư:',
  'balance.flash.5pdf6a': 'Giá tham khảo hiện tại:',
  'balance.flash.y91k7m': 'Giá sau',
  'balance.flash.r56244': 'Cập nhật',
  'balance.flash.979l87': 'Làm mới',
  'balance.flash.p7ss2f': 'Vui lòng nhập số tiền',
  'balance.flash.ks5utw': 'Quy đổi ngay',
  'balance.flash.vw5c2g': 'Câu hỏi thường gặp',
  'balance.flash.7hx1b2': 'Có phí giao dịch không?',
  'balance.flash.5bi77n':
    'BasicEx chuyển đổi nhanh không chỉ miễn phí giao dịch mà còn có tỷ giá quy đổi cao hơn giá thị trường, mang lại lợi ích tối đa cho người dùng.',
  'balance.flash.i5c7dy': 'Hỗ trợ những đồng tiền nào?',
  'balance.flash.dtr85o': 'Bạn đang thực hiện quy đổi nhanh, giá tham khảo',
  'balance.flash.d8616e': 'giá hiện tại còn hiệu lực trong',
  'balance.flash.2mo1kq': 'bạn có xác nhận không?',
  'balance.flash.o1osbj':
    'Giá hiện tại đã hết hạn, vui lòng bắt đầu lại giao dịch nhanh.',

  'balance.index.6ixcdv': 'Số dư',
  'balance.index.65v969':
    'USDT đại diện cho tổng số dư bao gồm tiền điện tử và số dư tiền pháp định',
  'balance.index.y78v22': 'Số dư ước tính',
  'balance.index.5xh61i': 'Quy đổi nhanh',
  'balance.index.k2bw93': 'Số dư tiền điện tử',
  'balance.index.6855lf': 'Tổng tài sản',
  'balance.index.m1300v': 'Số tiền khả dụng',
  'balance.success.pr8hn6': 'Quy đổi thành công',
  'balance.success.586403': 'Số tiền thanh toán',
  'balance.success.2x4hq2': 'Số tiền nhận được',
  'balance.success.h3u572': 'Giá tham khảo',
  'collection.index.mrieh4': 'Nhận tiền bằng ví BasicEx',
  'collection.index.4o71tn':
    'Nhận thanh toán tiền điện tử qua nhiều phương thức thanh toán',
  'collection.index.2we6y1': 'Chọn phương thức thanh toán',
  'collection.index.4yb2x1': 'Mã QR hoặc ID người dùng',
  'collection.index.n0ph26': 'Phí chuyển khoản 0',
  'collection.index.9783d8': 'Không có phí cho cả người nhận và người gửi',
  'collection.index.24ge97': 'Chia sẻ với người gửi',
  'collection.index.soy1v7': 'Chia sẻ mã QR thanh toán đã tạo',
  'collection.index.wbmgaq': 'Xem trạng thái giao dịch',
  'collection.index.e36sj0':
    'Bạn và người gửi có thể xem giao dịch này trong lịch sử giao dịch',
  'collection.index.865l53': 'Nhận ngay',
  'collection.index.b3s2h6': 'Quét và chuyển tiền',
  'collection.index.6jm0yl': 'BasicEx ID của tôi',
  'collection.index.38jmo5': 'Tên người dùng',

  'components.faq.2w458d': 'Hướng dẫn video',
  'components.faq.j4f5i0':
    'Làm thế nào để nạp tiền điện tử vào tài khoản BasicEx của tôi',
  'components.faq.vcw5n4': 'Tại sao tiền tôi nạp vẫn chưa được ghi nhận?',
  'components.faq.25etk3':
    'Làm thế nào để khôi phục tài sản của tôi khi điền sai hoặc bỏ sót Memo/tag?',
  'components.faq.356p23': 'Làm thế nào để mua tiền điện tử?',
  'components.faq.jy036g': 'Xem trạng thái nạp/rút của tất cả các đồng tiền',
  'components.figures.6q1l29': 'Sao chép địa chỉ nạp tiền',
  'components.figures.wg89uc':
    'Chọn đồng tiền và mạng blockchain bạn muốn nạp và sao chép địa chỉ nạp tiền trên trang này',
  'components.figures.3t4e1h': 'Bắt đầu rút tiền',
  'components.figures.u0vqw3': 'Bắt đầu rút tiền trên nền tảng đối tác',
  'components.figures.5o9w2x': 'Xác nhận mạng',
  'components.figures.4xw78l': 'Chờ mạng blockchain xác nhận giao dịch của bạn',
  'components.figures.0r434g': 'Nạp tiền thành công',

  'components.figures.agse9k':
    'Sau khi xác nhận khối, BasicEx sẽ ghi có vào tài khoản của bạn',

  'components.figures.6ixcdv': 'Chọn đồng tiền',
  'components.figures.65v969': 'Chọn đồng tiền bạn muốn rút',
  'components.figures.y78v22': 'Chọn mạng',
  'components.figures.5xh61i': 'Chọn mạng blockchain để rút tiền',
  'components.figures.k2bw93': 'Xác nhận rút tiền',
  'components.figures.6855lf': 'Xác nhận thông tin rút tiền của bạn',
  'components.figures.m1300v': 'Hoàn tất',
  'components.figures.pr8hn6': 'Rút tiền thành công',
  'components.selectcoin.8926wx': 'Vui lòng chọn đồng tiền rút',
  'components.selectcoin.16q188': 'Chọn đồng tiền',
  'components.selectnet.tn1k41': 'Chọn mạng chuyển khoản',
  'components.selectnet.7f375j':
    'Vui lòng đảm bảo nền tảng nhận tiền của bạn hỗ trợ đồng tiền và mạng bạn muốn rút. Nếu bạn chưa chắc chắn, nên xác nhận với nền tảng nhận tiền trước khi rút.',
  'components.selectnet.5jt6om': 'Thời gian dự kiến ≈',
  'components.selectnet.5c6ffp': 'phút',
  'components.selectnet.0r7e3h': 'Chi tiết phí',
  'recharge.edit.sbdp33': 'Đang tiến hành',
  'recharge.index.4iivy9': 'Nạp tiền điện tử',
  'recharge.index.08t186':
    'Nếu bạn muốn mua tiền điện tử bằng tiền pháp định (như CNY, EUR, v.v.), vui lòng nhấp vào',
  'recharge.index.fx7y4j': 'Địa chỉ nạp tiền',
  'recharge.index.luaa4b': 'Vui lòng nhấp vào nút để lấy địa chỉ nạp tiền.',
  'recharge.index.2h1v0v': 'Lấy địa chỉ',
  'recharge.index.43a846':
    'Quét mã bằng ứng dụng sàn giao dịch hoặc ví điện tử',
  'recharge.index.6gs53g': 'Địa chỉ này chỉ nhận',
  'recharge.index.4x3psz': 'Vui lòng xác nhận lại mạng chính bạn đã chọn là',
  'recharge.index.x58g67': 'Vui lòng không gửi NFT đến địa chỉ này',
  'recharge.index.d02594': 'Dự kiến nhận được',
  'recharge.index.26c7b1': 'xác nhận mạng',
  'recharge.index.qdj1i5': 'Dự kiến mở khóa',
  'recharge.index.jz6i9u': 'Số tiền nạp tối thiểu',
  'recharge.index.0c907p': 'Địa chỉ hợp đồng',
  'recharge.index.g77o3j': 'Địa chỉ này chỉ có thể nhận',
  'recharge.index.dfp1wo': 'Vui lòng không gửi NFT đến địa chỉ này',
  'recharge.index.608blw': 'Chưa nhận được tiền nạp?',
  'recharge.index.1q3qvs': 'Xem chi tiết',
  'recharge.index.18kyp2':
    'Nếu bạn gặp các vấn đề sau trong quá trình nạp tiền, bạn có thể sử dụng chức năng "Tra cứu tiền nạp chưa nhận được" để kiểm tra trạng thái nạp tiền hiện tại hoặc tự yêu cầu khôi phục tài sản.',
  'recharge.index.jln747': 'Đã nạp tiền nhưng chưa nhận được sau thời gian dài',
  'recharge.index.l9ps88': 'Quên điền hoặc điền sai Tag/Memo khi nạp tiền',
  'recharge.index.x2s98t': 'Đã nạp đồng tiền chưa được BasicEx niêm yết',
  'recharge.index.1s1gn0': 'Tra cứu ngay',
  'recharge.index.73fh6d': 'Lịch sử nạp tiền gần đây',
  'recharge.index.bdc7lj': 'Chưa nhận được tiền nạp? Nhấp vào đây',
  'recharge.index.18p77l': 'Tài sản',
  'recharge.index.a5711v': 'Xem tất cả',
  'recharge.index.776234': 'Đang xử lý, vui lòng đợi',
  'recharge.index.e09065': 'Vui lòng chọn mạng chuyển khoản trước',

  'transferaccounts.index.2s3533': 'Email không được để trống',
  'transferaccounts.index.lf002x': 'Số điện thoại không được để trống',
  'transferaccounts.index.6pr76n': 'Tên người dùng không được để trống',
  'transferaccounts.index.183816': 'BasicEx ID không được để trống',
  'transferaccounts.index.80v1jn': 'Số tiền chuyển khoản không được để trống',
  'transferaccounts.index.47n170':
    'Vui lòng nhập số tiền, có thể chính xác đến {0} số thập phân',
  'transferaccounts.index.99wa8r': 'Thêm ghi chú',
  'transferaccounts.index.q4dl6q': 'Ghi chú (tùy chọn)',

  'transferaccounts.index.22w46q': 'Thêm người nhận thường xuyên',
  'transferaccounts.index.72b1l1': 'Thiết lập mã PIN thanh toán',
  'transferaccounts.index.x677ux': 'Tạo mã PIN thanh toán',
  'transferaccounts.index.r3p3hi': 'Mã PIN phải có 6 chữ số',
  'transferaccounts.index.x608ud': 'Không được lặp lại một số quá 3 lần',
  'transferaccounts.index.77hv2u': 'Không được sử dụng 4 số liên tiếp',
  'transferaccounts.index.8pm8yh': 'Vui lòng nhập lại mã PIN',
  'transferaccounts.index.gvyrgf': 'Đặt lại mã PIN',
  'transferaccounts.index.r63k73': 'Mã PIN hiện tại',
  'transferaccounts.index.4vn63o': 'Mã PIN thanh toán mới',
  'transferaccounts.index.ak108t': 'Vô hiệu hóa mã PIN thanh toán',
  'transferaccounts.index.w91yi6': 'Cài đặt nhận tiền',
  'transferaccounts.index.261157':
    'Nhận tiền qua địa chỉ email hoặc số điện thoại',
  'transferaccounts.index.1akdi9':
    'Người dùng khác có thể chuyển tài sản vào ví của người nhận thông qua email hoặc số điện thoại của người nhận',
  'transferaccounts.index.w97lk9': 'Mã PIN thanh toán',
  'transferaccounts.index.96f22u': 'Quên mã PIN',
  'transferaccounts.index.358pq1': 'Lịch sử giao dịch',
  'transferaccounts.index.7918ln':
    'Chuyển khoản giữa người dùng BasicEx miễn phí, nhận tiền ngay lập tức.',
  'transferaccounts.index.34p09t': 'Người nhận',
  'transferaccounts.index.jjo13u':
    'Chuyển tiền điện tử cho người dùng BasicEx thông qua email, số điện thoại, tên người dùng, BasicEx ID.',
  'transferaccounts.index.b15688':
    'Người nhận có thể tìm thấy BasicEx ID của họ trong ID người dùng tại trung tâm người dùng',
  'transferaccounts.index.hwd69o': 'Tiếp tục',
  'transferaccounts.index.qf3w26': 'Thêm người nhận',
  'transferaccounts.index.842g41': 'Chưa có liên hệ',
  'transferaccounts.index.1486f1': 'Email',
  'transferaccounts.index.85687o': 'Số điện thoại',
  'transferaccounts.index.jb2g31': 'Ghi chú chuyển khoản (tùy chọn)',
  'transferaccounts.index.8r1kbp': 'Phương thức thanh toán',
  'transferaccounts.index.4rj478': 'Tài khoản ví',
  'transferaccounts.index.8kr6wj': 'Số dư khả dụng:',
  'transferaccounts.index.qrk32f': 'Số dư không đủ',
  'transferaccounts.index.p1czps': 'Đồng tiền thanh toán',
  'transferaccounts.index.28y74i': 'Chuyển khoản thành công',
  'transferaccounts.index.g5b418': 'Gửi lại',
  'transferaccounts.index.76452i': 'Vui lòng nhập ghi chú',
  'transferaccounts.index.u22dj7':
    'Bạn có chắc chắn muốn xóa liên hệ thường xuyên này không?',
  'transferaccounts.index.om6z9f': 'Email người nhận',
  'transferaccounts.index.8597h6': 'Số điện thoại người nhận',
  'transferaccounts.index.3j55lk': 'Tên người dùng người nhận',
  'transferaccounts.index.f23b7k': 'BasicEx ID người nhận',
  'transferaccounts.index.41p237':
    'Để đảm bảo an toàn và thuận tiện cho giao dịch của bạn, vui lòng thiết lập mã PIN thanh toán 6 chữ số',
  'transferaccounts.index.elpdch':
    'Bạn sắp rời khỏi trang này, thông tin thanh toán bạn đã nhập sẽ bị xóa, xác nhận rời khỏi?',
  'transferaccounts.index.8g712z': 'Tiếp tục vô hiệu hóa mã PIN thanh toán?',
  'transferaccounts.index.2w5z6g':
    'Sau khi vô hiệu hóa mã PIN thanh toán, bạn sẽ phải sử dụng 2FA để xác minh mọi giao dịch. Bạn có thể bật mã PIN thanh toán sau trong phần Cài đặt thanh toán.',
  'transferaccounts.index.b4f4w2': 'Người nhận thường xuyên',
  'transferaccounts.index.m8280y':
    'Nhập ghi chú chuyển khoản (người nhận có thể xem)',
  'transferaccounts.index.5v553e':
    'Chức năng chuyển khoản của bạn đã bị vô hiệu hóa',
  'withdrawal.index.71560x': 'Phí rút tiền',
  'withdrawal.index.6s0k2s': 'Phí giao dịch',
  'withdrawal.index.l86n00':
    'Số tiền rút tối thiểu {0}, có thể chính xác đến {1} số thập phân',
  'withdrawal.index.3245em': 'Rút tiền điện tử',
  'withdrawal.index.u06exc': 'Chuyển đến',
  'withdrawal.index.21sf6z': 'Người dùng BasicEx',
  'withdrawal.index.t8rcx5':
    'Danh sách trắng đã được bật, tài khoản của bạn chỉ có thể rút tiền đến các địa chỉ trong danh sách trắng.',
  'withdrawal.index.e1j15c': 'Thêm địa chỉ vào danh sách trắng',
  'withdrawal.index.45v07r': 'Đây là ID người dùng BasicEx:',
  'withdrawal.index.6i9x3o': ', chuyển khoản ngay, không mất phí.',
  'withdrawal.index.at7j1o': 'Số lượng rút tối thiểu',
  'withdrawal.index.fd2d7c': 'Phí rút tiền',
  'withdrawal.index.8r166r': 'Hạn mức rút tiền còn lại trong 24H',
  'withdrawal.index.73ox38': 'Hạn mức còn lại trong ngày',
  'withdrawal.index.88j284': 'Số dư tài khoản',
  'withdrawal.index.7wx846': 'Số tiền nhận được',
  'withdrawal.index.g5sz4l': 'Phí chuyển mạng đã bao gồm',
  'withdrawal.index.5uv3o4': 'Phí chuyển mạng đã bao gồm',
  'withdrawal.index.ic0r63': 'Chọn từ sổ địa chỉ',
  'withdrawal.index.746054':
    'Vui lòng kiểm tra kỹ ghi chú, địa chỉ, loại tiền và mạng lưới khi rút tiền, nếu không có thể dẫn đến mất tiền!',
  'withdrawal.index.q32r62': 'Thêm địa chỉ rút tiền mới',
  'withdrawal.index.sm4s27':
    'Bạn đã bật tính năng danh sách trắng, hiện chỉ có thể rút tiền đến các địa chỉ trong danh sách trắng',
  'withdrawal.index.wiqrui':
    'Rút tiền nhanh đã được bật, rút tiền đến địa chỉ trong danh sách trắng sẽ được miễn xác minh bảo mật.',
  'withdrawal.index.0eil5v': 'Nhận được',
  'withdrawal.index.mx1436':
    'Vui lòng đảm bảo bạn đã nhập đúng địa chỉ rút tiền và mạng chuyển khoản phù hợp với địa chỉ',
  'withdrawal.index.p1m1k5': 'Lệnh rút tiền sau khi tạo không thể hủy',
  'withdrawal.index.04k48l': 'Yêu cầu rút tiền đang được xem xét',
  'withdrawal.index.d3dv5h':
    'Yêu cầu rút tiền của bạn đang được xem xét, thời gian xem xét dự kiến là 12 giờ, vui lòng chờ đợi. Bạn có thể kiểm tra trạng thái rút tiền trong trang lịch sử rút tiền.',
  'withdrawal.index.4nx0jo':
    'Bật rút tiền nhanh để rút tiền nhanh chóng đến địa chỉ trong danh sách trắng',
  'withdrawal.index.jd6j7h':
    'Sau khi bật rút tiền nhanh, bạn có thể rút tiền đến địa chỉ trong danh sách trắng mà không cần xác minh bảo mật. Bạn có thể bật tính năng này trong "Tài khoản - Bảo mật tài khoản"',
  'withdrawal.index.q9pca9': 'Bật ngay',
  'withdrawal.index.6375ey': 'Vui lòng nhập địa chỉ',
  'withdrawal.index.k1ar3u':
    'Nhấp vào đây để vào giao diện rút tiền người dùng BasicEx, không mất phí',
  'withdrawal.index.t8utw1': 'Chức năng rút tiền của bạn đã bị vô hiệu hóa',
  'withdrawal.index.297v9q':
    'Không thể rút tiền trong vòng 24 giờ sau khi thay đổi mật khẩu, vui lòng thử lại sau 24 giờ',
  'withdrawal.index.203a4f': 'Vui lòng nhập số tiền rút',
  'withdrawal.index.0j7gm8': 'Số tiền rút không được lớn hơn số dư tài khoản',
  'withdrawal.index.cs673u':
    'Số tiền rút không được lớn hơn hạn mức còn lại trong ngày',
  'withdrawal.index.d8s17u': 'Vui lòng nhập địa chỉ rút tiền chính xác',
  'withdrawal.index.i8n26t': 'Vui lòng nhập địa chỉ chính xác',

  'withdrawal.user.5i08r6': 'Chuyển khoản đến địa chỉ tiền điện tử',
  'withdrawal.user.81vy10': 'Chuyển khoản cho người dùng BasicEx',
  'components.figureswith.r4794m': 'Tạo lệnh rút tiền trên nền tảng BasicEx',
  'components.figureswith.795hkl': 'Nhận địa chỉ rút tiền',
  'components.figureswith.o48601':
    'Dán địa chỉ người nhận vào ô nhập địa chỉ trên trang rút tiền',
  'components.figureswith.1v1uox':
    'Chờ mạng blockchain xác nhận thông tin rút tiền',
  'components.figureswith.q3d8b4': 'Rút tiền thành công',
  'components.figureswith.741j33':
    'Tài sản bạn rút đã được gửi thành công đến địa chỉ nhận',
  'components.footer.hkr883': 'Lịch sử rút tiền gần đây',
  'components.footer.4wp718': 'Chưa nhận được tiền điện tử?',
  'components.footer.hp7v7v': 'Ẩn thông báo lỗi',
  'components.footer.71g73b': 'Đối tác giao dịch',
  'components.footer.8kz3w7': 'Chi tiết giao dịch',
  'components.footer.4bd6qh': 'Số tiền chuyển',
  'components.footer.2p29y6': 'Tài khoản người nhận',
  'components.footer.7niq62': 'Đang xem xét',
  'components.noneaddressbook.h57py3':
    'Lưu địa chỉ thường dùng vào sổ địa chỉ để sử dụng trực tiếp khi rút tiền sau này. Thêm nhãn cho mỗi địa chỉ để dễ nhận biết.',
  'components.usetype.gap2oj': 'Vui lòng nhập {0} chính xác',
  'components.usetype.193qyk':
    'Vui lòng nhập số tiền, có thể chính xác đến {0} số thập phân',
  'components.usetype.c12082': 'Xác nhận chuyển khoản',
  'components.usetype.578640': 'Gửi đến',
  'components.usetype.781tn7': 'Số lượng rút',
  'components.usetype.83383l': 'Tài khoản rút tiền',
  'components.usetype.isnuw4':
    'Vui lòng xác nhận thông tin người nhận là chính xác',
  'components.usetype.6w5d58':
    'Tiền sẽ được chuyển ngay lập tức và không hỗ trợ hoàn tiền',
  'components.usetype.dy26cx':
    'Người nhận có thể kiểm tra số dư trong tài khoản tiền',
  'components.usetype.kyxc2u':
    'Không thể chuyển khoản đến tài khoản này, vui lòng đảm bảo đây là tài khoản hợp lệ và đã xác minh, và người nhận đã bật tính năng cho phép chuyển khoản qua email hoặc số điện thoại trong phần Cài đặt thanh toán',
  'components.usetype.41254s': 'Vui lòng nhập ghi chú (tùy chọn)',
  'components.usetype.jo4wvy':
    'Chức năng chuyển khoản giữa người dùng BasicEx của bạn đã bị vô hiệu hóa',
  'components.usetype.wyeia0': 'Vui lòng nhập số tiền chuyển khoản',
  'components.usetype.teccw9':
    'Số tiền chuyển khoản không được lớn hơn số dư tài khoản',
  'wallethistory.index.9f1e8k': 'Lịch sử ví',
  'wallethistory.index.3p8jpj': 'Hướng',
  'wallethistory.index.3i4539': 'Tùy chỉnh thời gian',
  'wallethistory.index.v1f4xc': 'Đã gửi yêu cầu',
  'wallethistory.index.kxtger': 'Tùy chỉnh',
  'wallethistory.index.8a5y51':
    'Khoảng thời gian tìm kiếm không được vượt quá 12 tháng',
  'wallethistory.index.y6y6n4': 'Vui lòng chọn',
  'wallethistory.index.7fu66b': 'Mã đơn hàng',
  'wallethistory.index.1r786y': 'ID quảng cáo',
  'wallethistory.index.8838lh': 'Mã đơn hàng C2C',
  'wallethistory.index.6xpeei': 'Nhập mã giao dịch',
  'wallethistory.index.ediegq': 'Nhập ID quảng cáo',
  'wallethistory.index.2s65sr': 'Nhập mã đơn hàng C2C',
  'wallethistory.index.b885tc': 'Vui lòng nhập TxID',
  'wallethistory.index.0k1y2f': 'Số dư đóng băng',
  'wallethistory.index.ind7f2': 'Tài khoản thương mại',
  'wallethistory.index.vadizt': 'Không tìm thấy đơn hàng này',

  'wallethistory.index.4u2nrc': '7 ngày gần đây',
  'wallethistory.index.t3mb83': '30 ngày gần đây',
  'wallethistory.index.sxz353': '90 ngày gần đây',
  'wallethistory.index.bce1l1': 'Mã đơn hàng giao dịch',
  'wallethistory.index.2idphi': 'Đơn hàng C2C',
  'wallethistory.index.ck8goy': 'Loại số tiền ghi sổ',
  'wallethistory.index.32d322':
    'Để trở thành đại lý thanh toán BasicEx, vui lòng liên hệ Telegram:',
  'navbar.index.p8cqf3': 'Mua tiền điện tử nhanh chóng',
  'navbar.index.075ejk': 'Nạp tiền',
  'navbar.index.u0jp51': 'Ví',
  'navbar.index.n1qu62': 'Mua tiền điện tử',
  'navbar.index.r0zkso': 'Chuyển khoản',
  'navbar.index.5ypwz7': 'Rút tiền',
  'navbar.index.5jxg12': 'Lịch sử ví',
  'navbar.index.6fwd19': 'Người dùng cá nhân',
  'navbar.index.xr75kp': 'Tổng quan',
  'navbar.index.fsd231': 'Giới thiệu & Hoa hồng',
  'navbar.index.n35329': 'Bảo mật tài khoản',
  'navbar.index.4sr56d': 'Xác minh danh tính',
  'navbar.index.l461w3': 'Phương thức thanh toán',
  'navbar.index.dtm61i': 'Quản lý tài chính',
  'navbar.index.dfp47y': 'Tra cứu đơn hàng',
  'navbar.index.xg8agy': 'Cài đặt',
  'navbar.index.7r8gej': 'Đăng xuất',
  'navbar.index.f6enm3': 'Tải xuống',
  'navbar.index.jft093': 'Quét để tải ứng dụng',
  'navbar.index.e4hpuj': 'Thêm lựa chọn tải xuống',
  'components.revence-data.1o1v3x': 'Chi tiết thu chi',
  'components.revence-data.xutv2h': 'Thêm',
  'components.revence-data.96f84q': 'Trạng thái',
  'components.revence-data.yy5177': 'Thời gian',
  'components.revence-data.2nd8n4': 'Loại tiền',
  'components.revence-data.wx899o': 'Số tiền nạp',
  'components.revence-data.q052s1': 'Mạng chuyển khoản',
  'components.revence-data.7c5t77': 'Địa chỉ',
  'components.revence-data.228zrf': 'Số tiền',
  'components.revence-data.e6yxx8': 'Đến',
  'components.revence-data.5e8xk1': 'Ghi chú',
  'components.revence-data.mh514x': 'Thời gian tạo',
  'components.revence-data.8j5533': 'Mã đơn hàng',
  'components.revence-data.982243': 'Đã gửi yêu cầu rút tiền',
  'components.revence-data.yn42bn': 'Hệ thống đang xử lý',
  'components.revence-data.wrk1uq': 'Đã hoàn thành',
  'components.revence-data.u5bwcq': 'Số tiền rút',
  'components.revence-data.141gb1': 'Số tiền thực nhận',
  'components.revence-data.lu095t': 'Phí giao dịch',
  'components.revence-data.q86g34': 'Đang tiến hành',
  'components.revence-data.qb2p5y': 'Thành công',
  'components.revence-data.252etx': 'Thất bại',
  'components.revence-data.vfx53c': 'Đang xem xét',

  'components.revence-data.k4c8ps': 'Không có dữ liệu',
  'components.revence-data.k715gf': 'Chi tiết nạp tiền',
  'components.revence-data.5pbm81': 'Chi tiết rút tiền',
  'components.revence-data.wjfj5b': 'Chi tiết chuyển khoản',
  'components.revence-data.5fo35v': 'Loại',
  'components.revence-data.76qs5l': 'Số lượng',
  'components.footer.v77lp7': 'Lịch sử rút tiền gần đây',
  'components.footer.7lynr7': 'Chưa nhận được tiền điện tử?',
  'components.footer.3y1w82': 'Ẩn thông báo lỗi',
  'components.footer.imrq6j': 'Tài sản',
  'components.footer.ak8p8q': 'Xem tất cả',
  'components.footer.3s16r7': 'Đối tác giao dịch',
  'components.footer.73i15s':
    'Lưu ý: Chúng tôi sẽ gửi email thông báo khi hoàn tất rút tiền. Tại sao khoản rút tiền của tôi',
  'components.footer.s1m739': 'chưa được nhận?',
  'components.footer.92i2kf': 'Không có',
  'components.footer.196oh4': 'Chi tiết giao dịch',
  'components.footer.p0bqf6': 'Số tiền chuyển khoản',
  'components.footer.58menr': 'Tài khoản người nhận',
  'components.footer.8579va': 'Thu nhập',
  'components.footer.tgfys9': 'Chi tiêu',
  'components.footer.4mnajt': 'Hoàn thành',
  'components.footer.18b1ms': 'Chuyển khoản qua địa chỉ tiền điện tử',
  'components.footer.531kp1': 'Chuyển khoản cho người dùng BasicEx',
  'wallethistory.index.5i78a8': 'Hướng',
  'wallethistory.index.8w6804': 'Tùy chỉnh thời gian',
  'wallethistory.index.47v114': 'Tất cả',
  'wallethistory.index.5ghw1q': 'Từ',
  'wallethistory.index.2sphc2': 'Tùy chỉnh',
  'wallethistory.index.529g8d':
    'Khoảng thời gian tìm kiếm không được vượt quá 12 tháng',
  'wallethistory.index.h55bzf': 'Xác nhận',
  'wallethistory.index.2ovnj2': 'Vui lòng chọn',
  'wallethistory.index.hk6yeh': 'Mã đơn hàng',
  'wallethistory.index.n3h46i': 'ID quảng cáo',
  'wallethistory.index.z1r79x': 'Mã đơn hàng C2C',
  'wallethistory.index.uq1xwt': 'Nhập mã đơn hàng giao dịch',
  'wallethistory.index.7g829o': 'Nhập ID quảng cáo',
  'wallethistory.index.qqo536': 'Nhập mã đơn hàng C2C',
  'wallethistory.index.lergm7': 'Vui lòng nhập TxID',
  'wallethistory.index.o22no8': 'Số dư đóng băng',
  'wallethistory.index.4nyb38': 'Số dư khả dụng',
  'wallethistory.index.tj74nd': 'Không tìm thấy đơn hàng này',
  'wallethistory.index.f898io': '7 ngày gần đây',
  'wallethistory.index.v4u435': '30 ngày gần đây',
  'wallethistory.index.8l028p': '90 ngày gần đây',
  'wallethistory.index.t7ec78': 'Loại số tiền ghi sổ',
  'wallethistory.index.mqe2y8': 'Mã đơn hàng giao dịch',
  'wallethistory.index.l4dp73': 'Đơn hàng C2C',
  'wallethistory.index.l4d233': 'Không có dữ liệu',

  'wallethistory.index.l423d3':
    'Để bảo vệ an toàn tài sản của bạn, sau khi thay đổi hoặc hủy liên kết xác minh, tài khoản này sẽ bị cấm dịch vụ thanh toán, rút tiền và bán C2C trong 24 giờ.',
  'safetydialog.index.178bx6': 'Xác minh mã PIN thanh toán',
  'safetydialog.index.557581': 'Vui lòng nhập mã PIN thanh toán',
  'safetydialog.index.2e2215': 'Chuyển sang phương thức xác minh khác',
  'safetydialog.index.zj74uq': 'Gửi mã xác minh',
  'safetydialog.index.193173': 'Gửi lại sau',
  'safetydialog.index.284434': 'Vui lòng nhập',
  'safetydialog.index.uo4t31': 'Mã xác minh 6 chữ số đã tạo',
  'safetydialog.index.4yhgsc': 'Mã xác minh 6 chữ số',
  'safetydialog.index.kh189w': 'Chuyển sang mã PIN',
  'safetydialog.index.pnpoko': 'Chọn một trình xác minh',
  'safetydialog.index.wks78g': 'Mã xác minh phải là 6 chữ số',
  'safetydialog.verification.j6a316': 'Xác minh bảo mật không khả dụng?',
  'login.verification.3yt211': 'Đăng nhập thành công',
  'login.verification.h937w2': 'Vui lòng thử lại sau',
  'texttool.index.8tkh16': 'Mã chống lừa đảo là gì?',
  'texttool.index.8322uj':
    'Mã chống lừa đảo là một chuỗi ký tự do bạn tự đặt, giúp bạn nhận biết các trang web hoặc email giả mạo BasicEx',
  'texttool.index.d4btk5': 'Mã chống lừa đảo xuất hiện ở đâu?',
  'texttool.index.85q6ja':
    'Sau khi thiết lập mã chống lừa đảo, mọi email BasicEx gửi cho bạn đều sẽ có chuỗi ký tự này.',
  'texttool.index.614p6n':
    'Để bảo mật tài khoản, BasicEx khuyến nghị bạn thường xuyên thay đổi mã chống lừa đảo',
  'message.index.nu55o6': 'Trung tâm thông báo',
  'message.index.040p38': 'Ẩn thông báo đã đọc',
  'message.index.45l897': 'Hủy xóa',
  'message.index.j53q1c': 'Xóa từng mục',
  'message.index.cr0g63': 'Xóa tất cả',
  'message.index.3s11dy': 'Không có thông báo',
  'message.index.5149es': 'Đánh dấu tất cả là đã đọc',
  'message.index.v8v63c': 'Bạn có chắc chắn muốn xóa tất cả tin nhắn?',
  'message.index.rs86ai': 'Bạn có chắc chắn muốn xóa tin nhắn này?',
  'message.index.813ex2':
    'Bạn có chắc chắn muốn đánh dấu tất cả tin nhắn là đã đọc?',
  'message.index.6id33f': 'Tất cả thông báo',
  'message.index.3mx6r2': 'Thông báo hệ thống',
  'withdrawal.index.sl37o9': 'Số tiền rút tối thiểu',
  'disableacc.index.4he22l': 'Xóa tài khoản',
  'disableacc.index.4600vj':
    'Xóa tài khoản của bạn sẽ dẫn đến những kết quả sau:',
  'disableacc.index.1n1xyl':
    'Thông tin tài khoản và tất cả lịch sử của bạn sẽ bị xóa, bạn có muốn tiếp tục?',
  'activity.index.3v228s': 'Mời bạn bè kiếm tiền cùng nhau',
  'activity.index.edb45q':
    'Người mời và người được mời cùng kiếm tiền thông qua giao dịch C2C, nạp tiền lần đầu và thanh toán.',
  'activity.index.yu66tq': 'Xem quy tắc giới thiệu',
  'activity.index.w3to2t': 'Mã mời',
  'activity.index.r5c8le': 'Sao chép',
  'activity.index.07k677': 'Liên kết giới thiệu',
  'activity.index.7c2lsz': 'Mời bạn bè',
  'activity.index.0f7d7m': 'Người được mời',
  'activity.index.tgo3f2': 'Hoa hồng người giới thiệu',
  'activity.index.k3pq4e': 'Hoa hồng người được mời',

  'activity.index.iq3uti':
    '*Thời gian thống kê dữ liệu dựa trên múi giờ UTC+8.',
  'activity.index.gh832j':
    'Hoa hồng giới thiệu sẽ được ghi có vào số dư ví của bạn theo giờ. Trong trường hợp chậm trễ, thời gian xử lý có thể kéo dài đến 72 giờ. Nếu sau 72 giờ bạn vẫn chưa nhận được hoa hồng, vui lòng liên hệ bộ phận hỗ trợ để được giúp đỡ thêm.',
  'activity.index.4xx2p5': 'Chỉ hiển thị',
  'activity.index.3d4v15':
    'ngày gần nhất. Để xem chi tiết lịch sử giao dịch, vui lòng nhấp vào "Xuất".',
  'activity.index.al57mt': 'Xuất',
  'activity.index.g50728': 'Mã giới thiệu',
  'activity.index.2sxu6b': 'Đăng ký ngay. Kiếm tiền cùng nhau',
  'activity.index.24767a': 'Thu nhập của bạn',
  'activity.index.41k75q': 'Bạn bè đã bắt đầu giao dịch',
  'activity.index.1srwpj': 'Số bạn bè',
  'activity.index.ghhu65': 'Không có dữ liệu',
  'activity.index.njgm7p': 'Bạn có muốn thực hiện thao tác "Xuất" không?',
  'activity.index.k3v982': 'Sao chép liên kết',
  'activity.index.yh2778': 'Sao chép thành công',
  'activity.index.t4g7z4': 'Sao chép thất bại',
  'activity.index.ye8x52': 'Tất cả thời gian',
  'activity.index.e517a8': 'Hôm qua',
  'activity.index.k1q8v4': 'Tuần này',
  'activity.index.28s1pm': 'Tháng này',
  'activity.index.2x16v8':
    'Nhấp vào liên kết, đăng ký ngay và bắt đầu giao dịch.',
  'activity.index.71420h': 'Một',
  'activity.index.66imlc': 'ID người dùng',
  'activity.index.587i85': 'Thời gian giới thiệu',
  'activity.index.15vt2o': 'Cấp hoa hồng',
  'activity.index.x27398': 'Loại hoa hồng',
  'activity.index.f84ctg': 'Số tiền hoa hồng',
  'activity.index.7ud5d7': 'Loại tiền hoa hồng',
  'tab.yet1-1': 'Nếu bạn chưa cài đặt, vui lòng tải xuống trước.',
  'tab.yet1-2': 'Mở ứng dụng',
  'resigter.index.e142w4': 'Mã giới thiệu (tùy chọn)',
  'resigter.index.8o8gw4': 'Vui lòng nhập mã giới thiệu',
  'activity.index.sdj13m': 'Cấp 1',
  'activity.index.tcy327': 'Cấp 2',
  'activity.index.eit3w1': 'Người được giới thiệu',
  'activity.index.31bq7k': 'Thanh toán',
  'activity.index.0i8xbr': 'Nạp tiền lần đầu',
  'activity.index.7t1d28':
    'Truy cập {0} bằng trình duyệt để đăng ký BasicEx và nhận nhiều ưu đãi!',
  'financial.index.a416j6': 'Tài chính',
  'financial.index.u17v8y':
    'Đơn giản, an toàn. Tìm kiếm các đồng tiền phổ biến và bắt đầu kiếm lợi nhuận ngay.',
  'financial.index.9wn75k': 'Tài sản tài chính ước tính',
  'financial.index.0aez31': 'Lợi nhuận tích lũy 30 ngày',
  'financial.index.7l6xk6': 'Lịch sử',
  'financial.index.74sqjr': 'Đăng ký',
  'financial.index.45m1g2': 'Linh hoạt',
  'financial.index.u45h04': 'ngày',
  'financial.index.1lb333': 'Thu gọn',
  'financial.index.lvk700': 'Xem thêm',
  'financial.index.4i534j': 'Tìm kiếm tiền tệ',

  'financial.index.ic2158':
    'Sản phẩm hiện tại là tài chính có kỳ hạn, rút tiền sớm sẽ mất tất cả lợi nhuận, bạn có chắc chắn muốn rút không?',
  'financial.index.h53vii': 'Hoàn trả vốn gốc',
  'financial.index.9ql3t3': 'Tiếp tục nhận lợi nhuận',
  'financial.index.7k4y73': 'Tất cả chu kỳ',
  'financial.index.9o8661': 'Có kỳ hạn',
  'financial.index.d4y434': 'Tất cả sản phẩm',
  'financial.index.7fe254': 'Vị thế hiện tại',
  'financial.index.cl022e': 'Lãi suất năm ước tính',
  'financial.index.rlc92n': 'Kỳ hạn',
  'financial.index.ry7o62': 'Số lượng nắm giữ',
  'financial.index.7x13ac': 'Lãi suất năm cố định',
  'financial.index.j4adht': 'Tổng lợi nhuận',
  'components.redemptionmodal.f6wol5': 'Rút tiền',
  'components.redemptionmodal.8tdo21': 'Số tiền rút',
  'components.redemptionmodal.1dyoq9': 'Tối đa',
  'components.redemptionmodal.i1tf10': 'Có thể rút:',
  'components.redemptionmodal.c2f84y': 'Rút nhanh',
  'components.redemptionmodal.313789': 'Số tiền rút được ghi có ngay lập tức',
  'components.redemptionmodal.k5z838': 'Số tiền rút tối đa {0}{1}',
  'components.subscriptionmodal.4z6146': 'Kỳ hạn (ngày)',
  'components.subscriptionmodal.vu0s2x': 'Số tiền đăng ký',
  'components.subscriptionmodal.2l381c': 'Số dư khả dụng',
  'components.subscriptionmodal.hs6266': 'Không đủ tài sản tiền điện tử?',
  'components.subscriptionmodal.n4iiqu': 'Giới hạn số lượng',
  'components.subscriptionmodal.k14498': 'Số tiền tối thiểu:',
  'components.subscriptionmodal.210l8f': 'Hạn mức còn lại:',
  'components.subscriptionmodal.6o1pe5': 'Tổng quan',
  'components.subscriptionmodal.69kj53': 'Ngày đăng ký',
  'components.subscriptionmodal.e2tf8d': 'Ngày bắt đầu tính lãi',
  'components.subscriptionmodal.crg825': 'Ngày nhận thưởng đầu tiên',
  'components.subscriptionmodal.d733lj':
    'Lãi suất cố định hàng năm, tính lãi hàng ngày.',
  'components.subscriptionmodal.15rpj4':
    'Lợi nhuận không được tính bằng tiền pháp định.',
  'components.subscriptionmodal.2750lb': 'Có thể rút bất cứ lúc nào.',
  'components.subscriptionmodal.782584':
    'Rút tiền sớm sẽ không phát sinh lợi nhuận.',
  'components.subscriptionmodal.cn60q7': 'Tôi đã đọc và đồng ý với',
  'components.subscriptionmodal.7njv30': 'Thỏa thuận dịch vụ tài chính BasicEx',
  'components.subscriptionmodal.vk7tu8':
    'Số dư khả dụng của đồng tiền hiện tại',
  'components.subscriptionmodal.ii6354': 'Hạn mức mua tối đa hiện tại',
  'components.subscriptionmodal.7585sb': 'Lãi suất dự kiến hàng ngày',
  'components.subscriptionmodal.94bpyq': 'Lãi suất dự kiến',
  'components.subscriptionmodal.8x2l76':
    'Số tiền đăng ký không được vượt quá số dư khả dụng',
  'components.subscriptionmodal.7pv85t': 'Số tiền tối thiểu {0} {1}',
  'components.subscriptionmodal.r8z1in': 'Hạn mức còn lại {0} {1}',
  'components.successmodal.c86btp': 'Đăng ký thành công',
  'components.successmodal.s5mxe3': 'Đã xác nhận rút tiền',
  'components.successmodal.n55j08': 'Đang chờ xử lý',
  'components.successmodal.81qw5t': 'Thời gian rút dự kiến',
  'components.successmodal.p1lcda': '*Thời gian hiệu lực thống nhất theo UTC+8',
  'financial.index.51d412': 'Lịch sử rút tiền',
  'components.redemptionmodal.6w3q97':
    'Nếu không rút toàn bộ, ưu tiên rút lợi nhuận trước.',
  'components.history.722wpc': 'Quản lý tài chính',
  'components.history.wrvmwf': 'Lịch sử lợi nhuận tích lũy 30 ngày',
  'components.history.d27w35': 'Sản phẩm',
  'components.history.b37656': 'Thời gian đăng ký',
  'components.history.821kxk': 'Lãi suất năm',
  'components.history.oq7jxy': 'Lợi nhuận phát sinh',
  'components.history.023us6': 'Thời gian phát sinh',
  'components.historyred.73r9w9': 'Đang xác nhận',
  'components.historyred.fxsgdj': 'Đã rút',
  'components.historyred.l43b8v': 'Lợi nhuận',
  'components.historyred.3lfq3n': 'Thời gian rút',
  'components.historyred.246k93': 'Rút tiền thành công',
  'financial.index.s1t66k': 'Thu nhập dự kiến hàng ngày',
  'financial.index.2g4uc2': 'Thu nhập khi đáo hạn',
  'components.editmodal.bm5h27': 'Chi tiết rút tiền',
  'components.editmodal.d6bc00': 'Thời gian đáo hạn',
  'wallethistory.index.pe3x7i': 'Đang sinh lời',
  'wallethistory.index.2343kc': 'Đã đóng',

  'login.indexnew.uc1wu1': 'Tin tưởng thiết bị hiện tại',
  'login.indexnew.e6y77j': 'Đăng nhập bằng mã QR',
  'login.indexnew.kv2529': 'Mã QR đã hết hạn',
  'login.indexnew.6dd1e5': 'Làm mới ngay',
  'login.indexnew.jk723f': 'Xác nhận trên ứng dụng',
  'login.indexnew.msql2b': 'Sử dụng',
  'login.indexnew.1ls8gl': 'Ứng dụng di động BasicEx',
  'login.indexnew.4p37cx': 'Quét mã QR',
  'login.index.518our': 'Nhận mã QR',
  'login.country.cpp4yr': 'Chọn mã vùng',
  'login.country.o4efb0': 'Tìm kiếm',
  'login.country.dbb7i9': 'Chọn khu vực',
  'enforcement.index.glq4x4': 'Pháp lý BasicEx',
  'enforcement.index.j6do82':
    'Tầm nhìn của chúng tôi là giải quyết các thách thức thiết kế tiền điện tử và blockchain, thiết lập tiêu chuẩn cho giai đoạn ứng dụng chính tiếp theo.',
  'enforcement.index.ns090u': 'Lệnh phong tỏa và lệnh tiết lộ',
  'components.disclosure.c31xn2': 'Điều khoản sử dụng BasicEx',
  'components.disclosure.7m8c62': 'Cổng thông tin lệnh tòa án BasicEx',
  'components.disclosure.qhl2v9': '(Áp dụng cho lệnh phong tỏa/lệnh tiết lộ)',
  'components.disclosure.7xrmo3':
    'Vui lòng chỉ sử dụng trang này khi có lệnh của tòa án',
  'components.disclosure.dcq7c7':
    'Chào mừng đến với cổng thông tin lệnh phong tỏa/lệnh tiết lộ pháp lý của BasicEx. Đại diện pháp lý có thể sử dụng cổng thông tin này để gửi lệnh phong tỏa và lệnh tiết lộ liên quan đến tài khoản người dùng trên nền tảng giao dịch tiền điện tử BasicEx. Vui lòng đảm bảo đơn đặt hàng của bạn liên quan đến người dùng nền tảng, không phải với nền tảng giao dịch riêng lẻ',

  'components.disclosure.q5b601':
    'Vui lòng điền thông tin vào biểu mẫu ở trang tiếp theo và gửi tài liệu chứng minh.',
  'components.disclosure.j6h429':
    'Do cổng thông tin này được liên kết với nhóm BasicEx cụ thể, nó chỉ có thể được sử dụng để gửi lệnh phong tỏa và lệnh tiết lộ cuối cùng. Bất kỳ lệnh tòa án hoặc thủ tục tố tụng nào khác không nên được gửi qua cổng thông tin chuyên dụng này, mà nên được gửi đến văn phòng đăng ký của tổ chức BasicEx liên quan. BasicEx sẽ không xem xét hoặc chấp nhận các tài liệu khác được gửi qua cổng thông tin này.',
  'components.disclosure.d71ku2':
    'Vui lòng đảm bảo rằng lệnh bạn gửi đã được xác nhận nhận và bạn đã nhận được thông tin tham chiếu cho lệnh đã gửi. BasicEx sẽ xem xét tất cả các trường hợp được gửi thành công, nhưng BasicEx không chịu trách nhiệm nếu bạn không kiểm tra tình trạng xem xét.',
  'components.disclosure.23iexl':
    'Tốt nhất là chỉ gửi lệnh liên quan một lần. Gửi lặp lại chỉ làm chậm quá trình xử lý trường hợp của bạn.',
  'components.disclosure.8nr8z1':
    'BasicEx mở cổng thông tin này không có nghĩa là tuân thủ từng lệnh phong tỏa và lệnh tiết lộ được gửi. Khả năng phong tỏa ID người dùng (tài khoản) của BasicEx hoặc khả năng tiết lộ thông tin liên quan đến ID người dùng (tài khoản) cho bên thứ ba phải tuân theo luật pháp và quy định hiện hành cũng như quyền của người dùng BasicEx (và trách nhiệm của BasicEx đối với người dùng). BasicEx sẽ xem xét bất kỳ lệnh phong tỏa hoặc lệnh tiết lộ nào được gửi thành công qua cổng thông tin này để xác định xem có thể tuân thủ hay không. BasicEx hoàn toàn bảo lưu các quyền hiện có hoặc có thể có để phản đối bất kỳ lệnh phong tỏa hoặc lệnh tiết lộ nào.',
  'components.disclosure.27z7cv':
    'Tùy thuộc vào tòa án và tổ chức liên quan, BasicEx cũng có quyền phản đối thẩm quyền của tòa án đưa ra lệnh phong tỏa hoặc lệnh tiết lộ. Việc BasicEx mở cổng thông tin này để tải lên các lệnh cụ thể không có nghĩa là BasicEx chấp nhận thẩm quyền của tòa án liên quan, và BasicEx cũng không từ bỏ bất kỳ quyền nào đã có hoặc có thể có để phản đối thẩm quyền của tòa án liên quan.',
  'components.disclosure.4gd7w7':
    'Đối với các lệnh phong tỏa và/hoặc lệnh tiết lộ mà BasicEx có thể tuân thủ và xử lý mà không cần thuê luật sư bên ngoài, BasicEx thường yêu cầu phí tuân thủ hợp lý là $500 cho mỗi ID người dùng (tài khoản) liên quan đến lệnh phong tỏa; tương tự, BasicEx yêu cầu phí tuân thủ hợp lý là $500 cho mỗi ID người dùng (tài khoản) liên quan đến lệnh tiết lộ. Nếu cùng một ID người dùng (tài khoản) liên quan đến cả hai loại lệnh hoặc lệnh kết hợp, thì phí tuân thủ hợp lý của BasicEx liên quan đến ID người dùng (tài khoản) đó là $1,000. Việc sử dụng cổng thông tin này để gửi lệnh phong tỏa và/hoặc lệnh tiết lộ có nghĩa là bạn đồng ý thanh toán phí tuân thủ hợp lý cho BasicEx trên cơ sở quy định trên (nhưng không ảnh hưởng đến quyền nhận lại phí pháp lý đã thanh toán của BasicEx khi BasicEx theo quyền quyết định tuyệt đối của mình thấy cần có tư vấn pháp lý bên ngoài)',
  'components.disclosure.3675e8':
    'Chúng tôi đồng ý với các điều khoản sử dụng cổng thông tin này như trên (chúng tôi có thể đăng hướng dẫn về lệnh pháp lý vào thời điểm thích hợp).',
  'components.disclosure.5nw311':
    'Vui lòng đồng ý với tuyên bố từ chối trách nhiệm',
  'components.disclosure.13563x': 'Đồng ý và gửi yêu cầu',
  'components.formbox.ibm33u': 'Gửi yêu cầu',
  'components.formbox.42c25u': 'Có',
  'components.formbox.6775e9':
    'Hiện tại, chúng tôi có nhiều yêu cầu tồn đọng. Để đẩy nhanh tốc độ giải quyết vấn đề, vui lòng không gửi lại thông tin xác thực. Đồng thời, chúng tôi đặc biệt khuyên bạn truy cập trang câu hỏi thường gặp của BasicEx để nhanh chóng tìm câu trả lời:',
  'components.formbox.wh3bb8': 'Cảm ơn sự hợp tác của bạn',
  'components.formbox.4j461y': 'Vui lòng chọn vấn đề của bạn (pháp lý)',
  'components.formbox.r716w9':
    'Email của bạn để nhận phản hồi (và bất kỳ thông tin tiết lộ nào)',
  'components.formbox.4ipunl': 'Tên của bạn',
  'components.formbox.fz9724': 'Tên công ty',
  'components.formbox.5m129h': 'Quốc gia/khu vực của tòa án',
  'components.formbox.1t32m3': 'Tòa án ban hành',
  'components.formbox.x5c5m4': 'Ngày trả lời phiên điều trần xem xét nhiều bên',
  'components.formbox.kl233z': 'Số yêu cầu/tài liệu tham khảo',
  'components.formbox.339ddn':
    'Tài khoản cần phong tỏa (tên chủ sở hữu/ID người dùng/email tài khoản)',
  'components.formbox.l9w4t3':
    'Yêu cầu bảo mật (có thể thông báo cho người dùng không)',
  'components.formbox.e8n33x': 'Vui lòng nhập email của bạn',
  'components.formbox.6fk7qq': 'Chủ đề',
  'components.formbox.h6mhj9': 'Mô tả',
  'components.formbox.3qhc3v': 'Tệp đính kèm',
  'components.waiting.to28je':
    'Tài liệu của bạn đã được gửi để xem xét, kết quả xem xét sẽ được gửi đến email bạn đã cung cấp, vui lòng theo dõi.',
  'home.index.13w8x6': 'Giới thiệu sản phẩm BasicEx',
  'home.index.sf95h6': 'Ví BasicEx',
  'home.index.916ckf':
    'Ví đa chuỗi an toàn và thuận tiện, bạn có thể thực hiện nạp tiền/rút tiền/mua bán bằng một cú nhấp chuột, và cũng có thể chuyển khoản/nhận tiền miễn phí cho người dùng ví BasicEx.',
  'home.index.yrsun1':
    'Hỗ trợ nhiều chuỗi công khai, ví dụ: TRC20 / ERC20 / BSC / POLYGON.',
  'home.index.089fo6':
    'Hỗ trợ nhiều loại tiền điện tử như USDT / ETH / BTC / TRX.',
  'home.index.r2831g': 'Mở ví',
  'home.index.i886e8': 'Thanh toán blockchain',
  'home.index.c372qd':
    'Các thương nhân muốn chấp nhận thanh toán USDT có thể sử dụng thanh toán BasicEx, thông qua hệ thống xử lý thanh toán blockchain do BasicEx cung cấp để thúc đẩy quá trình này.',
  'home.index.y3x6w2':
    'Đây là nền tảng thanh toán phi tập trung cho phép thương nhân chấp nhận giao dịch USDT. Một trong những lợi ích chính của loại thanh toán này là chi phí giao dịch thấp hơn nhiều so với phương thức thanh toán truyền thống, nhưng còn nhiều lợi ích khác.',
  'home.index.yvfa61':
    'Mua bán tiền điện tử một cách nhanh chóng và thuận tiện, hoặc có thể chọn thương nhân để giao dịch trực tuyến, đăng ký làm người dùng ví BasicEx để tận hưởng ngay.',
  'home.index.1n42v0':
    'Nền tảng C2C BasicEx cung cấp dịch vụ giao dịch đảm bảo để đảm bảo giao dịch của bạn an toàn và không lo lắng.',
  'home.index.81h875':
    'Hỗ trợ nhiều loại tiền điện tử / tiền pháp định, không có giới hạn T+1, hoàn thành xác minh danh tính để giao dịch trực tuyến.',
  'home.index.g1p541': 'Mua ngay',
  'localeAll.234asd': 'Vui lòng chọn loại tiền nạp',
  'components.header-info.j6esv2': 'Hướng dẫn cho người mới',
  'resigter.verification.w175qb':
    'Mã xác minh không hợp lệ, vui lòng nhấp để lấy lại',
  'qrrouter.wxtipcom.8axip0': 'Nhấp vào góc trên bên phải để chọn',
  'qrrouter.wxtipcom.2wm33j': 'Mở trong trình duyệt',
  'qrrouter.index.wvls92': 'Vui lòng sử dụng ứng dụng BasicEx để quét mã',
  'qrrouter.index.tt12fs': 'Đang mở ứng dụng BasicEx...',
  'qrrouter.index.id86f1': 'Mở ứng dụng BasicEx',
  'qrrouter.index.u63nz6': 'Nếu bạn chưa cài đặt, vui lòng tải xuống trước',
  'qrrouter.index.4dxm5b': 'Tải xuống ứng dụng BasicEx',
  'recharge.index.9z9w2e': 'Địa chỉ ví',
  'recharge.index.2f8wh2':
    'Vui lòng không nạp tiền vào địa chỉ hợp đồng, nếu không sẽ không thể khôi phục',
  'payment.index.0n2eod': '{0} phiếu giảm giá',
  'payment.index.6c163p': 'Phiếu giảm giá',
  'payment.index.3uy4pw': 'Chọn phiếu giảm giá',
  'payment.index.i15zz2': 'Đủ điều kiện',
  'payment.index.16l0wm': 'Có thể sử dụng',
  'payment.index.u7tek5': 'Hết hạn',
  'payment.index.64iqff': 'Không thể sử dụng',
  'payment.index.hkk670': 'Không sử dụng phiếu giảm giá',
  'payment.index.zd1b88': 'Xem lịch sử phiếu giảm giá',
  'payment.index.59376r': 'Đã sử dụng',
  'payment.index.jbd8h6': 'Không có bản ghi',
  'payment.index.17zzsj': 'Đã hết hạn',
  'payment.index.ww2c87': 'Không có sẵn',
  'navbar.index.u61ns9':
    'Mạng không khả dụng, vui lòng kiểm tra cài đặt mạng của bạn',
  'balance.coupon.1nmcyv': 'Chưa sử dụng',
  'down.index.u22o39': 'Tải xuống dự phòng:',
  'down.index.w2bq03': 'Nhấp để tải xuống',
  'down.index.Tutorial1': 'Thêm H5 vào màn hình chính,',
  'down.index.Tutorial2': 'Xem hướng dẫn',
  'login.indexnew.start': 'Thời gian bắt đầu',
  'login.indexnew.end': 'Thời gian kết thúc',
  'login.indexnew.kown': 'Tôi đã hiểu',
  'identityauthentication.idcard.2tyer4': 'Vui lòng tải lên',
  'identityauthentication.idcard.ly8zq2': 'Ảnh của tài liệu',
  'identityauthentication.idcard.4nn5ve':
    'Vui lòng quét mã QR bằng điện thoại và làm theo hướng dẫn để hoàn tất xác thực nâng cao',
  'identityauthentication.idcard.ej24b3': 'Quét để xác minh',
  'identityauthentication.idcard.8fp6ul': 'Nhận diện khuôn mặt',
  'identityauthentication.idcard.im974z':
    'Vui lòng tải thông tin tài liệu lên trước',
  'transferaccounts.index.4cy1yh': 'Chỉnh sửa ghi chú',
  'resigter.index.234dsd':
    'Số điện thoại từ khu vực này không được hỗ trợ, vui lòng sử dụng email để đăng ký.',
  'resigter.index.234dsa': 'Số điện thoại từ khu vực này không được hỗ trợ.',
  'resigter.index.234dsab': 'Khác',
  'tab.loc1': 'Ngôn ngữ và khu vực',
  'tab.loc2': 'Tỷ giá',
  'moneywallet.flashhistory.q6rvgn': 'Lịch sử đổi nhanh',
  'moneywallet.flashhistory.98n2vu': 'Loại tiền ban đầu',
  'moneywallet.flashhistory.452g9m': 'Số tiền ban đầu',
  'moneywallet.flashhistory.5fx2j6': 'Loại tiền được đổi',
  'moneywallet.flashhistory.yq7qj6': 'Số tiền được đổi',
  'moneywallet.flashhistory.kf7611': 'Tỷ giá hối đoái',
  'explain.index.9epet3': 'Reset',
  'localeAll.text499': `Đối tác kênh`,
  
};
