import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/message',
  name: 'Message',
  component: DEFAULT_LAYOUT,
  redirect: '/message/message',
  meta: {
    locale: 'message.index.nu55o6',
    requiresAuth: true,
    icon: 'icon-message',
    order: 7,
    hideInMenu: false,
    activeMenu: 'Message',
    title: 'message.index.nu55o6',
  },
  children: [
    {
      path: 'message',
      name: 'Message',
      component: () => import('@/views/message/index.vue'),
      meta: {
        locale: 'menu.overview',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
  ],
};

export default DASHBOARD;
