import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { Message } from '@arco-design/web-vue';

export default function useLocale() {
  const i18 = useI18n();
  const router = useRouter();
  const htmlElement = document.documentElement;
  const currentLocale = computed(() => {
    return i18.locale.value;
  });
  const changeLocale = async (value: string) => {
    i18.locale.value = value;
    await localStorage.setItem('arco-locale', value);
    await htmlElement.setAttribute('lang', value);
    Message.success(i18.t('navbar.action.locale'));
    // router.go(0);

    window.location.reload();
  };
  return {
    currentLocale,
    changeLocale,
  };
}
