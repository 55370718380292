export default {
  'home.coin': 'एन्क्रिप्टेड एसेट्स पूरी तरह से नियंत्रण में',
  'home.wallet':
    'एन्क्रिप्टेड मुद्रा वॉलेट/BasicEx पेमेंट/एक क्लिक में कॉइन खरीदें',
  'home.there': 'पूरी तरह से',
  'home.Basic': 'BasicEx',
  'home.userName': 'ईमेल/मोबाइल नंबर पंजीकरण',
  'home.down': 'अभी डाउनलोड करें',
  'home.free': 'मुफ्त',
  'home.collection': 'प्राप्ति/भुगतान',
  'home.frees': '0 शुल्क',
  'home.sell': 'BCNY खरीदना/बेचना, सुविधाजनक और तेज।',
  'home.pay': 'BasicEx उपयोगकर्ता ट्रांसफर/प्राप्ति, कोई शुल्क नहीं।',
  'home.register': 'वॉलेट पंजीकरण',
  'home.payment': 'BasicEx पेमेंट',
  'home.introduce': 'परिचय',
  'home.tip1':
    'BasicEx पेमेंट BasicEx द्वारा पेश की गई एक संपर्क रहित, सीमा रहित एन्क्रिप्टेड मुद्रा पेमेंट सिस्टम है, सुरक्षित और विश्वसनीय है।',
  'home.tip2':
    'अपनी कंपनी के लिए पेमेंट सेवा शुरू करें, USDT और अन्य प्रमुख मुद्राओं के साथ ऑनलाइन और ऑफलाइन सेल्स का विस्तार करें।',
  'home.see': 'अधिक देखें',
  'home.basicWallet': 'BasicEx वॉलेट',
  'home.chain': 'मल्टीचेन वॉलेट',
  'home.manage': 'मल्टीचेन मुद्रा संपत्ति का आसान प्रबंधन',
  'home.interface': 'पेमेंट इंटरफेस',
  'home.api': 'पूर्ण प्लेटफॉर्म API/SDK स्मार्ट पेमेंट इंटरफेस',
  'home.C2C': 'C2C मार्केट',
  'home.buy': 'एक क्लिक में एन्क्रिप्टेड मुद्रा संपत्ति खरीदें/बेचें',
  'home.help': 'सहायता की जरूरत है',
  'home.chat1': '24/7 चैट सपोर्ट',
  'home.chat2':
    '24/7 चैट सपोर्ट प्राप्त करें, मैत्रीपूर्ण ग्राहक सेवा प्रतिनिधि आपकी सेवा में हैं।',
  'home.chat3': 'ऑनलाइन चैट',
  'home.chat4': 'सामान्य प्रश्न',
  'home.chat5':
    'सामान्य प्रश्न देखें, विशिष्ट सुविधा के विस्तृत विवरण प्राप्त करें।',
  'home.chat6': 'अधिक जानें',
  'footer.about.text1': 'हमारे बारे में',
  'footer.about.text2': 'कैरियर अवसर',
  'footer.about.text3': 'व्यावसायिक संपर्क',
  'footer.about.text4': 'सेवा समझौता',
  'footer.about.text5': 'गोपनीयता नीति',
  'footer.about.text6': 'जोखिम चेतावनी',
  'footer.about.text7': 'नोटिस सेंटर',
  'footer.about.text8': 'कुकी प्राथमिकता सेटिंग',
  'footer.product.text1': 'C2C ट्रेडिंग',
  'footer.service.text1': 'व्यापारी बनना',
  'footer.service.text2': 'स्वीकृत व्यापारी जुड़ना',
  'footer.service.text3': 'प्रतिनिधि प्रचार',
  'footer.help.text1': 'सहायता केंद्र',
  'footer.help.text2': 'ऑनलाइन ग्राहक सेवा',
  'footer.help.text3': 'सुझाव और प्रतिक्रिया',
  'footer.help.text4': 'BasicEx कानूनी',
  'footer.self.text1': 'पासवर्ड रीसेट',
  'footer.self.text2': 'अकाउंट अनलॉक',
  'footer.self.text3': 'मोबाइल सत्यापन रीसेट',
  'footer.self.text4': 'ईमेल संशोधित',
  'footer.self.text5': 'गूगल प्रमाणीकरण रीसेट',
  'footer.self.text6': 'प्राप्त नहीं हुआ पुनःपूर्ति',
  'footer.self.text7': 'अकाउंट हटाने का अनुरोध',
  'footer.product.text': 'उत्पाद',
  'footer.service.text': 'सेवा',
  'footer.help.text': 'सहायता',
  'footer.self.text': 'स्व-सहायता सेवा',
  'down.down1': 'कहीं भी ट्रेडिंग शुरू करें',
  'down.down2': 'ट्रेडिंग',
  'down.down3': 'BasicEx क्लाइंट डाउनलोड करें, सभी ट्रेडिंग आपके हाथ में हैं।',
  'down.down4': 'iOS बीटा संस्करण',
  'down.down4-1': 'बीटा संस्करण स्थापित करने के लिए, पहले',
  'down.down5': 'Android से डाउनलोड',
  'down.down6': 'Google Play से डाउनलोड',
  'down.down7': 'गूगल',
  'down.down8': 'प्रमाणीकरण',
  'down.down9':
    'गूगल प्रमाणीकरण आपके लिए दो-चरण प्रमाणीकरण प्रदान करता है, दो-चरण प्रमाणीकरण के माध्यम से आपका BasicEx अकाउंट अधिक सुरक्षित हो जाता है।',
  'down.down10': 'App Store से डाउनलोड',
  'down.down12': 'Google Play से डाउनलोड',
  'down.down13': 'iOS&Android स्कैन डाउनलोड',
  'down.footerText1': 'अमेरिका MSB लाइसेंस नंबर',
};
