import localeLogin from '@/views/login/locale/hi';
import localeHome from '@/views/down/locale/hi';
import localeSettings from './hi/settings';
import localeAll from './hi/all';
import simple from './hi/simple';
import localePri from './hi/pri';

export default {
  'menu.required': 'सभी आवश्यक विवरण पूरे कीजिए',
  'menu.locale': 'हिंदी',
  'menu.dark': 'रात्रि मोड',

  'menu.balance': 'शेष',
  'menu.recharge': 'पुनःपूर्ति',
  'menu.buycoins': 'सिक्के खरीदें',
  'menu.financial': 'वित्तीय',
  'menu.transferaccounts': 'हस्तांतरण',
  'menu.withdrawal': 'निकासी',
  'menu.c2corder': 'सी2सी ऑर्डर',
  'menu.payhis': 'पे इतिहास',
  'menu.wallethistory': 'वॉलेट इतिहास',
  'menu.title': 'BasicEx वैश्विक प्रमुख क्रिप्टोकरेंसी भुगतान सेवा प्रदाता',

  'menu.loginOut': 'लॉगआउट',
  'navbar.action.locale': 'हिंदी में स्विच कीजिए',
  'public.next': 'अगला कदम',
  'public.submit': 'प्रस्तुत कीजिए',
  ...localeSettings,
  ...localeLogin,
  ...localeHome,
  ...localeAll,
  ...localePri,
  ...simple,
};
