import { useCookies } from 'vue3-cookies';

const TOKEN_KEY = 'token';
const { cookies } = useCookies();

const isLogin = () => {
  return cookies.get(TOKEN_KEY);
};
const isLoginDev = () => {
  return cookies.get('tokenDev');
};

const getToken = () => {
  return cookies.get(TOKEN_KEY);
};
const getTokenDev = () => {
  return cookies.get('tokenDev');
};

const setToken = (token: string, time: any) => {
  cookies.set('token', token, time, '/', '.basicex.com', false);
  cookies.set('tokenDev', token, time, '/', '', false);
};

const clearToken = () => {
  setToken('', '1s');
  cookies.remove(TOKEN_KEY);
  cookies.remove('tokenDev');
};

export { isLogin, isLoginDev, getToken, getTokenDev, setToken, clearToken };
