import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/recordquery',
  name: 'RecordQuery',
  component: DEFAULT_LAYOUT,
  redirect: '/recordquery/c2corder',
  meta: {
    locale: 'navbar.index.dfp47y',
    requiresAuth: true,
    icon: 'icon-record',
    order: 5,
    title: 'navbar.index.dfp47y',
  },
  children: [
    // {
    //   path: 'c2corder',
    //   name: 'C2cOrder',
    //   component: () => import('@/views/recordquery/c2corder/index.vue'),
    //   meta: {
    //     locale: 'menu.c2corder',
    //     requiresAuth: true,
    //     roles: ['*'],
    //   },
    // },
    {
      path: 'walletHistory',
      name: 'WalletHistory',
      component: () => import('@/views/recordquery/wallethistory/index.vue'),
      meta: {
        locale: 'menu.wallethistory',
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default DASHBOARD;
