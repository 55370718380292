import i18n from '@/locale';

export default function getPageTitle(pageTitle: any) {
  // const title = `BasicEx全球领先的加密货币支付服务商`;
  const title = i18n.global.t('menu.title');
  // menu.title
  if (pageTitle) {
    return `${i18n.global.t(pageTitle || '')}-${title}`;
  }
  return `${title}`;
}
