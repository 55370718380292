export default {
  'overview.index.y55nvv': 'You have not passed identity verification yet!',
  'overview.index.vm3ll4': 'Verify Now',
  'overview.index.7630kj':
    'Congratulations! You have passed basic identity verification!',
  'overview.index.6gv796':
    'Congratulations! You have passed advanced identity verification!',
  'overview.index.w42owo': 'Total Asset Valuation',
  'overview.index.nbd725':
    'Your account currently has no assets. Please complete identity verification and then recharge your account.',
  'overview.index.6b7683':
    'Your account currently has no assets. Please recharge your account first.',
  'overview.index.49nwm5': 'Buy Coins',
  'overview.index.d48b5i': 'One-click purchase of cryptocurrencies',
  'overview.index.1850v5': 'Quick Zone/Optional Zone for your selection',
  'overview.index.9t7e25': 'Buy Now',
  'overview.index.fd5w2s': 'Recharge',
  'overview.index.r376u3': 'Recharge already held currency to Basicex',
  'overview.index.66x2y5': 'Withdraw from an external wallet to Basicex',
  'overview.index.d0et7a': 'Recharge Now',
  'overview.index.y774k3': 'Receive Payment',
  'overview.index.2yn505': 'Receive transfers from other Basicex accounts',
  'overview.index.8ybxdp': '0 fees for receiving/paying',
  'overview.index.b46qtd': 'Receive Now',
  'components.assets-value.18yr1j': 'Frozen Amount:',
  'components.assets-value.2b3517': 'Available Balance:',
  'components.assets-value.8478m6': 'Transfer',
  'components.assets-value.687r4q': 'Withdrawal',
  'components.assets-value.w22527': 'Days',
  'components.assets-value.ge43pz':
    'We will update asset data daily before 16:00 Hong Kong time.',
  'components.assets-value.zghh33': 'Income',
  'components.assets-value.a1dr9x': 'Expenditure',
  'components.assets-value.ly1k52':
    'Frozen amount includes unsettled amount and system-frozen amount',
  'components.assets-value.j70i73':
    'Send cryptocurrency to Basicex users with 0 fees',
  'components.assets-value.jj3hg4': 'Income',
  'components.assets-value.i6j148': 'Expenditure',
  'components.header-info.12t323': 'Basicex ID',
  'components.header-info.um3476': 'User Type',
  'components.header-info.4m03sm': 'Last Login Time',
  'components.header-info.3787t3':
    'Please note: Nickname can only be changed once, please confirm carefully before changing!',
  'components.header-info.pj4n5u': 'Unverified',
  'components.header-info.zqw278': 'Basic Verification',
  'components.header-info.jd9962': 'Advanced Verification',
  'components.header-info.43h887': 'Personal User',
  'components.header-info.yqi9ko': 'Enterprise User',
  'components.header-info.e2tp8h': 'Edit Nickname',
  'components.header-info.1f7mrx': 'Set a custom nickname for your profile.',
  'components.header-info.5noe2d': 'Nickname',
  'components.header-info.p63d76': 'Enter the nickname you want to modify',
  'components.header-info.xwpc25': 'Nickname cannot be empty',
  'components.revence-data.9713y6': 'Income and Expenditure Details',
  'components.revence-data.npd4wy': 'More',
  'components.revence-data.ce4131': 'Withdrawal application submitted',
  'components.revence-data.9752t3': 'Processing by the system',
  'components.revence-data.62xf33': 'Completed',
  'components.revence-data.2iv5ux': 'Status',
  'components.revence-data.3y5g5i': 'Currency',
  'components.revence-data.4wmme1': 'Withdrawal Amount',
  'components.revence-data.tyfp3z': 'Fee',
  'components.revence-data.s8967j': 'Transfer Network',
  'components.revence-data.do87js': 'Available Balance',
  'components.revence-data.q5vlq3': 'Frozen Amount',
  'components.revence-data.46f9tr': 'Address',
  'components.revence-data.tle9fw':
    "Please note that we will send you an email notification when the withdrawal is completed. Why hasn't my withdrawal",
  'components.revence-data.zfuugj': 'arrived yet?',
  'components.revence-data.rfkndq': 'Amount',
  'components.revence-data.61xy2u': 'From',
  'components.revence-data.354789': 'To',
  'components.revence-data.2uv9jf': 'Creation Time',
  'components.revence-data.rfyt9g': 'Order Number',
  'components.revence-data.3hb0uk': 'Remarks',
  'components.revence-data.35h0kp': 'Successful',
  'components.revence-data.7h5jsb': 'Time',
  'components.revence-data.j8k864': 'Recharge Amount',
  'components.revence-data.543v48': 'Processing',
  'components.revence-data.5s4r08': 'In Review',
  'components.revence-data.dyxzu8': 'Canceled',
  'components.revence-data.868ebs': 'Failed',
  'components.revence-data.ltoesk': 'Pending Payment',
  'components.revence-data.g1qbzi': 'Paid, Pending Confirmation',
  'components.revence-data.kq9g21': 'In Dispute',
  'components.revence-data.jfjmj4': 'No Data Available',
  'components.revence-data.268t86': 'Recharge Details',
  'components.revence-data.k51q8g': 'Withdrawal Details',
  'components.revence-data.4l1jq3': 'Transfer Details',
  'components.revence-data.9sc1cn': 'Type',
  'components.revence-data.hpr4p5': 'Amount',
  'accaction.index.87m503': 'Account Security',
  'accaction.index.5565bk': 'Recent Account Activity',
  'accaction.index.p55715': 'Account Abnormalities?',
  'accaction.index.v7gjvv': 'Disable Account',
  'accaction.index.y3gm71': 'Login Activity',
  'accaction.index.ey2758': 'Security Operation Records',
  'accaction.index.qznrs3': 'Source',
  'accaction.index.75e7l9': 'IP Address',
  'address.batchaddaddress.6b7h2u': 'Batch Add Address',
  'address.batchaddaddress.lx0837': 'Add',
  'address.batchaddaddress.5g5at6': 'Cancel',
  'address.batchaddaddress.0c092d': 'Save',
  'address.batchaddaddress.7u2hjf':
    'The address you filled in has not been saved. Do you want to cancel the save?',
  'address.batchaddaddress.s47gk6': 'Continue Editing',
  'address.batchaddaddress.s319i3': 'Address Note',
  'address.batchaddaddress.kttf6p': 'Address Source',
  'address.batchaddaddress.qe79l9': 'Operation',
  'address.batchaddaddress.8313nb': 'No need to fill in',
  'address.index.6p7di6': 'Address Management',
  'address.index.1n20p9': 'Whitelist restriction is turned off',
  'address.index.59zoxy': 'Whitelist Settings',
  'address.index.af4395': 'All',
  'address.index.tf8k5s': 'Whitelist',
  'address.index.pqk179': 'Search',
  'address.index.jrqg1h': 'Batch Export',
  'address.index.xih4ay': 'Add Address',
  'address.index.t6l0wx': 'Selected',
  'address.index.o0w7e4': 'addresses. What do you want to do?',
  'address.index.39qj58': 'Add to Whitelist',
  'address.index.83dxt5': 'Remove from Whitelist',
  'address.index.s01fov': 'Delete',
  'address.index.80651m': 'Add Withdrawal Address',
  'address.index.86kz67':
    'Save as a common address, applicable to withdrawals for multiple currencies',
  'address.index.vwcg4v': 'Whitelist Address',
  'address.index.n6u1h8':
    'After enabling the whitelist, you can only withdraw to addresses in the whitelist.',
  'address.index.5tmj7t': 'Address Search',
  'address.index.u5b9jk': 'Non-whitelist Address',
  'address.index.x4cqzf': 'Cannot be empty!',
  'address.index.2k9010':
    "Please enter a note of 4-20 characters, for example: Xiao Ming's Basicex account",
  'address.index.eii4u3': 'Withdrawal Address',
  'address.index.bdqqx0': 'Please enter the withdrawal address',
  'address.index.871rhx':
    'Enabling the whitelist will limit your account to withdrawing only to addresses in the whitelist, enhancing the security of your assets.',
  'address.index.31c20i': 'Are you sure you want to delete this address?',
  'address.index.7uq2y9': 'Address Book',
  'address.index.r539eg': 'Transfer Network cannot be empty',
  'address.index.kw2845': 'Common Address',
  'address.index.fp19tw': 'Normal Address',
  'changepassword.index.u8w3oi': 'Change Password',
  'changepassword.index.8473bq': 'Submit',
  'changepassword.index.6qts82': 'Password changed successfully',
  'changepassword.index.7bh14e':
    'Your password has been changed. Please log in using the new password',
  'changepassword.index.awho4e': 'Login',
  'changepassword.index.157jed': 'Old Password',
  'changepassword.index.2rye5l': 'Please enter',
  'changepassword.index.7yf04o': 'New Password',
  'changepassword.index.51j37z': 'Confirm New Password',
  'changepassword.index.6382f8':
    'Password must be at least 8 characters long, including numbers and both uppercase and lowercase letters',
  'changepassword.index.o2u857': 'Passwords do not match',
  'changepassword.index.71mx3u': 'Incorrect Password!',
  'device.index.338736': 'Device:',
  'device.index.6f1n27': 'Device Management',
  'device.index.b30ayt':
    'These devices have been authorized to log in to your account',
  'device.index.r0pnkc': 'Device',
  'device.index.3o1ncj': 'Login Location',
  'disableacc.index.i6lc27': 'Account Activity',
  'disableacc.index.e6oy6i':
    'Disabling your account will result in the following:',
  'disableacc.index.o45g18':
    'If you decide to reactivate your account, please log in to unblock the account after a two-hour disablement',
  'disableacc.index.u61qfr': 'Delete this account',
  'disableacc.index.t3d7tt': 'Are you sure you want to delete this account?',
  'disableacc.index.sk863i': 'All trading and login functions will be disabled',
  'disableacc.index.893l4o': 'Delete all trusted devices',
  'disableacc.index.56bm0l': 'All pending withdrawals will be canceled',
  'disableacc.index.n4932t': 'All current orders will be canceled',
  'disableacc.index.803x4k':
    'Verified identity information will not be deleted',
  'email.bindemail.6oti7n': 'Bind Email Verification',
  'email.bindemail.77it3v': 'Get Verification Code',
  'email.bindemail.n0705g': 's later',
  'email.bindemail.2k8gfj': 'Bind Email Verification Code',
  'email.bindemail.6275ov': 'New Email',
  'email.bindemail.vx440f': 'Email cannot be empty!',
  'email.bindemail.4r361n': 'Please enter the new email address',
  'email.bindemail.5y253x': 'Email Verification Code',
  'email.bindemail.d7l1pb': 'Email verification code cannot be empty!',
  'email.bindemail.q0840y': 'Please enter the new email verification code',
  'email.changeemail.rm3mkt': 'Change Email Verification',
  'email.changeemail.sui27i': 'Change Email Verification Code',
  'email.changeemail.fpep15': 'New Email Verification Code',
  'email.unbindemail.igv777': 'Unbind Email Verification',
  'email.unbindemail.1467zm': 'Security Verification',
  'email.unbindemail.6un89v': 'Back',
  'fish.changefish.09en1y': 'Change Anti-Phishing Code',
  'fish.changefish.1f4oi3': 'Where does the Anti-Phishing Code appear?',
  'fish.changefish.x47u89':
    'Please enter 4-10 characters, excluding special symbols.',
  'fish.changefish.dteu66': 'Confirm',
  'fish.changefish.x30e85':
    'Do not tell anyone your password, Google, or SMS verification codes, including Basicex customer service.',
  'fish.changefish.545pu5': 'Old Anti-Phishing Code',
  'fish.changefish.lkyri4': 'New Anti-Phishing Code',
  'fish.changefish.bry0ys':
    'Cannot contain Chinese characters and special characters!',
  'fish.changefish.go8188': 'Needs to be composed of more than 4-20 characters',
  'fish.setfinsh.61569g': 'Set Anti-Phishing Code',
  'fish.setfinsh.o8230x': 'What is the Anti-Phishing Code?',
  'fish.setfinsh.6t45bq':
    'The Anti-Phishing Code is a string of characters you set yourself, which helps you identify phishing Basicex websites or emails',
  'fish.setfinsh.7cf26c': 'Where does the Anti-Phishing Code appear?',
  'fish.setfinsh.1rske9':
    'After setting the Anti-Phishing Code, every email Basicex sends to you will contain this string of characters.',
  'fish.setfinsh.29c19t': 'Create Anti-Phishing Code',
  'fish.setfinsh.bk3ekb': 'Anti-Phishing Code',
  'components.alert.172xn1':
    'To protect the security of your assets, after changing or unbinding the verification, the account will disable payment services, withdrawals, and C2C selling for 24 hours.',
  'google.bindgoogle.bj1319': 'Bind Google Authenticator',
  'google.bindgoogle.t70ckm': 'Download and Install the Authenticator App',
  'google.bindgoogle.1c6pys': 'How to Use Google Authenticator?',
  'google.bindgoogle.y8n99f': 'Next',
  'google.bindgoogle.7v5exp':
    'Scan this QR code with the Google Authenticator App',
  'google.bindgoogle.orfs9q':
    'If you cannot scan this QR code, manually enter this string in the App',
  'google.bindgoogle.cl439i': 'Previous',
  'google.bindgoogle.yvvt3q': 'Backup and save the key in a secure location',
  'google.bindgoogle.l8ru6j':
    'If you lose your phone, this key can be used to restore your Authenticator.',
  'google.bindgoogle.7vi621':
    'Otherwise, customer service will help you reset Basicex/Google Authenticator in at least 7 days.',
  'google.bindgoogle.p80e6s':
    'To bind Google Authenticator, you need to verify your account',
  'google.bindgoogle.255n21':
    'Enter the 6-digit verification code from Google Authenticator',
  'google.bindgoogle.l4i130': 'Authenticator has been changed',
  'google.bindgoogle.32j9s6':
    'You can now use the new Authenticator to log in.',
  'google.bindgoogle.k8240u': 'Basicex/Google Authenticator',
  'google.bindgoogle.5wml8v': 'Download App',
  'google.bindgoogle.35sm5m': 'Scan the new QR code',
  'google.bindgoogle.4z51qi': 'Backup the new key',
  'google.bindgoogle.55623i': 'Enable new identity verifier',
  'google.bindgoogle.5nl6v0': 'Verification code must be 6 digits',
  'google.changegoogle.bp2ib0': 'Change Google Authenticator',
  'google.changegoogle.0150qu':
    'Account verification is required before unbinding the old authenticator',
  'google.changegoogle.892net': 'Unbind old authenticator',
  'google.unbindgoogle.067sm6': 'Unbind old Google Authenticator',
  'phone.bindphone.6y56ed': 'Bind phone verification',
  'phone.bindphone.14257g': 'Please enter the SMS verification code',
  'phone.bindphone.rgfl35': 'Phone number',
  'phone.bindphone.cjdlml': 'Please enter the phone number',
  'phone.bindphone.xel50u': 'Phone verification code',
  'phone.bindphone.3c27v0': 'Phone number cannot be empty!',
  'phone.changephone.12r3e5': 'Change phone verification',
  'phone.changephone.e5x5v7':
    'For the security of your assets, after changing or unbinding the verification, the account will disable payment services, withdrawals, and C2C selling for 24 hours.',
  'phone.changephone.7wjyni': 'Please enter the 6-digit code sent to',
  'phone.changephone.780e3h': 'your phone',
  'phone.changephone.k33121': 'Change phone verification code',
  'phone.changephone.r34o47': 'New phone number',
  'phone.changephone.4end8x': 'Phone number cannot be empty!',
  'phone.unbindphone.4yg1w8': 'Unbind phone verification',
  'reset.index.6089f8': 'Reset security verification',
  'reset.index.li0671': 'Please select the unavailable security items:',
  'reset.index.qihc2i': 'Confirm reset',
  'reset.index.j3x4d5': '6-digit SMS verification code',
  'reset.index.wu2244': 'You are applying for the following reset operation:',
  'reset.index.albrnw': 'New phone number verification',
  'reset.index.315820': 'New phone verification code',
  'reset.index.vs4ka9':
    'Please enter the new email address you want to enable verification',
  'reset.index.92fyx1': 'Reset phone number',
  'reset.index.3qyt3q': 'Reset email',
  'reset.index.307sy0': 'Turn off Google Authenticator',
  'reset.index.3gs1p2':
    'Google Authenticator is not available, apply for reset.',
  'reset.index.6odl42': 'Please select at least one unavailable method!',
  'reset.index.41hjjt': 'Phone number {0} is not available, apply for reset.',
  'reset.index.jsuwbx': 'Email {0} is not available, apply for reset.',
  'accountsecurity.index.jeh767': 'Two-Factor Authentication (2FA)',
  'accountsecurity.index.6i7fi7': 'Encounter problems?',
  'accountsecurity.index.3vr5uk': 'Bind',
  'accountsecurity.index.yh5rux': 'Change',
  'accountsecurity.index.g4x63c': 'Unbind',
  'accountsecurity.index.qlf4hm': 'Advanced security',
  'accountsecurity.index.1h64q4': 'Turn on',
  'accountsecurity.index.l73sj8': 'Turn off',
  'accountsecurity.index.i33811': 'Recent logins',
  'accountsecurity.index.mc345k': 'Quick withdrawals',
  'accountsecurity.index.wi2qnw': '24-hour free verification limit:',
  'accountsecurity.index.9m6b36':
    'For the security of your funds, even if you enable quick withdrawals, we may still require you to undergo security verification based on your account security.',
  'accountsecurity.index.j7591y': 'New whitelist address withdrawal time limit',
  'accountsecurity.index.4nrynd':
    'If this feature is turned off, your withdrawal function will be disabled for 24 hours.',
  'accountsecurity.index.j7bqkc':
    'After turning off this feature, newly added whitelist addresses can immediately perform withdrawal operations.',
  'accountsecurity.index.797yxm':
    'Please consider your account security level before turning off this feature.',
  'accountsecurity.index.688s59':
    'Withdrawals and other related operations require the activation of two security verifications. Keeping only one will affect withdrawals!',
  'accountsecurity.index.98r7qd': 'Not bound',
  'accountsecurity.index.to6o94': 'Google Authenticator (recommended)',
  'accountsecurity.index.zm5d58': 'Phone verification',
  'accountsecurity.index.1fmg25': 'Email verification',
  'accountsecurity.index.2qgw1x': 'Withdrawal whitelist',
  'accountsecurity.index.r8g22r': 'Whitelist address security limit',
  'accountsecurity.index.mxdyyw': 'Login password',
  'accountsecurity.index.b47e47': 'Management',
  'accountsecurity.index.s7684g': 'Single free verification limit',
  'accountsecurity.index.2w8i2e': '24 hours',
  'accountsecurity.index.705k7v': '48 hours',
  'accountsecurity.index.4ee8so': '72 hours',
  'accountsecurity.index.528m37': 'Identity authentication',
  'accountsecurity.index.yx274i':
    'Protect your transaction and account security',
  'accountsecurity.index.21goo8': 'Enable',
  'accountsecurity.index.2t85w6': 'Used to manage your account login password',
  'accountsecurity.index.z43335': 'Withdrawal settings',
  'accountsecurity.index.7bfk11':
    'After enabling this function, you can exempt security verification when making small withdrawals to whitelist addresses.',
  'accountsecurity.index.530ut2':
    'Once this function is enabled, your account can only withdraw to your whitelist address.',
  'accountsecurity.index.i27mc3':
    'By setting up an anti-phishing code, you can identify whether the emails you receive are from Basicex.',
  'accountsecurity.index.q786rj':
    'The device has been authorized to log in to the account',
  'accountsecurity.index.6968fe': 'Quick withdrawals have been enabled!',
  'accountsecurity.index.13dw21': 'Whitelist enabled successfully!',
  'accountsecurity.index.g2c5k3': 'Whitelist has been closed!',
  'accountsecurity.index.4p2g2d': 'Are you sure you want to change',
  'accountsecurity.index.ks8y15': 'Are you sure you want to unbind',
  'accountsecurity.index.s123zs': 'Google Authenticator?',
  'accountsecurity.index.747q6p': 'Phone verification?',
  'accountsecurity.index.bu8j46': 'Email verification?',
  'accountsecurity.index.zxk4z3':
    'To protect your assets, the account will disable payment services, withdrawals, and C2C selling for 24 hours after changing or unbinding verification.',
  'accountsecurity.index.k9sv5l': 'Enable withdrawal whitelist',
  'accountsecurity.index.5v4733':
    'Once this function is enabled, your account can only withdraw to your whitelist address.',
  'accountsecurity.index.4n64be':
    "Please confirm whether you want to 'turn off whitelist address security restrictions'?",
  'accountsecurity.index.b1sj33':
    'Unbound or changed email cannot be registered within 30 days and cannot be bound or changed to another account within 30 days.',
  'accountsecurity.index.2399b8':
    'You will be able to withdraw to any withdrawal address, which may pose greater risks. Are you sure you want to disable your withdrawal whitelist?',
  'accountsecurity.index.6c8350':
    'Are you sure you want to delete this device?',
  'accountsecurity.index.44c15i':
    'After disabling, please unblock your account with confidence in account security.',
  'accountsecurity.index.x8b688': 'Turn off quick withdrawal',
  'accountsecurity.index.yoo2x8': 'Turn off withdrawal whitelist',
  'accountsecurity.index.77we05':
    "Please confirm whether you want to 'turn on whitelist address security restrictions'?",
  'accountsecurity.index.5c1bpu': 'Prohibit withdrawal time change',
  'accountsecurity.index.p95b65':
    'According to the duration you configured, new whitelist addresses will be prohibited from withdrawing for the corresponding time.',
  'accountsecurity.index.r5318m':
    'Before unbinding the phone, please bind the email first.',
  'accountsecurity.index.y27sd3':
    'Before unbinding the email, please bind the phone first.',
  'accountsecurity.index.8t87mw':
    "The 'whitelist address security restriction' has been enabled, and new whitelist addresses will be prohibited from withdrawing for {0} hours.",
  'identityauthentication.enterpriseerr.f1lrt4': 'Identity verification failed',
  'identityauthentication.enterpriseerr.z33fdl':
    'Please restart the authentication process and verify that your documents are valid, clear, and visible.',
  'identityauthentication.enterpriseerr.71f92d': 'Re-authenticate',
  'identityauthentication.enterpriseidcard.h5u4s1':
    'Enterprise identity authentication',
  'identityauthentication.enterpriseidcard.cu8i9m':
    'Basic information of the enterprise',
  'identityauthentication.enterpriseidcard.qvft88': 'Residential area',
  'identityauthentication.enterpriseidcard.ln74wk':
    'Select the country or region where you currently reside. You only need to answer questions required by local laws.',
  'identityauthentication.enterpriseidcard.0hze6m': 'Nationality',
  'identityauthentication.enterpriseidcard.816vg3': 'Name',
  'identityauthentication.enterpriseidcard.mwg4s7': 'Select the document',
  'identityauthentication.enterpriseidcard.n927hl': 'Document number',
  'identityauthentication.enterpriseidcard.6u8b6m':
    'Why do I need to verify my identity?',
  'identityauthentication.enterpriseidcard.dtocnj':
    'Verifying your identity allows you to have a higher withdrawal and transaction limit. The maximum withdrawal limit within 24 hours is 50K USDT.',
  'identityauthentication.enterpriseidcard.4kk33v': 'China',
  'identityauthentication.enterpriseidcard.d81gjt': 'Please enter your name',
  'identityauthentication.enterpriseidcard.840u6c': 'ID card',
  'identityauthentication.enterpriseidcard.9vrkn4':
    'Please enter the document number',
  'identityauthentication.enterpriseidcard.q64mg8': 'United States',
  'identityauthentication.enterprisesuccess.j1u6ev':
    'Enterprise identity verification completed',
  'identityauthentication.enterprisesuccess.8coosb':
    'You have passed the enterprise KYC verification and can enjoy an unlimited cryptocurrency trading limit',
  'identityauthentication.enterprisesuccess.8qnd58': 'Start trading',
  'identityauthentication.enterpriseverification.ns7yt4':
    'Document verification',
  'identityauthentication.enterpriseverification.88kdei':
    'Upload business license',
  'identityauthentication.enterpriseverification.ey7dp3':
    'Please upload a .jpg/.jpeg/.png file with a size less than 10MB',
  'identityauthentication.enterpriseverification.s64287': 'Use the camera',
  'identityauthentication.enterpriseverification.hpuo9s':
    'Upload legal person ID card',
  'identityauthentication.enterpriseverification.e3mkb4':
    'ID card front (portrait side)',
  'identityauthentication.enterpriseverification.cd94nk':
    'ID card back (national emblem side)',
  'identityauthentication.enterpriseverification.0hxcb7':
    'Make sure the document meets all the following standards:',
  'identityauthentication.enterpriseverification.587dk6': 'Readable and clear',
  'identityauthentication.enterpriseverification.5yiwid':
    'No reflection, no blur',
  'identityauthentication.enterpriseverification.3qir5b':
    'No black and white images, not edited',
  'identityauthentication.enterpriseverification.f5d5nf':
    'Place the document on a solid color background',
  'identityauthentication.idcard.rb3w44': 'Personal identity verification',
  'identityauthentication.idcard.0qc2n1': 'Country/Region',
  'identityauthentication.idcard.83ui8q':
    'Please go to the Basicex mobile APP for identity verification',
  'identityauthentication.idcard.7nqj15':
    'You can trade up to 50K USDT in cryptocurrency. For higher limits,Please proceed with advanced video verification.',
  'identityauthentication.idcard.7nqj14':
    'You can trade up to 50K USDT in cryptocurrency. For higher limits, please',
  'identityauthentication.idcard.51831f':
    'Go to the Basicex APP for advanced video verification',
  'identityauthentication.idcard.n8e533': 'Download Basicex APP',
  'identityauthentication.idcard.xjw8i8':
    'Please select or search for Country/Region',
  'identityauthentication.idcard.0gcncv': 'Please select document type',
  'identityauthentication.idcard.d6pvi4': 'Please fill in all required fields',
  'identityauthentication.idsuccess.5o2663':
    'Personal information verification completed',
  'identityauthentication.index.c3zv6w':
    'After identity verification, start trading immediately!',
  'identityauthentication.index.t582wo': 'Personal identity verification',
  'identityauthentication.index.1hjssv':
    'Trade with personal identity and provide personal information.',
  'identityauthentication.index.y28m4x':
    'Can I create both a personal account and a business account at the same time?',
  'identityauthentication.index.6752e4':
    'Sorry, please choose your account type carefully. Multiple account types are not supported at the moment.',
  'identityauthentication.personal.ve2gkh':
    'Upgrade to advanced certification is recommended to increase transaction limits.',
  'identityauthentication.personal.ht7x5k':
    'You have passed the advanced certification and can enjoy all services.',
  'identityauthentication.personal.b1468c':
    'Primary certification - Personal information',
  'identityauthentication.personal.c64mk9':
    'Normal withdrawal limit, 24-hour limit 50K USDT',
  'identityauthentication.personal.2d1typ': 'C2C transaction limit 50K USDT',
  'identityauthentication.personal.1b931w': 'Verify now',
  'identityauthentication.personal.877chb': 'Verified',
  'identityauthentication.personal.c3i45p':
    'Advanced certification - Video verification',
  'identityauthentication.personal.cl5cd4':
    'Increase withdrawal limit, 24-hour limit 8M USDT',
  'identityauthentication.personal.23n4n6':
    'Increase C2C transaction limit 8M USDT',
  'identityauthentication.personal.bc8i1z':
    'Why do I have to undergo identity verification?',
  'identityauthentication.personal.t13x7g':
    'Real-name authentication is to comply with local financial regulations or related policies. Real-name authentication also helps protect your account security and avoid certain fraudulent methods.',
  'identityauthentication.personal.e4025p': 'Is authentication secure?',
  'identityauthentication.personal.642u24':
    'Video verification is only done through the interface, Basicex will not retain any of your personal identity information. In addition, we will strictly keep your information confidential.',
  'identityauthentication.personal.63dlpu':
    'Which countries and regions are temporarily not supported?',
  'identityauthentication.personal.067qd0':
    "Due to compliance and regulatory requirements, the following countries and regions are temporarily not supported: Cuba, Iran, North Korea, Crimea, Malaysia, Syria, United States [including all U.S. territories like Puerto Rico, American Samoa, Guam, Northern Mariana Islands, and U.S. Virgin Islands (St. John's St. Croix Island and St. Thomas)], Bangladesh, Bolivia, Malta, Canada Quebec Province, Canada Ontario Province, Singapore...",
  'identityauthentication.personal.1xnj58':
    'Please go to the Basicex mobile APP for video verification',
  'identityauthentication.remake.83au88': 'After identification, your photo:',
  'identityauthentication.remake.ky8q8l': 'Readable, clear, and not blurry',
  'identityauthentication.remake.i1ev9d':
    'Adequate light, not dark or reflective',
  'identityauthentication.remake.mp31d8': 'Do not use black and white images',
  'identityauthentication.remake.ui8830': 'Please also ensure that:',
  'identityauthentication.remake.2v4801': 'The document is unedited',
  'identityauthentication.remake.lto47s':
    'Place the document on a solid color background',
  'identityauthentication.remake.721tfj': 'Retake',
  'identityauthentication.uploaded.zh4393': 'ID photo uploaded',
  'identityauthentication.verification.60c2cl': 'Upload personal ID card',
  'alipay.index.pu3gld': 'Add receiving method',
  'alipay.index.r7zk37': 'Alipay',
  'alipay.index.wp509f': 'Upload',
  'alipay.index.184371': '(Supports JPG/JPEG/PNG/BMP formats, less than 1MB)',
  'alipay.index.34512m': 'Reminder',
  'alipay.index.83il88':
    'When you sell cryptocurrency, the payment method you choose will be shown to the buyer. Please confirm that the information is filled in correctly. Please use the receiving account consistent with your Basicex platform KYC identity verification.',
  'alipay.index.k4b512': 'Confirm',
  'alipay.index.ar7ke7': 'Alipay account',
  'alipay.index.5gw17n': 'Alipay account cannot be empty',
  'alipay.index.d434d5': 'Please enter your Alipay account',
  'alipay.index.i6773c': 'Receiving QR Code (optional)',
  'alipay.index.31jn63': 'Please fill in the required items',
  'alipay.index.68ff1b':
    'File size exceeds the limit, please modify and re-upload',
  'alipay.index.g0167s': 'Upload successful',
  'alipay.index.i1b1c3': 'Upload failed',
  'bankcard.index.122668': 'Bank card',
  'bankcard.index.j3lm0r': 'Special reminder!',
  'bankcard.index.36w4tq':
    'Please make sure to add your bank card number for instant payment/receipt. Do not include details of other banks or payment methods. You must add payment/receipt information for the selected bank.',
  'bankcard.index.v3v417': 'Bank account/card number',
  'bankcard.index.oh2r4b': 'Bank account/card number cannot be empty',
  'bankcard.index.55v5l4': 'Please enter your bank account/card number',
  'bankcard.index.kjx038': 'Bank name',
  'bankcard.index.6tj74o': 'Bank name cannot be empty',
  'bankcard.index.439w4o': 'Please enter your bank name',
  'bankcard.index.7863u2': 'Branch (optional)',
  'bankcard.index.su0qyl': 'Please enter your branch',
  'wechat.index.4q6p3g': 'WeChat',
  'wechat.index.1jw088':
    'When you sell cryptocurrency, the payment method you choose will be shown to the buyer. Please confirm that the information is filled in correctly. Please use the receiving account consistent with your Basicex platform KYC identity verification.',
  'wechat.index.323592': 'WeChat account',
  'wechat.index.l3prca': 'Please enter your WeChat ID',
  'paymentmethod.index.m2t022': 'Receiving method',
  'paymentmethod.index.1a7s4d':
    'Receiving method: The C2C receiving method you add will be shown to the buyer as your receiving method when selling cryptocurrency in C2C transactions. Be sure to use your own real-name account to ensure that the buyer can transfer money to you smoothly.',
  'paymentmethod.index.0opj97':
    "Manage the payment methods on the 'your credit and debit card' page of the buy crypto page",
  'paymentmethod.index.y9sc13': 'Bank card transfer',
  'paymentmethod.index.xqm1v7': 'Certification name',
  'paymentmethod.index.jdpo9n': 'Card number',
  'paymentmethod.index.26qcyf': 'Bank',
  'paymentmethod.index.v1al7g': 'Edit',
  'paymentmethod.index.6h4u03': 'Alipay transfer',
  'paymentmethod.index.33vdu3': 'Account',
  'paymentmethod.index.nb557l': 'QR Code',
  'paymentmethod.index.2v2sr4': 'None',
  'paymentmethod.index.64j352': 'WeChat transfer',
  'paymentmethod.index.ipl281': "You don't have any receiving methods yet",
  'paymentmethod.index.8o4t13': 'No receiving method available',
  'paymentmethod.index.4o3t7g':
    'Are you sure you want to delete this receiving method?',
  'paymentmethod.index.4yuz54': 'C2C receiving method',
  'paymentmethod.index.kr8k8g': 'Quick Buy Crypto',
  'paymentmethod.index.g7423o': 'Please complete identity verification first',
  'paymentmethod.index.8r14e6':
    'There are ongoing orders for the current receiving method, and it cannot be edited',
  'paymentmethod.index.r0jme1':
    'There are ongoing orders for the current receiving method, and it cannot be deleted',
  'setupp.index.eg8e11': 'Settings',
  'setupp.index.452t1d': 'My Profile',
  'setupp.index.qd87c0': 'Profile Picture',
  'setupp.index.233vb3': 'Choose a profile picture to personalize your account',
  'setupp.index.vj214g': 'Activity Notifications',
  'setupp.index.8p3tf4': 'System Messages',
  'setupp.index.51f41n': 'Basicex News',
  'setupp.index.5cgiog': 'Trade Notifications',
  'setupp.index.82461m': 'Select All',
  'setupp.index.7xr0nm': 'Notification Language',
  'setupp.index.w81d03': 'Choose a language',
  'setupp.index.m79c1f': 'Choose Picture',
  'setupp.index.353554': 'Choose a profile picture for your profile photo',
  'setupp.index.y74j4e':
    'Choose the language for email and app push notifications',
  'setupp.index.xdhjw5': 'Messages',
  'setupp.index.ps0nd8':
    'Once enabled, you will receive relevant notifications in the app and on the website.',
  'forget.step2.2tb10c': 'Send Verification Code',
  'forget.step2.t728co': 'Send again',
  'forget.step2.xktgq7': 'Generated 6-digit verification code',
  'forget.step2.23m35e': 'Switch to another verification method',
  'forget.step2.127tlh': 'Select a validator',
  'forget.step3.234452':
    '*For the safety of your assets, after changing or unbinding the validator, the account will disable payment services, withdrawals, and C2C selling for 24 hours.',
  'balance.flash.avg5ow': 'Basicex Swap',
  'balance.flash.16e5dd': 'Exchange Records',
  'balance.flash.7yxk65': 'Balance:',
  'balance.flash.5pdf6a': 'Current Reference Price:',
  'balance.flash.y91k7m': 'Price in the back',
  'balance.flash.r56244': 'Update',
  'balance.flash.979l87': 'Refresh',
  'balance.flash.p7ss2f': 'Enter Amount',
  'balance.flash.ks5utw': 'Exchange Now',
  'balance.flash.vw5c2g': 'Frequently Asked Questions',
  'balance.flash.7hx1b2': 'Are there any fees?',
  'balance.flash.5bi77n':
    'Basicex Swap not only has no fees, but also offers exchange rates higher than the market price, allowing users to enjoy maximum benefits.',
  'balance.flash.i5c7dy': 'Which currencies are supported?',
  'balance.flash.dtr85o': 'You are doing a Swap with a reference price of',
  'balance.flash.d8616e': ', and there is still',
  'balance.flash.2mo1kq':
    'remaining for the current price validity period. Are you sure?',
  'balance.flash.o1osbj':
    'The current price has expired. Please initiate a new Swap.',
  'balance.index.6ixcdv': 'Balance',
  'balance.index.65v969':
    'USDT representing the total balance includes cryptocurrency and fiat balances',
  'balance.index.y78v22': 'Estimated Balance',
  'balance.index.5xh61i': 'Swap',
  'balance.index.k2bw93': 'Cryptocurrency Balance',
  'balance.index.6855lf': 'Total Asset Amount',
  'balance.index.m1300v': 'Available Amount',
  'balance.success.pr8hn6': 'Exchange Successful',
  'balance.success.586403': 'Payment Amount',
  'balance.success.2x4hq2': 'Received Amount',
  'balance.success.h3u572': 'Reference Unit Price',
  'collection.index.mrieh4': 'Receive Payments Using Basicex Wallet',
  'collection.index.4o71tn':
    'Receive cryptocurrency payments through various collection methods',
  'collection.index.2we6y1': 'Choose a Collection Method',
  'collection.index.4yb2x1': 'QR Code or User ID',
  'collection.index.n0ph26': 'Zero transfer fee',
  'collection.index.9783d8': 'No fees for both receiving and sending payments',
  'collection.index.24ge97': 'Shared with the sender',
  'collection.index.soy1v7': 'Share the generated QR code',
  'collection.index.wbmgaq': 'View Transaction Status',
  'collection.index.e36sj0':
    'Both you and the sender can view this transaction in the transaction history',
  'collection.index.865l53': 'Receive Now',
  'collection.index.b3s2h6': 'Scan and Transfer',
  'collection.index.6jm0yl': 'My Basicex ID',
  'collection.index.38jmo5': 'Username',
  'components.faq.2w458d': 'Video Tutorials',
  'components.faq.j4f5i0':
    'How to deposit cryptocurrency into my Basicex account',
  'components.faq.vcw5n4':
    'Why is the cryptocurrency I deposited not credited to my account?',
  'components.faq.25etk3':
    'What should I do if I fill in or miss the Memo/Tag and want to retrieve my assets?',
  'components.faq.356p23': 'How to buy cryptocurrency?',
  'components.faq.jy036g':
    'View the status of all currency deposit and withdrawal',
  'components.figures.6q1l29': 'Copy Deposit Address',
  'components.figures.wg89uc':
    'Select the cryptocurrency and its blockchain network you want to deposit and copy the deposit address on this page',
  'components.figures.3t4e1h': 'Initiate Withdrawal',
  'components.figures.u0vqw3': "Initiate withdrawal on the sender's platform",
  'components.figures.5o9w2x': 'Network Confirmation',
  'components.figures.4xw78l':
    'Wait for the blockchain network to confirm your transfer',
  'components.figures.0r434g': 'Successful Deposit',
  'components.figures.agse9k':
    'After confirmation by the blockchain, Basicex will credit your account',
  'components.selectcoin.8926wx': 'Select Withdrawal Currency',
  'components.selectcoin.16q188': 'Select Currency',
  'components.selectnet.tn1k41': 'Select Transfer Network',
  'components.selectnet.7f375j':
    'Please ensure that the receiving platform for this withdrawal supports the currency you want to withdraw and the network you are using for withdrawal. If you are not sure, we recommend confirming with the receiving platform before withdrawing.',
  'components.selectnet.5jt6om': 'Estimated Arrival Time ≈',
  'components.selectnet.5c6ffp': 'Minutes',
  'components.selectnet.0r7e3h': 'Fee Explanation',
  'recharge.edit.sbdp33': 'In Progress',
  'recharge.index.4iivy9': 'Cryptocurrency Deposit',
  'recharge.index.08t186':
    'If you want to purchase cryptocurrency using fiat currency (e.g., RMB, Euro, etc.), you can click',
  'recharge.index.fx7y4j': 'Deposit Address',
  'recharge.index.luaa4b':
    'Please click the button to get the deposit address.',
  'recharge.index.2h1v0v': 'Get Address',
  'recharge.index.43a846': 'Use the trading platform app or wallet app to scan',
  'recharge.index.6gs53g': 'This address only accepts',
  'recharge.index.4x3psz':
    'Please confirm again that the main network you selected is',
  'recharge.index.x58g67': 'Do not send NFT to this address',
  'recharge.index.d02594': 'Estimated Arrival',
  'recharge.index.26c7b1': 'Network Confirmations Expected',
  'recharge.index.qdj1i5': 'Estimated Unlocking',
  'recharge.index.jz6i9u': 'Minimum Deposit Amount',
  'recharge.index.0c907p': 'Contract Address',
  'recharge.index.g77o3j': 'This address only accepts',
  'recharge.index.dfp1wo': 'Do not send NFT to this address',
  'recharge.index.608blw': 'Did not receive the deposit?',
  'recharge.index.1q3qvs': 'View Details',
  'recharge.index.18kyp2':
    "If you encounter the following issues during the deposit process, you can use the 'Deposit Not Received Query' function to check the current deposit status or apply to retrieve your assets yourself.",
  'recharge.index.jln747':
    'Long delay in receiving the deposit after depositing',
  'recharge.index.l9ps88':
    'Forgot to fill in or filled in the wrong Tag/Memo during deposit',
  'recharge.index.x2s98t': 'Deposited a currency not yet supported by Basicex',
  'recharge.index.1s1gn0': 'Query Now',
  'recharge.index.73fh6d': 'Recent Deposit Records',
  'recharge.index.bdc7lj': 'Deposit not received? Click here',
  'recharge.index.18p77l': 'Assets',
  'recharge.index.a5711v': 'View All',
  'recharge.index.776234': 'Requesting, please wait',
  'recharge.index.e09065': 'Please select the transfer network first',
  'transferaccounts.index.2s3533': 'Email cannot be empty',
  'transferaccounts.index.lf002x': 'Phone number cannot be empty',
  'transferaccounts.index.6pr76n': 'Username cannot be empty',
  'transferaccounts.index.183816': 'Basicex ID cannot be empty',
  'transferaccounts.index.80v1jn': 'Transfer amount cannot be empty',
  'transferaccounts.index.47n170':
    'Please enter an amount, accurate to {0} decimal places',
  'transferaccounts.index.99wa8r': 'Add Note',
  'transferaccounts.index.q4dl6q': 'Note (Optional)',
  'transferaccounts.index.22w46q': 'Add Frequently Used Payee',
  'transferaccounts.index.72b1l1': 'Set Payment PIN',
  'transferaccounts.index.x677ux': 'Create Payment PIN',
  'transferaccounts.index.r3p3hi':
    'PIN must be a 6-digit numerical combination',
  'transferaccounts.index.x608ud':
    'A single digit cannot be repeated 3 times or more',
  'transferaccounts.index.77hv2u':
    'Do not use a combination of 4 consecutive digits',
  'transferaccounts.index.8pm8yh': 'Enter PIN again',
  'transferaccounts.index.gvyrgf': 'Reset PIN',
  'transferaccounts.index.r63k73': 'Current PIN',
  'transferaccounts.index.4vn63o': 'New Payment PIN',
  'transferaccounts.index.ak108t': 'Disable Payment PIN',
  'transferaccounts.index.w91yi6': 'Receiving Settings',
  'transferaccounts.index.261157':
    'Receive funds through email address or phone number',
  'transferaccounts.index.1akdi9':
    "Other users can transfer assets to the recipient's fund wallet using the recipient's email or phone number",
  'transferaccounts.index.w97lk9': 'Payment PIN',
  'transferaccounts.index.96f22u': 'Forgot PIN',
  'transferaccounts.index.358pq1': 'Transaction History',
  'transferaccounts.index.7918ln':
    'Transfer between Basicex users is free and instant.',
  'transferaccounts.index.34p09t': 'Payee',
  'transferaccounts.index.jjo13u':
    'Transfer cryptocurrency to Basicex users using email, phone number, username, or Basicex ID.',
  'transferaccounts.index.b15688':
    "The payee's Basicex ID can be found in the User ID section of the User Center",
  'transferaccounts.index.hwd69o': 'Continue',
  'transferaccounts.index.qf3w26': 'Add Payee',
  'transferaccounts.index.842g41': 'No Contacts Yet',
  'transferaccounts.index.1486f1': 'Email',
  'transferaccounts.index.85687o': 'Phone Number',
  'transferaccounts.index.jb2g31': 'Transfer Note (Optional)',
  'transferaccounts.index.8r1kbp': 'Payment Method',
  'transferaccounts.index.4rj478': 'Wallet Account',
  'transferaccounts.index.8kr6wj': 'Available Balance:',
  'transferaccounts.index.qrk32f': 'Insufficient Balance',
  'transferaccounts.index.p1czps': 'Payment Currency',
  'transferaccounts.index.28y74i': 'Transfer Successful',
  'transferaccounts.index.g5b418': 'Send Again',
  'transferaccounts.index.76452i': 'Please enter a note',
  'transferaccounts.index.u22dj7':
    'Are you sure you want to delete this frequent contact?',
  'transferaccounts.index.om6z9f': "Recipient's Email",
  'transferaccounts.index.8597h6': "Recipient's Phone Number",
  'transferaccounts.index.3j55lk': "Recipient's Username",
  'transferaccounts.index.f23b7k': "Recipient's Basicex ID",
  'transferaccounts.index.41p237':
    'For your transaction safety and convenience, please set a 6-digit payment PIN',
  'transferaccounts.index.elpdch':
    'You are about to leave this page, the payment information you entered will be deleted, confirm leaving?',
  'transferaccounts.index.8g712z': 'Continue to disable the payment PIN?',
  'transferaccounts.index.2w5z6g':
    'After disabling the payment PIN, you will need to use 2FA to verify each transaction. You can enable the payment PIN later in Payment-Settings.',
  'transferaccounts.index.b4f4w2': 'Frequent Payee',
  'transferaccounts.index.m8280y':
    'Enter transfer note (visible to the other party)',
  'transferaccounts.index.5v553e': 'Your transfer function has been disabled',
  'withdrawal.index.71560x': 'Withdrawal Fee',
  'withdrawal.index.6s0k2s': 'Fee',
  'withdrawal.index.l86n00':
    'Minimum withdrawal amount {0}, accurate to {1} decimal places',
  'withdrawal.index.3245em': 'Cryptocurrency Withdrawal',
  'withdrawal.index.u06exc': 'Transfer to',
  'withdrawal.index.21sf6z': 'Basicex User',
  'withdrawal.index.t8rcx5':
    'Whitelist has been enabled, your account can only withdraw to whitelist addresses.',
  'withdrawal.index.e1j15c': 'Add Whitelist Address',
  'withdrawal.index.45v07r': 'This is a Basicex User ID:',
  'withdrawal.index.6i9x3o': ', transfer now, zero handling fee.',
  'withdrawal.index.at7j1o': 'Minimum withdrawal amount',
  'withdrawal.index.fd2d7c': 'Withdrawal fee',
  'withdrawal.index.8r166r': '24H remaining withdrawal limit',
  'withdrawal.index.73ox38': 'Remaining limit for the day',
  'withdrawal.index.88j284': 'Account balance',
  'withdrawal.index.7wx846': 'Received amount',
  'withdrawal.index.g5sz4l': 'Network transfer fees included',
  'withdrawal.index.5uv3o4': 'Network transfer fees included',
  'withdrawal.index.ic0r63': 'Select from the address book',
  'withdrawal.index.746054':
    'Please carefully confirm the note, address, currency, and public chain during withdrawal, otherwise it may result in loss of funds!',
  'withdrawal.index.q32r62': 'Add new withdrawal address',
  'withdrawal.index.sm4s27':
    'You have enabled the whitelist function, and you can only withdraw to addresses marked as whitelist.',
  'withdrawal.index.wiqrui':
    'Quick withdrawal is enabled, and security verification can be waived when withdrawing to whitelist addresses.',
  'withdrawal.index.0eil5v': 'Arrival',
  'withdrawal.index.mx1436':
    'Make sure you enter the correct withdrawal address and that the selected transfer network matches the address',
  'withdrawal.index.p1m1k5':
    'Withdrawal orders cannot be canceled after creation',
  'withdrawal.index.04k48l': 'Withdrawal application under review',
  'withdrawal.index.d3dv5h':
    'Your withdrawal application is under review, the estimated review time is 12 hours, please be patient. You can check the status of this withdrawal on the withdrawal record page.',
  'withdrawal.index.4nx0jo':
    'Enabling quick withdrawal can quickly withdraw to whitelist addresses',
  'withdrawal.index.jd6j7h':
    "After enabling quick withdrawal, you can waive security verification when withdrawing to whitelist addresses. You can enable this feature on the 'Account-Security' page",
  'withdrawal.index.q9pca9': 'Enable Now',
  'withdrawal.index.6375ey': 'Please enter an address',
  'withdrawal.index.k1ar3u':
    'Click here to enter the Basicex user withdrawal page, zero handling fee',
  'withdrawal.index.t8utw1': 'Your withdrawal function has been disabled',
  'withdrawal.index.297v9q':
    'Withdrawal is not allowed within 24 hours after changing the password, please try again after 24 hours',
  'withdrawal.index.203a4f': 'Please enter the withdrawal amount',
  'withdrawal.index.0j7gm8': 'Withdrawal amount cannot exceed account balance',
  'withdrawal.index.cs673u':
    'Withdrawal amount cannot exceed the remaining daily limit',
  'withdrawal.index.d8s17u': 'Please enter the correct withdrawal address',
  'withdrawal.index.i8n26t': 'Please enter a correct address',
  'withdrawal.user.5i08r6': 'Cryptocurrency Address Transfer',
  'withdrawal.user.81vy10': 'Basicex User Transfer',
  'components.figureswith.r4794m':
    'Create withdrawal order on Basicex platform',
  'components.figureswith.795hkl': 'Get withdrawal address',
  'components.figureswith.o48601':
    "Paste the recipient's address into the address input box on the withdrawal page",
  'components.figureswith.1v1uox':
    'Wait for the block network to confirm the withdrawal information',
  'components.figureswith.q3d8b4': 'Withdrawal successful',
  'components.figureswith.741j33':
    'The assets you withdrew have been successfully sent to the receiving address',
  'components.footer.hkr883': 'Recent Withdrawal Records',
  'components.footer.4wp718': 'Cryptocurrency not received?',
  'components.footer.hp7v7v': 'Hide Error Tips',
  'components.footer.71g73b': 'Transaction Object',
  'components.footer.8kz3w7': 'Transaction Details',
  'components.footer.4bd6qh': 'Transfer Amount',
  'components.footer.2p29y6': "Recipient's Account",
  'components.footer.7niq62': 'Under Review',
  'components.noneaddressbook.h57py3':
    'Save frequently used addresses in the address book for future withdrawals. Add labels for each address for easy identification.',
  'components.usetype.gap2oj': 'Please enter the correct {0}',
  'components.usetype.193qyk':
    'Please enter the amount, accurate to {0} decimal places',
  'components.usetype.c12082': 'Confirm Transfer',
  'components.usetype.578640': 'Send to',
  'components.usetype.781tn7': 'Withdrawal Amount',
  'components.usetype.83383l': 'Withdrawal Account',
  'components.usetype.isnuw4':
    "Please confirm that the recipient's information is correct",
  'components.usetype.6w5d58':
    'Funds will be transferred immediately and are not refundable',
  'components.usetype.dy26cx':
    'The recipient can view the balance in the funds account',
  'components.usetype.kyxc2u':
    "Unable to transfer to this account, please ensure that this is a valid and verified account, and the recipient has enabled 'Allow others to transfer funds to me via email or phone number' in Payment-Settings",
  'components.usetype.41254s': 'Enter Note (Optional)',
  'components.usetype.jo4wvy':
    'Your Basicex user-to-user transfer function has been disabled',
  'components.usetype.wyeia0': 'Please enter the transfer amount',
  'components.usetype.teccw9': 'Transfer amount cannot exceed account balance',
  'wallethistory.index.9f1e8k': 'Wallet History',
  'wallethistory.index.3p8jpj': 'Direction',
  'wallethistory.index.3i4539': 'Custom Time',
  'wallethistory.index.v1f4xc': 'Application Submitted',
  'wallethistory.index.kxtger': 'Custom',
  'wallethistory.index.8a5y51':
    'The selected time range for retrieval cannot exceed 12 months',
  'wallethistory.index.y6y6n4': 'Please select',
  'wallethistory.index.7fu66b': 'Order Number',
  'wallethistory.index.1r786y': 'Ad ID',
  'wallethistory.index.8838lh': 'C2C Order Number',
  'wallethistory.index.6xpeei': 'Enter Transaction Order Number',
  'wallethistory.index.ediegq': 'Enter Ad ID',
  'wallethistory.index.2s65sr': 'Enter C2C Order Number',
  'wallethistory.index.b885tc': 'Please enter TxID',
  'wallethistory.index.0k1y2f': 'Frozen Balance',
  'wallethistory.index.ind7f2': 'Merchant Account',
  'wallethistory.index.vadizt': 'No order found',
  'wallethistory.index.4u2nrc': 'Last 7 Days',
  'wallethistory.index.t3mb83': 'Last 30 Days',
  'wallethistory.index.sxz353': 'Last 90 Days',
  'wallethistory.index.bce1l1': 'Transaction Order Number',
  'wallethistory.index.2idphi': 'C2C Order',
  'wallethistory.index.ck8goy': 'Booking Amount Type',
  'wallethistory.index.32d322':
    'Join BasicEx payment agent, please contact Telegram',
  'navbar.index.p8cqf3': 'Buy coins',
  'navbar.index.075ejk': 'Recharge',
  'navbar.index.u0jp51': 'Wallet',
  'navbar.index.n1qu62': 'Buy Coins',
  'navbar.index.r0zkso': 'Transfer',
  'navbar.index.5ypwz7': 'Withdraw',
  'navbar.index.5jxg12': 'Wallet History',
  'navbar.index.6fwd19': 'Personal User',
  'navbar.index.xr75kp': 'Overview',
  'navbar.index.fsd231': 'Referral Commission',
  'navbar.index.n35329': 'Account Security',
  'navbar.index.4sr56d': 'Identity Verification',
  'navbar.index.l461w3': 'Receipt Method',
  'navbar.index.dtm61i': 'Funds Management',
  'navbar.index.dfp47y': 'Order Query',
  'navbar.index.xg8agy': 'Settings',
  'navbar.index.7r8gej': 'Logout',
  'navbar.index.f6enm3': 'Download',
  'navbar.index.jft093': 'Scan to Download App',
  'navbar.index.e4hpuj': 'More Download Options',
  'components.revence-data.1o1v3x': 'Income and Expenditure Details',
  'components.revence-data.xutv2h': 'More',
  'components.revence-data.96f84q': 'Status',
  'components.revence-data.yy5177': 'Time',
  'components.revence-data.2nd8n4': 'Currency',
  'components.revence-data.wx899o': 'Recharge Amount',
  'components.revence-data.q052s1': 'Transfer Network',
  'components.revence-data.7c5t77': 'Address',
  'components.revence-data.228zrf': 'Amount',
  'components.revence-data.e6yxx8': 'To',
  'components.revence-data.5e8xk1': 'Remarks',
  'components.revence-data.mh514x': 'Creation Time',
  'components.revence-data.8j5533': 'Order Number',
  'components.revence-data.982243': 'Withdrawal Application Submitted',
  'components.revence-data.yn42bn': 'System Processing',
  'components.revence-data.wrk1uq': 'Completed',
  'components.revence-data.u5bwcq': 'Withdrawal Amount',
  'components.revence-data.141gb1': 'Actual Amount Received',
  'components.revence-data.lu095t': 'Transaction Fee',
  'components.revence-data.q86g34': 'In Progress',
  'components.revence-data.qb2p5y': 'Success',
  'components.revence-data.252etx': 'Failure',
  'components.revence-data.vfx53c': 'In Review',
  'components.revence-data.k4c8ps': 'No Data Available',
  'components.revence-data.k715gf': 'Recharge Details',
  'components.revence-data.5pbm81': 'Withdrawal Details',
  'components.revence-data.wjfj5b': 'Transfer Details',
  'components.revence-data.5fo35v': 'Type',
  'components.revence-data.76qs5l': 'Quantity',
  'components.footer.v77lp7': 'Recent Withdrawal Records',
  'components.footer.7lynr7': 'Cryptocurrency Not Received?',
  'components.footer.3y1w82': 'Hide Error Messages',
  'components.footer.imrq6j': 'Assets',
  'components.footer.ak8p8q': 'View All',
  'components.footer.3s16r7': 'Transaction Object',
  'components.footer.73i15s':
    "Please note that we will send you an email notification when your withdrawal is complete. Why hasn't my withdrawal",
  'components.footer.s1m739': 'arrived yet?',
  'components.footer.92i2kf': 'None',
  'components.footer.196oh4': 'Transaction Details',
  'components.footer.p0bqf6': 'Transfer Amount',
  'components.footer.58menr': "Recipient's Account",
  'components.footer.8579va': 'Income',
  'components.footer.tgfys9': 'Expenditure',
  'components.footer.4mnajt': 'Completed',
  'components.footer.18b1ms': 'Cryptocurrency Address Transfer',
  'components.footer.531kp1': 'Basicex Transfer',
  'wallethistory.index.5i78a8': 'Direction',
  'wallethistory.index.8w6804': 'Custom Time',
  'wallethistory.index.47v114': 'All',
  'wallethistory.index.5ghw1q': 'From',
  'wallethistory.index.2sphc2': 'Custom',
  'wallethistory.index.529g8d':
    'The time range for retrieval cannot exceed 12 months',
  'wallethistory.index.h55bzf': 'Confirm',
  'wallethistory.index.2ovnj2': 'Please Select',
  'wallethistory.index.hk6yeh': 'Order Number',
  'wallethistory.index.n3h46i': 'Ad ID',
  'wallethistory.index.z1r79x': 'C2C Order Number',
  'wallethistory.index.uq1xwt': 'Enter Transaction Order Number',
  'wallethistory.index.7g829o': 'Enter Ad ID',
  'wallethistory.index.qqo536': 'Enter C2C Order Number',
  'wallethistory.index.lergm7': 'Enter TxID',
  'wallethistory.index.o22no8': 'Frozen Balance',
  'wallethistory.index.4nyb38': 'Available Balance',
  'wallethistory.index.tj74nd': 'No order found for this transaction',
  'wallethistory.index.f898io': 'Last 7 Days',
  'wallethistory.index.v4u435': 'Last 30 Days',
  'wallethistory.index.8l028p': 'Last 90 Days',
  'wallethistory.index.t7ec78': 'Type of Account Entry',
  'wallethistory.index.mqe2y8': 'Transaction Order Number',
  'wallethistory.index.l4dp73': 'C2C Order',
  'wallethistory.index.l4d233': 'No Data Available',
  'wallethistory.index.l423d3':
    'To protect your asset security, this account will be disabled for payment services, withdrawal, and C2C selling for 24 hours after changing or unbinding verification.',
  'safetydialog.index.178bx6': 'Payment PIN Code Verification',
  'safetydialog.index.557581': 'Please enter the payment PIN code',
  'safetydialog.index.2e2215': 'Switch to another verification method',
  'safetydialog.index.zj74uq': 'Send verification code',
  'safetydialog.index.193173': 'Resend after',
  'safetydialog.index.284434': 'Please enter',
  'safetydialog.index.uo4t31': 'the generated 6-digit verification code',
  'safetydialog.index.4yhgsc': '6-digit verification code',
  'safetydialog.index.kh189w': 'Switch to PIN code',
  'safetydialog.index.pnpoko': 'Select a verification method',
  'safetydialog.index.wks78g': 'Verification code must be a 6-digit number',
  'safetydialog.verification.j6a316': 'Security verification not available?',
  'login.verification.3yt211': 'Login Successful',
  'login.verification.h937w2': 'Please try again later',
  'texttool.index.8tkh16': 'What is an Anti-Phishing Code?',
  'texttool.index.8322uj':
    'An Anti-Phishing Code is a series of characters you set yourself, helping you identify legitimate Basicex websites or emails and prevent phishing attempts.',
  'texttool.index.d4btk5': 'Where will the Anti-Phishing Code appear?',
  'texttool.index.85q6ja':
    "Once you've set up the Anti-Phishing Code, it will appear in every email Basicex sends to you.",
  'texttool.index.614p6n':
    'For account security, Basicex recommends regularly changing your phishing code.',
  'message.index.nu55o6': 'Message Center',
  'message.index.040p38': 'Hide Read Notifications',
  'message.index.45l897': 'Cancel Deletion',
  'message.index.j53q1c': 'Delete Individually',
  'message.index.cr0g63': 'Clear All',
  'message.index.3s11dy': 'No notifications at the moment',
  'message.index.5149es': 'All Read',
  'message.index.v8v63c': 'Are you sure you want to clear all messages?',
  'message.index.rs86ai': 'Are you sure you want to delete this message?',
  'message.index.813ex2': 'Are you sure you want to mark all messages as read?',
  'message.index.6id33f': 'All Notifications',
  'message.index.3mx6r2': 'System Notifications',
  'withdrawal.index.sl37o9': 'Minimum withdrawal amount',
  'disableacc.index.4he22l': 'Deleting account',
  'disableacc.index.4600vj':
    'Deleting your account will result in the following consequences:',
  'disableacc.index.1n1xyl':
    'Your account information and all records will be erased. Are you sure you want to proceed?',
  'activity.index.3v228s': 'Invite Friends to Earn Coins',
  'activity.index.edb45q':
    'Inviters and invitees earn coins together in C2C transactions, first deposits, and payments.',
  'activity.index.yu66tq': 'View Referral Rules',
  'activity.index.w3to2t': 'Invitation Code',
  'activity.index.r5c8le': 'Copy',
  'activity.index.07k677': 'Referral Link',
  'activity.index.7c2lsz': 'Invite Friends',
  'activity.index.0f7d7m': 'Invited Users',
  'activity.index.tgo3f2': 'Referrer Commission',
  'activity.index.k3pq4e': 'Invitee Commission',
  'activity.index.iq3uti': '*Data statistics are based on UTC+8 time.',
  'activity.index.4xx2p5': 'Show Past Only',
  'activity.index.3d4v15':
    "days of records. For detailed transaction records, please click 'Export'.",
  'activity.index.al57mt': 'Export',
  'activity.index.g50728': 'Referral Code',
  'activity.index.2sxu6b': 'Sign Up Now. Earn Coins Together',
  'activity.index.24767a': 'Your Earnings',
  'activity.index.41k75q': 'Friends who started trading',
  'activity.index.1srwpj': 'Number of Friends',
  'activity.index.ghhu65': 'No Records Yet',
  'activity.index.njgm7p':
    "Are you sure you want to perform the 'Export' operation?",
  'activity.index.k3v982': 'Copy Link',
  'activity.index.yh2778': 'Copy Successful',
  'activity.index.t4g7z4': 'Copy Failed',
  'activity.index.ye8x52': 'All Time',
  'activity.index.e517a8': 'Yesterday',
  'activity.index.k1q8v4': 'This Week',
  'activity.index.28s1pm': 'This Month',
  'activity.index.2x16v8':
    'Click the link, register now, and start trading immediately.',
  'activity.index.71420h': 'One',
  'activity.index.66imlc': 'User ID',
  'activity.index.587i85': 'Invitation Time',
  'activity.index.15vt2o': 'Commission Level',
  'activity.index.x27398': 'Commission Type',
  'activity.index.f84ctg': 'Commission Amount',
  'activity.index.7ud5d7': 'Commission Currency',
  'tab.yet1-1': "If you haven't installed it yet, please download first.",
  'tab.yet1-2': 'Open the app.',
  'resigter.index.e142w4': 'Invitation Code (Optional)',
  'resigter.index.8o8gw4': 'Enter the referral code',
  'activity.index.sdj13m': 'Level 1',
  'activity.index.tcy327': 'Level 2',
  'activity.index.eit3w1': 'Invitee',
  'activity.index.31bq7k': 'Payment',
  'activity.index.0i8xbr': 'First Recharge',
  'activity.index.7t1d28':
    'Visit {0} in your browser to register on BasicEx and enjoy multiple benefits!',
  'financial.index.a416j6': 'Financial Management',
  'financial.index.u17v8y':
    'Simple, Safe. Search for popular coins and start earning profits instantly.',
  'financial.index.9wn75k': 'Estimated Balance',
  'financial.index.0aez31': '30-Day Earnings',
  'financial.index.7l6xk6': 'History',
  'financial.index.74sqjr': 'Subscription',
  'financial.index.45m1g2': 'Current',
  'financial.index.u45h04': 'Days',
  'financial.index.1lb333': 'Collapse',
  'financial.index.lvk700': 'View More',
  'financial.index.4i534j': 'Search Coins',
  'financial.index.ic2158':
    'The current product is a fixed-term financial product. Early redemption will result in the loss of all earnings. Are you sure you want to redeem?',
  'financial.index.h53vii': 'Return Principal',
  'financial.index.9ql3t3': 'Continue Earnings',
  'financial.index.7k4y73': 'All Periods',
  'financial.index.9o8661': 'Fixed Term',
  'financial.index.d4y434': 'All Products',
  'financial.index.7fe254': 'Current Holdings',
  'financial.index.cl022e': 'Estimated Annual Interest Rate',
  'financial.index.rlc92n': 'Term',
  'financial.index.ry7o62': 'Holding Quantity',
  'financial.index.7x13ac': 'Fixed Annual Interest Rate',
  'financial.index.j4adht': 'Accumulated Earnings',
  'components.redemptionmodal.f6wol5': 'Redeem',
  'components.redemptionmodal.8tdo21': 'Redemption Amount',
  'components.redemptionmodal.1dyoq9': 'Max',
  'components.redemptionmodal.i1tf10': 'Redeemable:',
  'components.redemptionmodal.c2f84y': 'Quick Redemption',
  'components.redemptionmodal.313789':
    'Redemption amount will be credited immediately.',
  'components.redemptionmodal.k5z838': 'Max Redeemable {0}{1}',
  'components.subscriptionmodal.4z6146': 'Term (Days)',
  'components.subscriptionmodal.vu0s2x': 'Subscription Amount',
  'components.subscriptionmodal.2l381c': 'Available Margin',
  'components.subscriptionmodal.hs6266': 'Not enough crypto assets? ',
  'components.subscriptionmodal.n4iiqu': 'Quantity Limit',
  'components.subscriptionmodal.k14498': 'Min. Investment:',
  'components.subscriptionmodal.210l8f': 'Remaining Quota:',
  'components.subscriptionmodal.6o1pe5': 'Overview',
  'components.subscriptionmodal.69kj53': 'Subscription Date',
  'components.subscriptionmodal.e2tf8d': 'Interest Start Date',
  'components.subscriptionmodal.crg825': 'First Reward Distribution Date',
  'components.subscriptionmodal.d733lj':
    'Fixed annual interest rate, calculated daily.',
  'components.subscriptionmodal.15rpj4':
    'Earnings are not calculated in fiat currency.',
  'components.subscriptionmodal.2750lb': 'Withdraw anytime.',
  'components.subscriptionmodal.782584':
    'Early redemption will not generate earnings.',
  'components.subscriptionmodal.cn60q7': 'I have read and agree to the ',
  'components.subscriptionmodal.7njv30': 'Terms of Service for Basicex Finance',
  'components.subscriptionmodal.vk7tu8': 'Current Available Balance',
  'components.subscriptionmodal.ii6354': 'Max Purchase Limit',
  'components.subscriptionmodal.7585sb': 'Estimated Daily Interest',
  'components.subscriptionmodal.94bpyq': 'Estimated Interest',
  'components.subscriptionmodal.8x2l76':
    'Subscription amount must not exceed available margin',
  'components.subscriptionmodal.7pv85t': 'Min. Investment {0} {1}',
  'components.subscriptionmodal.r8z1in': 'Remaining Quota {0} {1}',
  'components.successmodal.c86btp': 'Subscription Successful',
  'components.successmodal.s5mxe3': 'Redemption Confirmed',
  'components.successmodal.n55j08': 'Awaiting Processing',
  'components.successmodal.81qw5t': 'Estimated Redemption Time',
  'components.successmodal.p1lcda': '*Effective time is subject to UTC+8',
  'financial.index.51d412': 'Redemption History',
  'components.redemptionmodal.6w3q97':
    'If not redeemed in full, priority will be given to redeeming earnings.',
  'components.history.722wpc': 'Financial Management',
  'components.history.wrvmwf': '30-Day Cumulative Earnings Record',
  'components.history.d27w35': 'Product',
  'components.history.b37656': 'Subscription Time',
  'components.history.821kxk': 'Annual Interest Rate',
  'components.history.oq7jxy': 'Issued Earnings',
  'components.history.023us6': 'Issuance Time',
  'components.historyred.73r9w9': 'Pending Confirmation',
  'components.historyred.fxsgdj': 'Redeemed',
  'components.historyred.l43b8v': 'Earnings',
  'components.historyred.3lfq3n': 'Redemption Time',
  'components.historyred.246k93': 'Redemption Successful',
  'financial.index.s1t66k': 'Estimated Daily Earnings',
  'financial.index.2g4uc2': 'Maturity Earnings',
  'components.editmodal.bm5h27': 'Redemption Details',
  'components.editmodal.d6bc00': 'Maturity Time',
  'wallethistory.index.pe3x7i': 'In Progress',
  'wallethistory.index.2343kc': 'Closed',
  'components.simple.756g85': '1. Overview',
  'components.simple.08c0iq':
    '1.1 Chain Earning Service ("the Service") is a product launched by Hola Limited and its affiliated companies (collectively referred to as "BasicEx"). It involves pledging digital currencies or other digital assets ("Digital Assets") on the blockchain to earn rewards, and redemption is allowed based on certain terms and conditions. BasicEx is responsible for the design, management, and operational services related to the product. To use the Service, you must read and comply with the Chain Earning User Agreement ("the Agreement"). Please carefully read, fully understand, and agree to the terms and conditions.',
  'components.simple.u4s2bs':
    '1.2 You have no right or should not use the Service unless you have read and accepted all the terms of the Agreement. Your acceptance of the Agreement, as well as your use of the Service, is deemed as having read, understood, and agreed to the Agreement, which is legally binding on you.',
  'components.simple.7kr04z': `1.3 This Agreement shall supplement BasicEx's service terms. In the event of any conflict or inconsistency between the terms or provisions specified in this Agreement and BasicEx's service terms, the resolution of such conflicts or inconsistencies shall give priority to this Agreement. All other provisions not modified by this Agreement in BasicEx's service terms remain fully effective.`,
  'components.simple.i612nw': '2. Definitions',
  'components.simple.pd935k': `2.1 Chain Earning refers to the practice of locking and holding digital assets in the blockchain network to participate in its consensus chain protocols and earn rewards. Chain Earning may involve various blockchain networks and technologies. Specific features and technologies involved may vary depending on the relevant blockchain network. Chain Earning may include different blockchain networks and technologies, primarily involving participation in consensus chain protocols, such as Proof of Stake ("PoS"), to secure the network and validate transactions.`,
  'components.simple.ur1w4y':
    '2.2 The Service is a solution that implements PoS chain protocols applicable to blockchain networks, involving on-chain pledging for rewards.',
  'components.simple.quu3go':
    '2.3 Other definitions related to the service include:',
  'components.simple.6o03hk': ` - Principal: A certain quantity of digital assets deposited into the user's account for using the Service.`,
  'components.simple.114i84': `- Redemption: The reasonable time/days required for the redemption of the pledged digital assets to be withdrawn to the account.`,
  'components.simple.2588q2': `- Reward Calculation Day: With the meaning in section 4.4(a).`,
  'components.simple.131i2v': `- Service Fee: Fees payable by users to BasicEx when subscribing to or participating in the Service, including but not limited to verification node commission fees, on-chain and off-chain miner fees (gas fees), etc.`,
  'components.simple.5y670y': `- Pledge: In this Service, BasicEx freezes a certain quantity of the user's digital assets and receives tokens or other digital assets as PoS. After pledging, users will not be able to redeem or withdraw assets before the redemption deadline.`,
  'components.simple.7w4en4': `- T Day: Natural days calculated in UTC+8 time zone.`,
  'components.simple.x831kf': `- Variable Pledge Rate: With the meaning in section 4.4(c) and as displayed and quoted on the BasicEx platform during the Service subscription period.`,
  'components.simple.e612g4': '3. Eligibility to Use the Service',
  'components.simple.vzb4x4': '3.1 You agree and confirm that:',
  'components.simple.v6iq7u':
    'a. BasicEx may pledge all or part of your digital assets;',
  'components.simple.53c448':
    ' b. BasicEx acts as a validator on the applicable network;',
  'components.simple.48k24p':
    'c. Any voting rights related to the digital assets you pledged are delegated to BasicEx;',
  'components.simple.08kj10':
    'd. BasicEx may specify, from time to time, the minimum amount of digital assets required for subscription to the service, any redemption period, any redemption restrictions, and/or any subscription limitations.',
  'components.simple.4zwvtu': '3.2 You also understand and confirm that:',
  'components.simple.nuu7bv': `- You have agreed to comply with BasicEx's service terms, and you are eligible and not prohibited from using the defined "Service";`,
  'components.simple.21xq44': `- If you are registering to use the Service as an individual, to validly accept this Agreement, you must be at least 18 years old, an individual with full legal capacity and legal competence, with the power and capacity to sign this Agreement, and this Agreement becomes binding on you upon signing;`,
  'components.simple.18143v': `- If you are registering to use the Service on behalf of a legal entity, to validly accept this Agreement, you declare and warrant that (i) such legal entity is lawfully established and validly existing under applicable law; (ii) you have obtained formal authorization from the legal entity and have the authority to act on its behalf.`,
  'components.simple.izihsy': `- You possess the risk identification ability and risk-bearing capacity corresponding to the use of this Service, with experience and knowledge in investing in non-guaranteed digital asset products.`,
  'components.simple.4a2i9n': `- You have the ability to perform relevant operations on the Internet to use this Service.`,
  'components.simple.dl4sk2': `- This Agreement does not conflict with the laws of your country or region, and you undertake to comply with the laws of your country or region.`,
  'components.simple.l2eqy3': `- You are the legal owner of the digital assets in your BasicEx platform account, and you guarantee the legality of the source of these digital assets.`,
  'components.simple.830685': '3.3 You also agree and undertake:',
  'components.simple.oowst3': `- You will not use the Service for any illegal purposes, engage in, initiate, or promote any form of market manipulation, price manipulation, insider trading, market distortion, any other malicious market behavior, or other forms of illegal activities, including but not limited to illegal gambling, money laundering, fraud, extortion, blackmail, data disclosure, terrorism financing, other violent activities, or any business activities prohibited by law.`,
  'components.simple.26iroe': `- You understand and accept the risks associated with using the Service and the potential losses you may incur, including but not limited to:`,
  'components.simple.gcf336': `- Economic losses and financial risks associated with the pledge of digital assets.`,
  'components.simple.tc17i7': `- The theoretical expected earnings displayed by BasicEx are not guaranteed or committed, and actual earnings may differ. BasicEx calculates user's actual earnings by subtracting the costs, fees, and expenses (including but not limited to server costs, electricity costs, etc.) incurred by BasicEx for pledge-related operations from the actual earnings generated by pledge-related operations. BasicEx does not guarantee or commit to any actual earnings you may receive.`,
  'components.simple.0ph46i': `- Significant or total losses due to high price volatility of digital assets and their derivative products.`,
  'components.simple.pj8gsy': `- Technical abnormalities that may delay or prevent your use of the Service or related transactions, leading to losses of your digital assets.`,
  'components.simple.c3wp4v': `- Market anomalies that may result in losses of your digital assets.`,
  'components.simple.4642wm': `- To maintain the overall health of the market, BasicEx may need to add, delete, or change policies, terms of this Agreement, or other relevant service agreements or terms from time to time. Such actions may benefit or harm individual customers like yourself.`,
  'components.simple.49x4f1': '4. Using the Service',
  'components.simple.19w0n6': `4.1 You can issue pledging, redemption, and other operational instructions through the BasicEx client. Once the operational instructions are completed, you will not be able to cancel, revoke, or modify the instructions.`,
  'components.simple.3cix8w': `4.2 Upon successful pledging, you will automatically delegate and authorize BasicEx to perform various actions on a certain quantity of the locked digital assets in your account, including but not limited to voting and digital asset gain behaviors.`,
  'components.simple.fkx5je': `4.3 Once the digital assets are pledged or locked, BasicEx will issue tokens or other digital assets to you as rewards. The use of these digital assets should comply with the rules and terms published by BasicEx on the BasicEx platform from time to time. BasicEx reserves the right to allow, consider, modify, or cancel any use of the digital assets on the BasicEx platform for any purpose.`,
  'components.simple.73e58y': '4.4 Pledge Rewards',
  'components.simple.1l6su3': `a. Pledge rewards will begin to accumulate based on the specific chain protocol's accumulated time, which may vary depending on the blockchain or network you pledged to. The accumulation of rewards starts from the day you subscribe to the service (referred to as "Reward Calculation Day") and follows the accumulated schedule specified by the chain protocol. Pledge rewards will be calculated based on the number of days in a year (365 days). Please note that you will not receive any pledge rewards on the first day of your subscription; your rewards will start accumulating from the second day, consistent with the daily accumulation schedule specified by the chain protocol.`,
  'components.simple.60u1s3': `b. Pledge rewards will be allocated to your fund account based on the distribution schedule specified by the specific chain protocol, which may vary depending on the blockchain or network you pledged to. These rewards will be credited to your fund account on the day following each Reward Calculation Day, provided it aligns with the distribution schedule specified by the chain protocol. Please note that the distribution time and frequency are subject to the rules and guidelines set by the chain protocol.`,
  'components.simple.kjo2z0': `c. The variable pledge rate is calculated with reference to the pledge rewards generated on the current day. To avoid confusion, this "variable pledge rate," which may change daily based on on-chain earnings, will be published on the BasicEx platform and may change from time to time.
`,
  'components.simple.trfh0l':
    'd. The calculation will be rounded to 8 decimal places.',
  'components.simple.8q8zh6': `To avoid confusion, BasicEx may retain a portion of on-chain rewards. This amount may differ from pledge rewards. BasicEx does not guarantee that you will receive pledge rewards at the variable pledge rate displayed at the time of your subscription. Any statements made at the time of your subscription (i) are indicative estimates and not guarantees, (ii) are based on the variable pledge rate, which may change, and (iii) may be higher or lower than on-chain rewards.`,
  'components.simple.3e5int': `4.5 Redemption Rules`,
  'components.simple.mp83y6': `a. The redemption of digital assets to the user's account must comply with the exchange rules specified by the chain protocol, and the specific time may vary. Please note that redemption time may vary depending on the type of digital assets. You understand and agree that, during the time from redemption initiation to the actual crediting, you will not be able to perform any feedback, transfers, or other instructions on the redeemed digital assets.
`,
  'components.simple.8q351n': `b. On the redemption day or later, BasicEx may only allow the redemption of a limited quantity of digital assets per day.`,
  'components.simple.u54482': `c. Once you submit a redemption request, you will no longer receive any pledge rewards related to the redeemed digital assets. To avoid confusion, the act of redeeming digital assets does not meet the conditions for any pledge rewards. Once a redemption order is issued and/or confirmed, the order cannot be canceled, revoked, or modified.`,
  'components.simple.30rpa8': `d. You should be able to receive digital assets within a reasonable time after submitting a redemption request.`,
  'components.simple.7x1wdy': `e. All redemption requests are processed by BasicEx on a first-come, first-served (FIFO) basis and are processed by BasicEx at the end of each day (UTC+8 market close).`,
  'components.simple.zr617j': `f. BasicEx reserves the right to charge a service fee for processing digital asset redemptions, which shall be published on the BasicEx platform and may be modified by BasicEx from time to time.`,
  'components.simple.o87n4p': `g. You understand and acknowledge that you will not receive pledge rewards during the redemption period.`,
  'components.simple.46u02b': `4.6 You will be able to view your account history on the BasicEx platform. You must carefully review your account history, and if you discover any entries or transactions that you do not recognize or believe to be incorrect, please inform BasicEx promptly. BasicEx may correct any errors in your account history at any time and reserves the right to cancel or revoke any transactions:`,
  'components.simple.7wu1il': `a. Involving or arising from obvious errors (i.e., any obvious errors, omissions, or misquotations (whether by BasicEx or any third party), including any errors in quotes made by BasicEx after considering current market and current advertising quotes, or any errors in information, sources, officials, official results, or other errors);`,
  'components.simple.a45t8y':
    'b. Reflecting details of transactions that we reasonably believe to be correct or fair.',
  'components.simple.6o3ll0':
    '4.7 Under applicable laws and regulations, BasicEx may need to share information about your account and the use of the BasicEx platform with third parties. You confirm, agree, and allow us the right to disclose such information.',
  'components.simple.g9h47v': '4.8 Service Fee',
  'components.simple.4ky6gb': `a. BasicEx may charge corresponding service fees for providing the Service to you. Unless otherwise stated on the BasicEx platform, the service fees charged by BasicEx are a specified percentage of your actual earnings (including interest and rewards) under this Service, and BasicEx will deduct the corresponding service fees from your actual earnings as the method of payment for the service fees.`,
  'components.simple.bvm49h': `b. You agree and authorize BasicEx to deduct the payable service fees from the assets in your account; otherwise, BasicEx has the right not to provide the services under this Agreement.`,
  'components.simple.ccy320': '5. Pause or Cancel Your Subscription',
  'components.simple.db7x1a': `5.1 According to the BasicEx Terms of Service, BasicEx may stop, suspend, or cancel your access to the BasicEx platform, impose restrictions on any BasicEx account, suspend or cancel your ability to subscribe to this service, or delay certain transactions if:`,
  'components.simple.v196mj': `a. You fail to provide additional information to verify your identity or the source of your funds as requested by BasicEx;`,
  'components.simple.18id34': `b. BasicEx complies with a court order to comply with any applicable laws or regulations (including anti-money laundering or sanction laws);`,
  'components.simple.26trc8':
    'c. BasicEx complies with any instructions or directives from government departments or agencies;',
  'components.simple.z13es2':
    'd. BasicEx reasonably determines that any information you provided to BasicEx is incorrect, untrue, outdated, or incomplete; or',
  'components.simple.5h7y44':
    'e. You fail to provide additional information as requested by BasicEx in a reasonable, satisfactory, or timely manner.',
  'components.simple.79g0s2': '6. Limitation of Liability',
  'components.simple.43vw58': `6.1 You agree and accept that BasicEx displays expected user earnings on the page as theoretical earnings, not guaranteed or promised by BasicEx. Actual earnings may differ from expected earnings or be lower than expected earnings, and your ultimate earnings will be based on actual earnings. BasicEx makes no commitments or guarantees regarding expected earnings or actual earnings you may receive.`,
  'components.simple.ad2oq2': `6.2 You agree and accept that the arrival times of certain currencies may differ during your redemption, and the information on the currency page shall prevail. BasicEx assumes no responsibility for any losses resulting from the existence and differences in arrival times.`,
  'components.simple.474yoj': `6.3 You agree and accept that BasicEx has the right to revise the content of this Agreement at any time. If you misunderstand this Agreement or any changes to it or fail to read the revisions to this Agreement in a timely manner, BasicEx shall not be liable for any losses incurred by you.`,
  'components.simple.r52ir9': `6.4 You agree and accept that BasicEx is not liable for damages or compensation when the BasicEx system platform cannot operate normally or causes service interruption, rendering you unable to use various services, issue instructions, or perform related transactions, including but not limited to failures, delays, interruptions, stops, unresponsive systems, slow system responses, or other abnormal conditions that prevent the normal use of the Service. This includes:`,
  'components.simple.3438jn':
    '- System maintenance periods announced by the BasicEx platform;',
  'components.simple.3w0472':
    '- Telecommunication equipment failures preventing data transmission;',
  'components.simple.2zleu8': `- Force majeure or other unforeseeable events, such as typhoons, earthquakes, tsunamis, floods, pandemics, power outages, wars, riots, government actions, terrorist attacks, or other accidents, causing platform system malfunctions that cannot execute business operations;`,
  'components.simple.i2y243': `- Service interruptions or delays caused by hacker attacks, computer virus intrusions or outbreaks, telecommunication department technical adjustments or failures, website upgrades, problems on the bank side, temporary closures due to government regulation, and other reasons affecting normal network operations;`,
  'components.simple.gn8q3r': `- Service interruptions or delays caused by BasicEx system platform damage, paralysis, or inability to operate normally due to technical problems that current industry technical capabilities cannot predict or resolve;`,
  'components.simple.c466p0':
    '- Losses caused by the negligence or delay of third parties to you or other third parties;',
  'components.simple.j1764b':
    '- Losses to you or other third parties caused by changes in laws or regulations or government, regulatory, or enforcement instructions; or',
  'components.simple.p66n4w':
    '- Losses to you or other third parties caused by other unforeseeable, unavoidable, and insurmountable force majeure or unexpected events.',
  'components.simple.q6x7d8':
    '- Losses to you or other third parties caused by other unforeseeable, unavoidable, and insurmountable force majeure or unexpected events.',
  'components.simple.jk53lr': `You agree and accept that the above reasons may lead to abnormal transactions, market interruptions, and other possible abnormal situations. BasicEx has the right to not execute your instructions based on the actual situation. You understand and agree that BasicEx is not liable for any losses (including but not limited to any direct or indirect losses, actual losses, or loss of potential profits) caused by the above situations.`,
  'components.simple.18dj4m': `6.5 You agree and accept that for any losses you may incur due to the risks described in Section 3.3 of this Agreement (including but not limited to any direct or indirect losses, actual losses, or loss of potential profits), BasicEx assumes no responsibility.`,
  'components.simple.m7quwg': '7. Technical Disclaimer',
  'components.simple.o7k2x3': `7.1 BasicEx may suspend your access to your BasicEx account, the BasicEx platform, this service, and/or any other BasicEx services from time to time for regular and emergency maintenance. BasicEx will make reasonable efforts to ensure timely processing of transactions on the BasicEx platform, but BasicEx makes no statements or guarantees regarding the time required to complete processing, which depends on many factors beyond BasicEx's control.`,
  'components.simple.e4dxgz': `7.2 Despite BasicEx's reasonable efforts to update information on the BasicEx platform, BasicEx makes no express or implied statements, warranties, or guarantees regarding the accuracy, completeness, or timeliness of the content on the BasicEx platform, including information related to this service and any other BasicEx services.`,
  'components.simple.ebq62o': `7.3 You are responsible for obtaining network access permissions required to use this service or any other BasicEx services. You are responsible for obtaining and updating the compatible hardware or devices needed to access and use this service, any BasicEx services, the BasicEx platform, and any updates. BasicEx does not guarantee that any BasicEx service or any part thereof will run on any specific hardware or device. BasicEx services may experience failures and delays due to the use of networks and electronic communications.`,
  'components.simple.50o29k': '8. Enforcement Measures',
  'components.simple.8vo15i': `You agree and accept that BasicEx strictly prohibits unfair trading practices. If you engage in the following activities, BasicEx reserves the right to implement enhanced supervisory control over your account or close your account at its discretion:`,
  'components.simple.716s6i': `- Engaging in market manipulation, price manipulation, insider trading, market distortion, any other malicious market behavior, or any other malicious market behavior;`,
  'components.simple.y7tut2': `- Potentially damaging other users or BasicEx by exploiting vulnerabilities in this service or other unreasonable means;`,
  'components.simple.syju60':
    '- Engaging in any other activities that BasicEx considers harmful to the market;',
  'components.simple.tvfc1s':
    '- Engaging in activities that violate any laws or regulations;;',
  'components.simple.a24892':
    '- BasicEx reserves the right to decide to take the following measures to eliminate any adverse impact on the overall health of the market, including but not limited to closing your account, restricting, or canceling instructions. You understand and agree that BasicEx is not liable for any losses (including but not limited to any direct or indirect losses, actual losses, or loss of potential profits) caused by the measures taken.',
  'components.simple.reg83l': '9. Liability for Compensation',
  'components.simple.61co62':
    '9.9.1 You should take all possible measures to ensure that BasicEx is exempt from any damages arising from your use of this service or other actions related to your BasicEx account. Otherwise, you should be liable for compensation to BasicEx.',
  'components.simple.q08qt1': `9.2 You hereby acknowledge and agree that for any claims, demands, or assertions (including all reasonable legal fees or other expenses) arising from your violation of this Agreement, your improper use of BasicEx services, your violation of any laws or regulations, or any third-party rights infringement, as well as any actions or omissions by any third party who uses your BasicEx account or logs into BasicEx's website, client, or system (including the network and servers used to provide any BasicEx services, whether operated by BasicEx, operated for BasicEx, or operated to provide BasicEx services to you), you shall defend, compensate, and make every effort to keep BasicEx harmless.`,
  'components.simple.vsc184': '10. Others',
  'components.simple.71o7m1':
    '10.1 The content of this Agreement includes various institutional regulations of BasicEx, other agreements or rules in the attachments of this Agreement, and other related agreements, rules, etc., which BasicEx may continuously publish regarding this service. Once officially published, the above content becomes an integral part of this Agreement. You agree to carefully read such agreements or rules and comply with the relevant provisions. You agree that such agreements or rules are legally binding on you. In case of conflict between the above content and this Agreement, this Agreement shall prevail.',
  'components.simple.drg5f9': `10.2 If necessary, BasicEx has the right to unilaterally change the content of this Agreement at any time without prior notice to you. If BasicEx unilaterally changes the content of this Agreement, the changed content will be immediately announced on BasicEx's website. Please check and carefully read the latest information on the website in a timely manner. After the announcement of such changes, you understand and agree that if you continue to use this service, it will be deemed that you have agreed to the changes to this Agreement, and such changes are legally binding on you. If you do not agree to the changes in this Agreement, you should stop using this service.`,
  'components.simple.8i03ph': `10.3 You should carefully read this Agreement and any other relevant agreements, rules, etc., constituting the content of this Agreement as referred to in Sections 10.1 and 10.2. If you have any questions about the above content, please contact our customer service through the Support Center, and BasicEx will provide reasonable and necessary explanations. Unless BasicEx has other requirements, you should contact BasicEx via email and transmit documents between you and BasicEx through email ("Formal Communication"). You can also informally contact BasicEx by phone ("Informal Communication"). Unless otherwise agreed by both parties, any formal communication should be conducted in English. If there is any inconsistency between the English version and the non-English version of any communication, the content of the English version shall prevail. If the content of any formal or informal communication conflicts with the content of this Agreement, this Agreement shall prevail.`,
  'components.simple.x726be': `10.4 If any term of this Agreement is deemed invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining terms of this Agreement shall not be affected or weakened in any form. However, if any term of this Agreement is deemed invalid, illegal, or unenforceable under the applicable laws of any jurisdiction, then, for that jurisdiction, such term should be deemed to be correspondingly modified to comply with the minimum requirements of that law. If, for any reason, such term is not considered to have been modified, it shall only be invalid, illegal, or unenforceable to the extent of such invalidity, illegality, or unenforceability in that jurisdiction, and the legality, validity, or enforceability of the remaining terms of this Agreement or such term under other jurisdictions' laws shall not be affected.`,
  'components.simple.34r56h': `10.5 BasicEx's failure or delay in exercising any right or remedy under this Agreement shall not be construed as a waiver of such right or remedy or as a confirmation of this Agreement. BasicEx's confirmation of this Agreement must be made in writing, otherwise, it is invalid. The exercise of any right or remedy in whole or in part once or in certain circumstances shall not hinder the further exercise of such right or remedy or the exercise of any other right or remedy. The rights and remedies stipulated under this Agreement are additional in nature and do not exclude any rights or remedies provided by law.`,
  'components.simple.8yus3r': `10.6 This clause, your use of this service, any type or nature of any claims, counterclaims, or disputes directly or indirectly arising from this Agreement, shall be governed by the laws of England and Wales and interpreted accordingly, without considering its conflict of laws rules.`,
  'components.simple.lo454v': '10.7 Jurisdiction and Dispute Resolution',
  'components.simple.567hub': `a. You acknowledge and agree that in the event of any dispute, controversy, differences, or claims, including the existence, validity, interpretation, performance, breach, or termination thereof, or any disputes arising out of or related to this Agreement ("Dispute"), the parties shall first attempt to resolve the Dispute through mediation in accordance with the then-effective Mediation Rules of the Hong Kong International Arbitration Centre ("HKIAC").`,
  'components.simple.8gqyqi': `b. If the Dispute is not resolved through mediation within ninety (90) days from the submission of the mediation request as provided in paragraph (i) above, then the Dispute shall be referred to the HKIAC for arbitration and finally resolved in accordance with the then-effective Administered Arbitration Rules of the Hong Kong International Arbitration Centre. The governing law of this arbitration clause shall be the law of the Hong Kong Special Administrative Region of the People's Republic of China ("Hong Kong").`,
  'components.simple.v5k5kn': `c. You agree that the place of arbitration shall be Hong Kong. The number of arbitrators shall be three (3). BasicEx shall appoint one (1) arbitrator, and you shall appoint one (1) arbitrator. The third arbitrator shall be appointed by the Chairman of the HKIAC. The arbitrators shall be chosen freely, and the parties are not restricted by any designated list when selecting arbitrators. The arbitration proceedings shall be conducted in English.`,
  'components.simple.pu2327': `d. You agree that BasicEx shall not be required to provide general discovery information but may only be required to provide specific, identified documents that are relevant to the dispute and materially significant to the outcome of the dispute.`,
  'components.simple.w86687': `e. Any arbitration award shall be final and binding on all parties and may be enforced in any court of competent jurisdiction.`,
  'components.simple.bw64il': `10.8 Without the prior written consent of BasicEx, you may not assign, transfer, or delegate this Agreement or any rights and obligations hereunder in whole or in part. BasicEx may transfer, replace, or delegate any rights or obligations under this Agreement by providing written notice to you, and such notice shall be effective upon delivery.`,
  'components.simple.137yu5': `10.9 The titles of all clauses in this Agreement are for convenience only and have no substantive meaning, and they cannot be used as the basis for interpreting the meaning of this Agreement.`,
  'components.simple.co7ivn': `10.10 In case of any conflict between the content of this Agreement and the translated versions in other languages, the content of the English version of this Agreement shall prevail.`,
  'components.simple.4i87f9': `10.11 The ultimate right of interpretation of this Agreement belongs to BasicEx.`,
  'login.indexnew.e6y77j': 'Login with QR Code',
  'login.indexnew.kv2529': 'QR Code has expired',
  'login.indexnew.6dd1e5': 'Refresh Now',
  'login.indexnew.jk723f': 'Confirm on App',
  'login.indexnew.msql2b': 'Use',
  'login.indexnew.1ls8gl': 'Bi Qu Mobile App',
  'login.indexnew.4p37cx': 'Scan QR Code',
  'login.index.518our': 'Get QR Code',
  'login.country.cpp4yr': 'Select Country Code',
  'login.country.o4efb0': 'Search',
  'login.country.dbb7i9': 'Select Region',
  'enforcement.index.glq4x4': 'Basicex Law Enforcement',
  'enforcement.index.j6do82':
    'Our vision is to address the design challenges of cryptocurrencies and blockchain, setting standards for the next phase of mainstream applications.',
  'enforcement.index.ns090u': 'Freeze Orders and Disclosure Orders',
  'components.disclosure.c31xn2': 'Basicex Terms of Use',
  'components.disclosure.7m8c62': 'Basicex Court Order Portal',
  'components.disclosure.qhl2v9':
    '(Applicable to Freeze Orders/Disclosure Orders)',
  'components.disclosure.7xrmo3':
    'Please use this page only if you hold a court order',
  'components.disclosure.dcq7c7':
    "Welcome to the Basicex Legal Freeze Order/Disclosure Order Portal. Authorized representatives can use this portal to submit freeze orders and disclosure orders related to Basicex's official cryptocurrency trading platform user accounts. Please ensure that your order is related to a platform user and not to a separate trading platform",
  'components.disclosure.q5b601':
    'Please fill in the information and submit supporting documents on the next page.',
  'components.disclosure.j6h429':
    'As this portal is associated with a specific team at Basicex, it is only intended for submitting final freeze orders and disclosure orders. Any other court orders or litigation proceedings should not be submitted through this dedicated portal but should be sent to the registered office of the relevant Basicex entity. Basicex does not review or accept any other materials delivered through this portal.',
  'components.disclosure.d71ku2':
    'Please ensure that your submitted order has been acknowledged and that you have received the reference information for the submitted order. Basicex will review all successfully submitted cases, but Basicex is not responsible if you do not check the review status.',
  'components.disclosure.23iexl':
    'It is best to submit related orders only once. Repeated submissions will only delay the processing of your case.',
  'components.disclosure.8nr8z1':
    "The opening of this portal by Basicex is not intended to comply with each freeze order and disclosure order submitted. Basicex's ability to freeze user IDs (accounts) or disclose information related to user IDs (accounts) to third parties is subject to applicable laws and regulations, as well as Basicex's user rights (and Basicex's obligations to users).\r\nBasicex will review any freeze order or disclosure order successfully submitted through this portal to determine whether it can be complied with and enforced. Basicex reserves the right to oppose any freeze order or disclosure order it currently has or may have in the future.",
  'components.disclosure.27z7cv':
    "Depending on the courts and entities involved, Basicex also reserves the right to challenge the jurisdiction of the relevant courts issuing freeze orders or disclosure orders. The opening of this portal by Basicex for uploading specific orders does not imply Basicex's acceptance of the jurisdiction of the relevant courts, nor does Basicex waive any rights it may have to oppose the jurisdiction of the relevant courts.",
  'components.disclosure.4gd7w7':
    "For freeze orders and/or disclosure orders that Basicex can comply with and handle without the need to hire external lawyers, Basicex typically requests a reasonable compliance fee of $500 per user ID (account) involved in the freeze order, and similarly, Basicex requests a reasonable compliance fee of $500 per user ID (account) involved in the disclosure order. If the same user ID (account) is involved in both orders or joint orders, the reasonable compliance fee for Basicex related to that user ID (account) is $1,000.\r\nBy using this portal to submit freeze orders and/or disclosure orders, you agree to pay Basicex reasonable compliance fees based on the above provisions (but this should not affect Basicex's absolute discretion to determine whether it is necessary to obtain reimbursement of legal fees already paid when Basicex believes it is necessary to obtain external legal advice)",
  'components.disclosure.3675e8':
    'We agree to the above terms of use for this portal (we may publish guides on legal orders at appropriate times).',
  'components.disclosure.5nw311': 'Please agree to the disclaimer',
  'components.disclosure.13563x': 'Agree and Submit Request',
  'components.formbox.ibm33u': 'Submit Request',
  'components.formbox.42c25u': 'Yes',
  'components.formbox.6775e9':
    'Currently, we have a large backlog of requests. To expedite the resolution process, please do not resubmit credentials. Also, we strongly recommend visiting the Basicex FAQ page to quickly find answers:',
  'components.formbox.wh3bb8': '. Thank you very much for your cooperation',
  'components.formbox.4j461y': 'Please select your issue (Legal)',
  'components.formbox.r716w9':
    'Your email address for receiving replies (and any disclosure information)',
  'components.formbox.4ipunl': 'Your Name',
  'components.formbox.fz9724': 'Company Name',
  'components.formbox.5m129h': 'Country/Region of Court',
  'components.formbox.1t32m3': 'Issuing Court',
  'components.formbox.x5c5m4': 'Reply Date for Multifaceted Review Hearing',
  'components.formbox.kl233z': 'Claim Number/Reference',
  'components.formbox.339ddn':
    'Accounts to Be Frozen (Owner Name/User ID/Account Email)',
  'components.formbox.l9w4t3':
    'Confidentiality Request (Can Users Be Notified)',
  'components.formbox.e8n33x': 'Please enter your email',
  'components.formbox.6fk7qq': 'Subject',
  'components.formbox.h6mhj9': 'Description',
  'components.formbox.3qhc3v': 'Attachment',
  'home.index.13w8x6': 'BasicEx Introduction',
  'home.index.sf95h6': 'Basicex Wallet',
  'home.index.916ckf':
    'A secure and convenient multi-chain wallet where you can easily complete operations such as recharge, withdrawal, buying, and selling with just one click. You can also transfer/receive funds for free to/from Basicex wallet users.',
  'home.index.yrsun1':
    'Supports multiple public chains such as TRC20, ERC20, BSC, and POLYGON.',
  'home.index.089fo6':
    'Supports various cryptocurrencies such as USDT, ETH, BTC, and TRX.',
  'home.index.r2831g': 'Open Wallet',
  'home.index.i886e8': 'Blockchain Payments',
  'home.index.c372qd':
    "Merchants wishing to accept USDT payments can use Basicex Pay to facilitate the process through Basicex's blockchain payment processing system.",
  'home.index.y3x6w2':
    'This is a decentralized payment platform that allows merchants to accept USDT transactions. One of the main benefits of such payments is that transaction costs are much lower than traditional payment methods, but there are many other benefits as well.',
  'home.index.yvfa61':
    'Buy and sell cryptocurrencies with one click, or trade with selected merchants online. Register as a Basicex Wallet user to start enjoying it now.',
  'home.index.1n42v0':
    'Basicex C2C platform provides escrow trading services to ensure the security of your transactions.',
  'home.index.81h875':
    'Supports a variety of cryptocurrencies/fiat currencies, no T+1 limit, and can conduct online transactions after completing real-name authentication.',
  'home.index.g1p541': 'Buy with One Click',
  'localeAll.234asd': 'Please select the recharge currency',
  'components.header-info.j6esv2': "Beginner's Tutorial",
  'resigter.verification.w175qb':
    'The verification code is invalid, please click to get it again.',
  'qrrouter.wxtipcom.8axip0': 'Click on the top right corner to select',
  'qrrouter.wxtipcom.2wm33j': 'Open in browser',
  'qrrouter.index.wvls92': 'Please scan with the CoinQu App',
  'qrrouter.index.tt12fs': 'Opening CoinQu App...',
  'qrrouter.index.id86f1': 'Open CoinQu App',
  'qrrouter.index.u63nz6':
    "If you haven't installed yet, please download first",
  'qrrouter.index.4dxm5b': 'Download CoinQu App',
  'recharge.index.9z9w2e': 'Wallet Address',
  'recharge.index.2f8wh2':
    'Do not deposit to a contract address, or the funds will be irretrievable',
  'payment.index.0n2eod': '{0} coupons',
  'payment.index.6c163p': 'Coupons',
  'payment.index.3uy4pw': 'Select coupon',
  'payment.index.i15zz2': 'Full',
  'payment.index.16l0wm': 'Available',
  'payment.index.u7tek5': 'Expires',
  'payment.index.64iqff': 'Unavailable',
  'payment.index.hkk670': 'Do not use coupons',
  'payment.index.zd1b88': 'View coupon history',
  'payment.index.59376r': 'Used',
  'payment.index.jbd8h6': 'No records',
  'payment.index.17zzsj': 'Expired',
  'payment.index.ww2c87': 'Not Available',
  'navbar.index.u61ns9':
    'Network unavailable, please check your network settings.',
  'balance.coupon.1nmcyv': 'Unused',
  'down.index.u22o39': 'Backup Download:',
  'down.index.w2bq03': 'Click to Download',
  'down.index.Tutorial1': 'Adding H5 page to home screen.',
  'down.index.Tutorial2': 'Tutorial',
  'login.indexnew.uc1wu1': 'Trust this device',
  'login.indexnew.start': 'Start time',
  'login.indexnew.end': 'End time',
  'login.indexnew.kown': 'I got it',
  'identityauthentication.idcard.2tyer4': 'Please upload',
  'identityauthentication.idcard.ly8zq2': 'Photo of the document',
  'identityauthentication.idcard.4nn5ve':
    'Please scan the QR code with your phone and follow the prompts to complete advanced authentication',
  'identityauthentication.idcard.ej24b3': 'Scan to verify',
  'identityauthentication.idcard.8fp6ul': 'Facial recognition',
  'identityauthentication.idcard.im974z':
    'Please upload the document information first',
  'transferaccounts.index.4cy1yh': 'Edit Remarks',
  'resigter.index.234dsd':
    'Phone numbers from this region are not supported, please use email to register.',
  'resigter.index.234dsa': 'Phone numbers from this region are not supported.',
  'resigter.index.234dsab': 'Others',
  'tab.loc1': 'Language and Region',
  'tab.loc2': 'Exchange Rate',
  'moneywallet.flashhistory.q6rvgn': 'Flash Exchange History',
  'moneywallet.flashhistory.98n2vu': 'Initial Currency',
  'moneywallet.flashhistory.452g9m': 'Initial Amount',
  'moneywallet.flashhistory.5fx2j6': 'Exchanged Currency',
  'moneywallet.flashhistory.yq7qj6': 'Exchanged Amount',
  'moneywallet.flashhistory.kf7611': 'Exchange Rate',
  'explain.index.9epet3': 'Reset',
  'localeAll.text499': `Channel Partner`,
};

