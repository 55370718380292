import localeLogin from '@/views/login/locale/zh-CN';
import localeHome from '@/views/down/locale/zh-CN';
import localeSettings from './zh-CN/settings';
import localeAll from './zh-CN/all';
import simple from './zh-CN/simple';
import localePri from './zh-CN/pri';

export default {
  'menu.required': '请填写所有必填项',
  'menu.locale': '简体中文',
  'menu.dark': '黑夜模式',

  'menu.overview': '总览',
  'menu.balance': '余额',
  'menu.recharge': '充值',
  'menu.buycoins': '买币',
  'menu.financial': '理财',
  'menu.transferaccounts': '转账',
  'menu.withdrawal': '提现',
  'menu.c2corder': 'C2C订单',
  'menu.payhis': 'PAY历史交易',
  'menu.wallethistory': '钱包历史记录',
  'menu.title': 'BasicEx全球领先的加密货币支付服务商',

  'menu.loginOut': '退出登录',
  'navbar.action.locale': '切换为简体中文',
  'public.next': '下一步',
  'public.submit': '提交',
  ...localeSettings,
  ...localeLogin,
  ...localeHome,
  ...localeAll,
  ...localePri,
  ...simple,
};
