<template>
  <a-tooltip background-color="var(--color-fill-2)" position="tr">
    <slot name="text"></slot>
    <template #content>
      <Alert
        :style="{
          width: '100%',
          height: '60px',
        }"
        :message="$t('texttool.index.614p6n')"
      />
      <ul
        :style="{
          padding: '0',
          marginLeft: '10px',
          color: 'var(--color-text-1)',
        }"
      >
        <li>
          <p
            :style="{
              padding: '0',
            }"
            >{{ $t('texttool.index.8tkh16') }}</p
          >
          <p
            :style="{
              fontSize: '12px',
              color: 'var(--color-text-2)',
            }"
            >{{ $t('texttool.index.8322uj') }}</p
          >
        </li>
        <li>
          <p>{{ $t('texttool.index.d4btk5') }}</p>
          <p
            :style="{
              fontSize: '12px',
              color: 'var(--color-text-2)',
            }"
            >{{ $t('texttool.index.85q6ja') }}</p
          >
        </li>
      </ul>
    </template>
  </a-tooltip>
</template>

<script lang="ts" setup></script>

<style lang="less" scoped></style>
