export default {
  'components.simple.756g85': '1.概述',
  'components.simple.08c0iq':
    '1.1 链上赚币服务(「本服务」)是Hola Limited及其关联公司（统称为「币趣」）推出的透过参与链上数字货币或其他数字资产(「此数字资产」)质押并获取奖励的产品,并根据某些条款和条件允许赎回。由币趣负责产品的设计管理及本服务相关运营服务。为使用本服务，您应阅读并遵守本链上赚币使用者协议(「本协议」)。请您务必审慎阅读、充分理解并同意各条款内容。',
  'components.simple.u4s2bs':
    '1.2除非您已阅读并接受本协议所有条款，否则您无权或不应该使用本服务。您接受本协议的行为，以及您使用本服务的行为即视为您已阅读、了解并同意本协议，本协议对您具有法律约束力。',
  'components.simple.7kr04z':
    '1.3本协议应补充币趣服务条款。如果本协议中规定的任何条款或规定与币趣服务条款发生任何冲突或不一致，应透过优先考虑本协议来解决此类冲突或不一致。币趣服务条款中未被本协议修改的所有其他规定仍具有完全之效力。',
  'components.simple.i612nw': '2.定义',
  'components.simple.pd935k':
    '2.1.链上赚币是指在区块链网络中锁定并持有数字资产以参与其共识链上协议并获取奖励的做法。链上赚币可能涵盖各种区块链网络和技术。所涉及的具体功能和技术可能会根据相关的区块链网络而有所不同。链上赚币可以包括不同的区块链网络和技术，它主要涉及参与共识链上协议，例如权益证明（「PoS」），以保护网络并验证交易。',
  'components.simple.ur1w4y':
    '2.2 本服务是实现适用区块链网络的PoS链上协议的解决方案，涉及链上质押以获取奖励。',
  'components.simple.quu3go': '2.3 与服务相关的其他定义包括：',
  'components.simple.6o03hk':
    '本金：为使用本服务而存入用户帐户的一定数量之数字资产。',
  'components.simple.114i84':
    '赎回：质押的该数字资产赎回需合理时间/日数提取至帐户。',
  'components.simple.2588q2': '奖励计算日: 具有第4.4(a) 节中的意义。',
  'components.simple.131i2v':
    '服务费:指用户在申购或参与本服务时须向币趣支付的费用，其中包括但不限于验证节点佣金费、上下链矿工费（gasfee）等。',
  'components.simple.5y670y':
    '质押：本服务中，币趣将用户一定数量的数字资产冻结，并接收代币或其他数字资产',
  'components.simple.6rlo4p':
    '作为PoS。在质押后，用户在赎回期限前将无法赎回或提取资产。',
  'components.simple.7w4en4': 'T日：以UTC+8地域时间计算的自然日。',
  'components.simple.x831kf':
    '可变质押率: 具有第4.4(c) 条中的含义以及用户在认购本服务期间币趣平台上显示和引用的含义。',
  'components.simple.e612g4': '3.使用本服务的资格',
  'components.simple.vzb4x4': '3.1 您同意并确认：',
  'components.simple.v6iq7u': 'a.币趣全部或部分质押您的数字资产；',
  'components.simple.53c448': 'b.币趣在适用网络上充当验证者； 和',
  'components.simple.48k24p':
    'c. 将与您质押的该数字资产相关的任何投票权委托给币趣；',
  'components.simple.08kj10': 'd.币趣可不时就服务指定，',
  'components.simple.v3c613': '认购服务所需的最低质押数字资产数量；',
  'components.simple.jnp1we': '任何赎回期；',
  'components.simple.fafb6a': '任何赎回限制；及/或',
  'components.simple.t2cfu8': '任何认购限制。',
  'components.simple.4zwvtu': '3.2 您亦了解并确认：',
  'components.simple.nuu7bv':
    '您已同意遵守币趣服务条款，同时您有资格且不被禁止使用其中定义的「服务」；',
  'components.simple.21xq44':
    '如果您正在以个人身份注册使用本服务，为了有效接受本协议，您必须年满18岁，是具有完全民事权利能力和民事行为能力的个人，具有签署本协议所有必需的权力和能力，本协议一经签署对您具有约束力；',
  'components.simple.18143v':
    '如果您是代表法人实体注册使用本服务，为了有效接受本协议，您声明并保证（i）此类法律实体根据适用法律合法成立并有效存在;（ii）您获得该法律实体的正式授权，有权代表其行事。',
  'components.simple.izihsy':
    '您具备使用本服务相应的风险识别能力和风险承受能力，拥有投资非保本类数字资产产品的经验和相关知识；',
  'components.simple.4a2i9n':
    '为使用本服务，您具备在互联网上进行相应操作的能力；',
  'components.simple.dl4sk2':
    '本协议与您所在国家或地区的法律没有冲突，您保证将遵守所在国家或地区的法律；',
  'components.simple.l2eqy3':
    '您是您币趣平台帐户中数字资产的合法所有者，并保证这些数字资产的来源是合法的。',
  'components.simple.830685': '3.3 您亦同意并承诺：',
  'components.simple.oowst3':
    '您不会将本服务用于任何非法目的、进行、发起或促进任何形式的市场操纵、价格操纵、内线交易、市场扭曲、任何其他恶意的市场行为或其他形式的非法行为，包括但不限于非法赌博，洗钱，欺诈，勒索，敲诈勒索，数据泄露，恐怖主义融资，其他暴力活动或任何法律禁止的商业行为。',
  'components.simple.26iroe':
    '3.4您了解并接受使用本服务可能对您产生的风险和您可能因此而遭受的相关损失，包括但不限于：',
  'components.simple.gcf336':
    '您所持有的数字资产质押后因您无法对该等被冻结的数字资产进行交易等操作而引起的不可交易风险，您愿意承担数字资产质押带来的资金风险及您可能遭受的经济损失；',
  'components.simple.tc17i7':
    '币趣在页面展示用户预期收益为预期理论收益而非币趣所保证或承诺的实际收益，实际收益可能与预期收益不符合，您的最终收益将以您收到的实际收益为准，币趣将根据质押相关操作所产生的实际收益减去币趣代用户进行质押相关操作的成本、费用和支出（包括但不限于服务器费用、电费等）减去币趣收取的质押相关手续费的方式计算用户的实际收益，币趣不会对您可能收到的实际收益作出任何保证或承诺；',
  'components.simple.0ph46i':
    '数字资产及其衍生性商品的高价格波动可能会在短时间内造成重大或全部损失;',
  'components.simple.pj8gsy':
    '可能会发生技术异常，这可能会延迟或阻止您使用本服务或进行相关交易等操作，这可能导致您的数字资产损失;',
  'components.simple.c3wp4v': '可能发生市场异常，这可能导致您的数字资产损失；',
  'components.simple.4642wm':
    '为了维持市场的整体健康，币趣需要不时添加、删除或更改政策、本协议条款或其他相关服务协议或条款的相关规定。这类行为可能有利于或损害像您一样的个人客户。',
  'components.simple.49x4f1': '4.使用本服务',
  'components.simple.19w0n6':
    '4.1您可以透过币趣客户端下达质押、赎回等操作指令。一旦操作指令完成后，您将无法取消、撤销或修改指令。',
  'components.simple.3cix8w':
    '4.2质押成功后您将自动委托并授权币趣对被锁账户中的一定数量的该数字资产进行包括但不限于投票、数字资产增益行为。',
  'components.simple.fkx5je':
    '4.3一旦该数字资产被质押或锁定，币趣将向您发行代币或其他数字资产作为奖励。该数字资产的使用应遵守币趣在币趣平台不时公布的规则和条款，币趣有权随时自行决定允许、考虑、修改或取消任何出于任何目的对该数字资产在币趣平台的任何使用。',
  'components.simple.73e58y': '4.4 质押奖励',
  'components.simple.1l6su3':
    'a.质押奖励将根据特定链上协议的累积时间开始累积，这可能会根据您质押的区块链或网络而有所不同。奖励的累积将从您认购服务之日（称为「奖励计算日」）开始，并按照链上协议指定的累积时间表进行。质押奖励将根据一年365天过去的天数来计算。请注意，您认购的第一天不会获得任何质押奖励；您的奖励将从第二天开始累积，与链上协议确定的每日累积时间一致;',
  'components.simple.60u1s3':
    'b.质押奖励将根据特定链上协议的分配时间表分配到您的资金账户，该分配时间表可能会根据您质押的区块链或网络而有所不同。这些奖励将在每个奖励计算日的次日记入您的资金账户，前提是与链上协议指定的分配时间一致。请注意，分发的时间和频率受链上协议制定的规则和准则的约束；',
  'components.simple.kjo2z0':
    'c.参考质押奖励产生当日的可变质押利率计算。为避免疑问，这种&ldquo;可变质押率&rdquo;，即在链上收益情况下每天可能变化的APY，应在币趣平台上公布，并且可能会不时发生变化；',
  'components.simple.trfh0l': 'd. 计算并四舍五入至小数点后8 位。',
  'components.simple.8q8zh6':
    '为避免疑问，币趣可能会保留部分链上奖励。该金额可能与质押奖励不同。币趣不保证您将按照您认购时显示的可变质押率获得质押奖励。关于您认购时可能回报的任何陈述(i)仅是指示性估计而不是保证，(ii)基于可变质押率，该利率可能会发生变化，并且(iii)可能高于或低于连锁奖励。',
  'components.simple.3e5int': '4.5 赎回规则',
  'components.simple.mp83y6':
    'a.赎回的数字资产到达使用者账户需遵守链上协议的兑换规则，具体时间可能会有所不同。请注意，赎回时间可能会因数字资产类型而异。您了解并同意，在赎回时间至实际到账时间之间，您将无法对所赎回的数字资产进行任何回馈、转账或其他指示。',
  'components.simple.8q351n':
    'b. 在赎回日当天或之后，币趣可能只允许每天赎回有限数量的数字资产。',
  'components.simple.u54482':
    'c.一旦您提交了赎回请求，您将不再获得与您请求赎回数字资产相关的任何质押奖励。为避免疑问，将数字资产赎回之行为不符合任何质押奖励的条件。一旦下达和/或确认赎回订单，该订单将不能被取消、撤销或修改。',
  'components.simple.30rpa8':
    'd. 您应能在提出赎回请求后的合理时间内收到数字资产。',
  'components.simple.7x1wdy':
    'e.所有赎回请求均由币趣按照先进先出（FIFO）的原则处理，并在当天结束时（UTC+8收市）由币趣处理。',
  'components.simple.zr617j':
    'f.币趣保留对处理数字资产赎回收取服务费的权利，该服务费应在币趣平台上公布，并可由币趣不时修改。',
  'components.simple.o87n4p': 'g. 您了解并承认，您在赎回期间不会获得质押奖励。',
  'components.simple.46u02b':
    '4.6您将能够在币趣平台上查看您的账户历史记录。您必须仔细查看您的账户历史记录，如果您发现任何您不认识或您认为不正确的条目或交易，请尽快告知币趣。币趣可以随时纠正您账户历史中的任何错误，并保留取消或撤销任何交易的权利：',
  'components.simple.7wu1il':
    'a.涉及或源自明显错误（即任何明显的错误、遗漏或错误引用（无论是币趣或任何第三方的错误），包括币趣任何代表在考虑当前市场和当前广告报价后的错误报价，或任何信息、来源、官方、官方结果或其他的任何错误）；或',
  'components.simple.a45t8y': 'b. 反映我们合理认为是正确或公平的交易细节。',
  'components.simple.6o3ll0':
    '4.7根据适用法律法规，币趣可能需要与第三方分享有关您的账户和使用币趣平台的信息。您确认、同意并允许我们有权揭露此类信息。',
  'components.simple.g9h47v': '4.8 服务费',
  'components.simple.4ky6gb':
    'a.币趣可就向您提供本服务收取相应的服务费。除非币趣平台另有说明,币趣收取的服务费为您在本服务项下的实际收益（包括利息和奖励）的指定百分比，而币趣将从您的实际收益中扣除相应的服务费作为服务费的支付方式。',
  'components.simple.bvm49h':
    'b.您同意并授权币趣，币趣有权从您账户中的资产扣除应付的服务费，否则币趣有权不向您提供本协议项下服务。',
  'components.simple.ccy320': '5.暂停或取消您的认购',
  'components.simple.db7x1a':
    '5.1.根据币趣服务条款，币趣可以停止、暂停或取消您对币趣平台的访问，对任何币趣账户施加限制，暂停或取消您认购本服务的能力，或延迟某些交易：',
  'components.simple.v196mj':
    'a. 您沒有依照币趣的要求提供额外资讯来验证您的身分或资金来源；',
  'components.simple.18id34':
    'b.币趣依照法院命令，以遵守任何适用法律法规（包括反洗钱或制裁法）；',
  'components.simple.26trc8': 'c.币趣遵守政府部门或机构的任何指示或指示；',
  'components.simple.z13es2':
    'd.币趣合理地确定您向币趣提供的任何资讯是错误的、不真实的、过时的或不完整的；或',
  'components.simple.5h7y44':
    'e. 您沒有依照币趣的要求而提供合理满意或及时要求的额外资讯。',
  'components.simple.79g0s2': '责任限制',
  'components.simple.43vw58':
    '6.1您同意并接受：币趣在页面上展示用户预期收益为预期理论收益而非币趣所保证或承诺的实际收益，实际收益可能与预期收益不符合或低于预期收益，您最终的收益以实际收益为准。币趣对预期收益或您可能收到的实际收益不做任何承诺或保证。',
  'components.simple.ad2oq2':
    '6.2您同意并接受：在您赎回时，部分币种的到账时间可能不同，以币种页面显示为准，币趣对任何因到账时间的存在及其差异而导致的损失不承担任何责任。',
  'components.simple.474yoj':
    '6.3您同意并接受：币趣有权自行决定随时修订本协议的内容，如您对本协议或其任何更改存在误解或未及时阅读本协议的修订，币趣对您因此而造成的任何损失不承担任何责任。',
  'components.simple.r52ir9':
    '6.4您同意并接受：当币趣系统平台因下列状况无法正常运作或导致服务中断，使您无法使用各项服务或无法正常下达指令或进行相关交易等操作时，包括但不限于失败、延迟、中断、停止、系统无回应、系统反应慢或其他无法正常使用本服务的情况，币趣不承担损害赔偿责任，此状况包括但不限于：',
  'components.simple.3438jn': '币趣平台公告之系统停机维护期间；',
  'components.simple.3w0472': '电信设备故障不能进行数据传输的；',
  'components.simple.2zleu8':
    '因台风、地震、海啸、洪水、瘟疫流行、停电、战争、动乱、政府行为、恐怖攻击等不可抗力或其他意外事件之因素，造成币趣平台系统障碍不能执行业务的；',
  'components.simple.i2y243':
    '因黑客攻击、电脑病毒侵入或发作、电信部门技术调整或故障、网站升级、银行方面的问题、因政府管制而造成的暂时性关闭等影响网络正常经营的原因而造成的服务中断或延迟；',
  'components.simple.gn8q3r':
    '币趣系统平台因电脑系统遭到破坏、瘫痪或无法正常使用而造成的服务中断或延迟；',
  'components.simple.c466p0':
    '因为产业现有技术力量无法预测或无法解决的技术问题所造成的损失；',
  'components.simple.j1764b':
    '因第三方的过失或延误而对您或其他第三方造成的损失；',
  'components.simple.p66n4w':
    '因法律法规或政府、监管或执法指令的变更而对您或其他第三方造成的损失；',
  'components.simple.q6x7d8':
    '因其他不可预见、无法避免且无法克服的不可抗力或意外事件而对您或其他第三方造成的损失。',
  'components.simple.jk53lr':
    '您同意并接受：上述原因可能导致的异常成交，行情中断，以及其他可能的异常情况，币趣有权根据实际情况不执行您的指示。您了解并同意币趣对上述情形而对您造成的任何损失（包括但不限于任何直接或间接损失、实际损失或可得利益损失等任何损失）不承担任何责任。',
  'components.simple.18dj4m':
    '6.5您同意并接受：对于您因本协议3.3条所述的风险而遭受的任何损失（包括但不限于任何直接或间接损失、实际损失或可得利益损失等任何损失），币趣不承担任何责任。',
  'components.simple.m7quwg': '技术免责声明',
  'components.simple.o7k2x3':
    '7.1币趣可能会不时暂停您对币趣账户、币趣平台、本服务和/或任何其他币趣服务的访问，以进行定期和紧急维护。币趣将尽合理努力确保及时处理币趣平台上的交易，但币趣对完成处理所需的时间不作任何陈述或保证，这取决于币趣无法控制的许多因素。',
  'components.simple.e4dxgz':
    '7.2尽管币趣已尽合理努力更新币趣平台上的信息，但币趣不就币趣平台上的内容，包括与本服务及任何其他币趣服务相关的信息作出任何明示或暗示的陈述、保证或担保,是准确的、完整的或最新的。',
  'components.simple.ebq62o':
    '7.3您應對於取得使用本服務或任何其他币趣服務所需的資料網路存取權限負責任。您應負責獲取和更新訪問和使用本服務、任何币趣服務、币趣平台及其任何更新所需的兼容硬體或設備負責任。币趣不保證任何币趣服務或其任何部分將在任何特定硬體或設備上運行。币趣服務可能會因使用網路和電子通訊而發生故障和延遲。',
  'components.simple.50o29k': '强制措施',
  'components.simple.8vo15i':
    '您同意並接受：币趣严格禁止不公平的交易行为。如果您参与以下行为，币趣保留自行决定对您的帐户实施高阶主管控制权或关闭您的帐户的权利：',
  'components.simple.716s6i':
    '参与市场操纵、价格操纵、内幕交易、市场扭曲、任何其他恶意的市场行为或任何其他恶意的市场行为;',
  'components.simple.y7tut2':
    '可能通过利用本服务的漏洞或其他不合理的手段损害其他用户或币趣;',
  'components.simple.syju60': '参与币趣认为对市场有害的任何其他活动；',
  'components.simple.tvfc1s': '参与违反任何法律法规的活动;',
  'components.simple.a24892':
    '为了消除任何对市场整体健康的不利影响，币趣保留自行决定采取以下措施的权利，包括但不限于关闭您的帐户，限制或取消指示。您了解并同意币趣对因采取上述措施而给您带来的任何损失（包括但不限于任何直接或间接损失、实际损失或可得利益损失等任何损失）不承担任何责任。',
  'components.simple.reg83l': '赔偿责任',
  'components.simple.61co62':
    '9.1您应该采取一切可能的措施保证币趣免受任何来自于您使用本服务或其他与您的币趣帐户相关的行为的损害，否则您应该对币趣承担赔偿责任。',
  'components.simple.q08qt1':
    '9.2您在此确认并同意，对因您违反本协议的行为、您不正当使用币趣的服务的行为、您违反任何法律法规或侵犯任何第三方权利的行为、经您授权使用您的币趣帐户或登录币趣网站、客户端、系统（包括用于提供任何币趣服务的网络和服务器，且无论该等网站、客户端和系统是由币趣运营，或者是为了币趣而运营，或者是为了为向您提供币趣服务而运营）的任何第三方的作为或不作为而引起的任何索赔、要求或主张（包括一切合理的法律费用或其他费用或支出），您应该进行抗辩、赔偿，并尽一切努力使币趣免受伤害。',
  'components.simple.vsc184': '其它',
  'components.simple.71o7m1':
    '10.1本协议内容同时包括币趣的各项制度规范、其他本协议附件中的协议或规则、币趣可能不断发布的关于本服务的其他相关协议、规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同意您将认真阅读该等协议或规则并应遵守该等协议或规则的相关规定，您同意该等协议或规则对您具有法律约束力。上述内容与本协议有冲突的，以本协议为准。',
  'components.simple.drg5f9':
    '10.2如有必要，币趣有权自行决定随时单方变更本协议的内容，且币趣无需事前通知您。如果币趣单方变更了本协议的内容，变更的内容将立即在币趣的网站上公布，届时请您及时查看并认真阅读上述网站上的最新信息。在上述变更公布后，您了解并同意如果您继续使用本服务，则视为您已经同意对本协议的变更并且该等变更对您具有法律约束力。如果您不同意本协议的变更，您应该停止使用本服务。',
  'components.simple.8i03ph':
    '10.3您应该认真阅读本协议以及本协议第10.1条和10.2条所提到的任何构成本协议的内容的其他相关协议、规则等内容。如果您对上述内容有任何疑问，請通过SupportCenter联系我们的客户服务，币趣将提供合理且必要的说明。除非币趣有其他要求，否则您应该通过电子邮件的方式联系币趣，并通过电子邮件的方式和币趣之间进行文件的传输（「正式沟通」）。您也可以非正式地通过电话联系币趣（非正式沟通）。除非双方有任何其他约定，否则任何正式沟通都应该使用英语进行。若任何英文版的沟通和非英文版的沟通不一致，则以英文版的沟通内容为准。若任何正式沟通或非正式沟通的内容与本协议的内容不一致，以本协议的内容为准。',
  'components.simple.x726be':
    '10.4如本协议任何条款被认定为无效、违法或不可执行，本协议其余条款的有效性、合法性和可执行性均不应受任何形式的影响或削弱。但是，如果本协议的任何条款在任何司法管辖区的任何适用法律项下无效、违法或不可执行，则就该司法管辖区而言，该等条款应被视为相应修改以符合该法律的最低要求，或者，如果出于任何原因该等条款不被视为已修改，则该等条款仅在该无效性、违法性或对可执行性限制的范围内无效、违法或不可执行，本协议中其余条款的合法性、有效性或可执行性或该等条款在其他法域法律下的合法性、有效性或可执行性均不应受任何形式的影响。',
  'components.simple.34r56h':
    '10.5币趣未有或延迟行使本协议项下任何权利或补救权不应视作放弃该等权利或补救权或构成对本协议的确认。币趣对本协议的确认必须通过书面形式作出否则无效。任何一次或部分行使任何权利或补救不应防礙进一步或在其他情况行使该等权利，或行使任何其他权利或补救权。本协议下规定的权利和补救权均为附加性质，不排除法律规定的任何权利或补救。',
  'components.simple.8yus3r':
    '10.6本条款、您对本服务的使用、由本条款直接或间接引发的任何类型或性质的任何权利主张、反诉或争议应受英格兰和威尔斯法律管辖，并依其解释，无需考虑其冲突法规则。&nbsp;',
  'components.simple.lo454v': '10.7 管辖和争议解决',
  'components.simple.567hub':
    'a.您确认并同意，如果发生任何争议、争论、分歧或权利主张，包括本条款的存在、有效性、解释、履行、违反或终止或由本条款引起的或与之相关的任何争议（「争议」），双方应先根据届时有效的《香港国际仲裁中心调解规则》将争议提交香港国际仲裁中心（「HKIAC」）。',
  'components.simple.8gqyqi':
    'b.若在上述8.7条第(i)款中规定的调解请求提交后九十（90）日内未通过签署和解协议的方式解决争议，则该等争议应提交HKIAC，根据提交仲裁通知时有效的《香港国际仲裁中心机构仲裁规则》通过仲裁最终解决本仲裁条款适用的法律应为中华人民共和国香港特别行政区（「香港」）法律。',
  'components.simple.v5k5kn':
    'c.您同意仲裁地为香港。仲裁人的数量应为三（3）名。币趣应指定一（1）名仲裁员，您应指定一（1）名仲裁员。第三位仲裁员应由HKIAC的主席指定。该等仲裁员应自由选择，各方在选择仲裁员时不受任何指定名单的限制。仲裁程序应以英文进行。',
  'components.simple.pu2327':
    'd.您同意币趣不应被要求提供文件的一般发现信息，而仅可被要求提供与争议结果相关且对争议结果有实质意义的特定的、经识别的文件。',
  'components.simple.w86687':
    'e.任何仲裁裁决应为终局裁决，对各方均具有约束力，并可在任何有管辖权的法院被强制执行。',
  'components.simple.bw64il':
    '10.8未经币趣事先书面同意，您不得全部或部分转让、更新或让本协议及本协议下的权利义务。币趣有权通过向您发出书面通知的方式转让、更替或让与本协议项下的任何权利或义务，该通知自送达之日起生效。',
  'components.simple.137yu5':
    '10.9本协议所有条款的标题仅为阅读方便，本身并无实质涵义，不能作为本协议意思解释的依据。',
  'components.simple.co7ivn':
    '10.10若本协议内容与其他语言的翻译版本的内容有冲突，以英文版的本协议内容为准。',
  'components.simple.4i87f9': '10.11 本协议的最终解释权归币趣所有。',
};
