import { createI18n } from 'vue-i18n';
import en from './en-US';
import cn from './zh-CN';
import tw from './zh-TW';
import vi from './vn';
import hi from './hi';

export const LOCALE_OPTIONS = [
  { label: 'English', value: 'en' },
  { label: '繁體中文', value: 'zh-TW' },
  { label: '简体中文', value: 'zh-CN' },
  { label: 'Tiếng Việt', value: 'vi' }, // 越南语
  { label: 'हिंदी', value: 'hi' }, // 印地语
];

// 设置 HTML 的语言
const htmlElement = document.documentElement;
const userLang = navigator.language || 'en';

const languageMap: any = {
  'zh-CN': ['zh', 'zh-CN', 'zh-Hans'],
  'zh-TW': ['zh-TW', 'zh-Hant'],
  'vi': ['vn', 'vi', 'vi-VN'], // 越南语
  'en': ['en', 'en-US'], // 英语
  'hi': ['hi'], // 印地语

  // 'es': ['es', 'es-ES'], // 西班牙语
  // 'pt': ['pt', 'pt-BR', 'pt-PT'], // 葡萄牙语
  // 'fr': ['fr', 'fr-FR', 'fr-CA', 'fr-CH'], // 法语
  // 'th': ['th'], // 泰语
  // 'ko': ['ko', 'ko-KR', 'ko-KP'], // 韩语
  // 'ja': ['ja', 'ja-JP'], // 日语
  // 'id': ['id', 'id-ID'], // 印度尼西亚语
  // 'km': ['km', 'km-KH'], // 柬埔寨语
  // 'my': ['my', 'ms', 'ms-MY'], // 马来西亚语
  // 'tr': ['tr', 'tr-TR'], // 土耳其语
};

// 检查和设置语言
const setLanguage = (): string => {
  let matchedLang = 'en';
  Object.keys(languageMap).forEach((lang) => {
    if (languageMap[lang].some((code: any) => userLang.startsWith(code))) {
      matchedLang = lang;
    }
  });
  htmlElement.setAttribute('lang', matchedLang);
  return matchedLang;
};

const defaultLocale = localStorage.getItem('arco-locale') || setLanguage();

localStorage.setItem('arco-locale', defaultLocale);
htmlElement.setAttribute('lang', defaultLocale);

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  allowComposition: true,
  messages: {
    'en': en,
    'zh-CN': cn,
    'zh-TW': tw,
    'vi': vi,
    'hi': hi,
  },
});

export default i18n;
