import axios from 'axios';

export interface MessageRecord {
  id: number;
  type: string;
  title: string;
  subTitle: string;
  avatar?: string;
  content: string;
  time: string;
  status: 0 | 1;
  messageType?: number;
}
export type MessageListType = MessageRecord[];

export function queryMessageList() {
  return axios.post<MessageListType>('/api/message/list');
}

interface MessageStatus {
  ids: number[];
}

export function setMessageStatus(data: MessageStatus) {
  return axios.post<MessageListType>('/api/message/read', data);
}

export interface ChatRecord {
  id: number;
  username: string;
  content: string;
  time: string;
  isCollect: boolean;
}

export function queryChatList() {
  return axios.post<ChatRecord[]>('/api/chat/list');
}

// 获取消息列表
export function messageListApi(data: object) {
  return axios.post<string>('/support/notifications/list', data);
}

// 查看消息详情
export function notificationsId(id: any) {
  return axios.get<string>(`/support/notifications/${id}`);
}
// 重要公告
export function notificationsImportant(params: any) {
  return axios.get(`/support/notifications/important`, { params });
}
// 逐一删除
export function notificationsDel(id: any) {
  return axios.delete(`/support/notifications/${id}`);
}
// 全部删除
export function notificationsDelAll(params: any) {
  return axios.delete(`/support/notifications/deleteAll`, { params });
}
// 全部已读
export function notificationsReadAll(type: any) {
  return axios.post(`/support/notifications/readAll?notifyType=${type}`);
}
