import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/accountsecurity',
  name: 'AccountSecurity',
  component: DEFAULT_LAYOUT,
  redirect: '/accountsecurity/accountIndex',
  meta: {
    locale: 'navbar.index.n35329',
    requiresAuth: true,
    icon: 'icon-safety',
    order: 1,
    hideInMenu: false,
    activeMenu: 'AccountSecurity',
    title: 'navbar.index.n35329',
  },

  children: [
    {
      path: 'accountIndex',
      name: 'AccountIndex',
      component: () => import('@/views/dashboard/accountsecurity/index.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    //     {
    //   path: 'disableAcc',
    //   name: 'DisableAcc',
    //   component: () => import('@/views/dashboard/accountsecurity/disableacc/index.vue'),
    //   meta: {
    //     locale: 'menu.overview',
    //     requiresAuth: true,
    //     roles: ['*'],
    //     hideInMenu: true,
    //   },
    // },
    {
      path: 'setFish',
      name: 'SetFish',
      component: () =>
        import('@/views/dashboard/accountsecurity/fish/setFinsh.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'changeFish',
      name: 'ChangeFish',
      component: () =>
        import('@/views/dashboard/accountsecurity/fish/changeFish.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'device',
      name: 'Device',
      component: () =>
        import('@/views/dashboard/accountsecurity/device/index.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'accAction',
      name: 'AccAction',
      component: () =>
        import('@/views/dashboard/accountsecurity/accaction/index.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'bindGoogle',
      name: 'BindGoogle',
      component: () =>
        import('@/views/dashboard/accountsecurity/google/bindGoogle.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'changeGoogle',
      name: 'ChangeGoogle',
      component: () =>
        import('@/views/dashboard/accountsecurity/google/changeGoogle.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'unbindGoogle',
      name: 'UnbindGoogle',
      component: () =>
        import('@/views/dashboard/accountsecurity/google/unbindGoogle.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'unbindPhone',
      name: 'UnbindPhone',
      component: () =>
        import('@/views/dashboard/accountsecurity/phone/unbindPhone.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'bindPhone',
      name: 'BindPhone',
      component: () =>
        import('@/views/dashboard/accountsecurity/phone/bindPhone.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'changePhone',
      name: 'ChangePhone',
      component: () =>
        import('@/views/dashboard/accountsecurity/phone/changePhone.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'other',
      name: 'Other',
      component: () =>
        import('@/views/dashboard/accountsecurity/reset/index.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'address',
      name: 'Address',
      component: () =>
        import('@/views/dashboard/accountsecurity/address/index.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'batchAddAddress',
      name: 'BatchAddAddress',
      component: () =>
        import('@/views/dashboard/accountsecurity/address/batchAddAddress.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'changePassword',
      name: 'ChangePassword',
      component: () =>
        import('@/views/dashboard/accountsecurity/changepassword/index.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'changeEmail',
      name: 'ChangeEmail',
      component: () =>
        import('@/views/dashboard/accountsecurity/email/changeEmail.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'bindEmail',
      name: 'BindEmail',
      component: () =>
        import('@/views/dashboard/accountsecurity/email/bindEmail.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'unbindEmail',
      name: 'UnbindEmail',
      component: () =>
        import('@/views/dashboard/accountsecurity/email/unbindEmail.vue'),
      meta: {
        locale: 'navbar.index.n35329',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
  ],
};

export default DASHBOARD;
