<template>
  <a-modal
    v-model:visible="setVisible"
    :footer="false"
    :header="false"
    :closable="false"
    :mask-closable="false"
  >
    <div class="bigBox">
      <div class="safe-header">
        <p>{{ title }}</p>
        <icon-close @click="handleCancel" />
      </div>
      <p class="header-tips">{{ message }}</p>
      <slot name="name"></slot>
      <slot name="language"></slot>
      <slot name="image"></slot>
      <slot name="letter"></slot>
      <div class="footer-btn">
        <a-button size="large" @click="handleCancel">{{
          dialogCloseBtnText || $t('address.batchaddaddress.5g5at6')
        }}</a-button>
        <a-button
          type="primary"
          size="large"
          :loading="isLoading"
          @click="handleSuccess"
          >{{ dialogSuccessBtnText || $t('fish.changefish.dteu66') }}</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
  import { ref, toRefs, watch } from 'vue';

  const props = defineProps({
    dialogSetVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '编辑昵称',
    },
    message: {
      type: String,
      default: '为您的个人资料设置自定义昵称。',
    },
    dialogCloseBtnText: {
      type: String,
      default: '',
    },
    dialogSuccessBtnText: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  });

  const refProps = toRefs(props);
  const emits = defineEmits(['update:dialogSetVisible', 'myClick']); // 此处需写'update'
  const setVisible = ref(false);
  watch(refProps.dialogSetVisible, (val, old) => {
    setVisible.value = val;
  });
  watch(setVisible, (val, old) => {
    emits('update:dialogSetVisible', val); // 此处的update亦要写
  });

  const handleCancel = () => {
    emits('myClick', false);
    setVisible.value = false;
  };

  const handleSuccess = () => {
    emits('myClick', true);
  };
</script>

<style lang="less" scoped>
  @media (max-width: 768px) {
    .bigBox {
      width: @modalApp;
    }
  }
  @media (min-width: 769px) {
    .bigBox {
      width: 480px;
    }
  }
  // 弹窗
  .header-tips {
    font-size: 13px;
    color: var(--color-text-2);
  }

  .footer-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    width: 100%;
    button {
      width: 48%;
      height: 40px;
      border-radius: 5px;
    }
    :deep(.arco-btn-secondary) {
      margin-right: 36px;
    }
  }
  .safe-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
    font-size: 16px;
    & > .arco-icon {
      cursor: pointer;
      color: var(--color-text-3);
    }
    & > p {
      margin: 0;
    }
  }
</style>
