export default {
  'settings.title': 'Cấu hình trang',
  'settings.themeColor': 'Màu chủ đề',
  'settings.content': 'Khu vực nội dung',
  'settings.search': 'Tìm kiếm',
  'settings.language': 'Ngôn ngữ',
  'settings.navbar': 'Thanh điều hướng',
  'settings.menuWidth': 'Độ rộng menu (px)',
  'settings.navbar.theme.toLight': 'Nhấp để chuyển sang chế độ sáng',
  'settings.navbar.theme.toDark': 'Nhấp để chuyển sang chế độ tối',
  'settings.navbar.screen.toFull': 'Nhấp để chuyển sang chế độ toàn màn hình',
  'settings.navbar.screen.toExit': 'Nhấp để thoát chế độ toàn màn hình',
  'settings.navbar.alerts': 'Thông báo',
  'settings.menu': 'Thanh menu',
  'settings.tabBar': 'Tab đa trang',
  'settings.footer': 'Chân trang',
  'settings.otherSettings': 'Cài đặt khác',
  'settings.colorWeak': 'Chế độ màu yếu',
  'settings.alertContent':
    'Cấu hình chỉ có hiệu lực tạm thời, để áp dụng thực sự cho dự án, hãy nhấp vào nút "Sao chép cấu hình" bên dưới và thay thế cấu hình trong settings.json.',
  'settings.copySettings': 'Sao chép cấu hình',
  'settings.copySettings.message':
    'Sao chép thành công, vui lòng dán vào tệp src/settings.json',
  'settings.close': 'Đóng',
  'settings.color.tooltip':
    '10 màu gradient được tạo dựa trên màu chủ đề (sao chép cấu hình vào dự án để màu chủ đề có hiệu lực cho cả chế độ sáng/tối)',
  'settings.menuFromServer': 'Menu từ máy chủ',
};


