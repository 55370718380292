<template>
  <VCharts
    v-if="renderChart"
    ref="chartRef"
    :option="options"
    :autoresize="autoResize"
    :style="{ width, height }"
  />
</template>

<script lang="ts" setup>
  import { ref, nextTick, onMounted, onUnmounted } from 'vue';
  import VCharts from 'vue-echarts';
  // import { useAppStore } from '@/store';

  defineProps({
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  });
  // const appStore = useAppStore();
  // const theme = computed(() => {
  //   if (appStore.theme === 'dark') return 'dark';
  //   return '';
  // });
  const renderChart = ref(false);
  // wait container expand
  nextTick(() => {
    renderChart.value = true;
  });

  const chartRef = ref<any>(null);

  // 调整图表大小的函数
  const resizeChart = () => {
    if (chartRef.value) {
      chartRef.value.resize();
    }
  };

  // 监听窗口大小变化
  const handleResize = () => {
    resizeChart();
  };

  // 挂载和卸载时的生命周期钩子
  onMounted(() => {
    // 监听窗口 resize 事件
    window.addEventListener('resize', handleResize);
    // 初始化图表尺寸
    resizeChart();
  });

  onUnmounted(() => {
    // 移除窗口 resize 事件监听
    window.removeEventListener('resize', handleResize);
  });
</script>

<style scoped lang="less"></style>
