export default {
  'login.form.title': 'Login to BasicEx',  
  'register.form.title': 'Register for BasicEx',
  'login.form.email': 'Email',
  'login.form.phone': 'Phone Number',
  'login.account': "Don't have an account?",
  'register.account': 'Already have an account?',
  'login.register': 'Register Now',
  'register.login': 'Login Now',
  'login.forget': 'Forgot Password',
  'login.pass': 'Password',
  'login.form.userName.errMsg': 'Email cannot be empty',
  'login.form.phone.errMsg': 'Phone number cannot be empty',
  'login.form.userName.placeholder': 'Please enter your email',
  'login.form.phone.userName.placeholder': 'Please enter your phone number',
  'login.form.password.errMsg': 'Password cannot be empty',
  'login.form.password.placeholder': 'Please enter your password',
  'login.form.code.errMsg': 'Verification code cannot be empty',
  'login.form.code.placeholder': 'Please enter the verification code',
  'login.form.code.sent': 'Send Code',
  'login.form.code.time': 'seconds to retry',
  'login.form.read': 'I have read and agree to BasicEx\'s ',
  'login.form.ServiceAgreement': 'Service Agreement ',
  'login.form.and': 'and ',
  'login.form.PrivacyPolicy': 'Privacy Policy ',
  'login.form.login': 'Login',
  'register.form.register': 'Register',
  'login.form.code.tip': 'The verification code must be a 6-digit number',
  'login.form.code.read': 'Please read and agree to the Service Agreement and Privacy Policy',
  'register.password.different': 'The passwords entered do not match',
  'register.form': 'The passwords entered do not match',
  'register.email': 'Please enter a valid email',
  'register.phone': 'Please enter a valid phone number',
  'register.password': 'The password must be at least 8 characters long and contain a combination of letters (uppercase and lowercase) and numbers',
  'forget.password': 'Reset Password',
  'forget.input': 'Please enter your email/phone number',
  'login.user': 'email/phone number',
  'forget.input-err': 'Email/phone number cannot be empty',
  'forget.newPassword': 'New Password',
  'forget.newPasswordCopy': 'Confirm Password',
  'forget.success': 'Password changed successfully',
  'forget.login': 'Please log in with the new password',
  'ver.number': 'Security verification',
  'ver.OTP_CODE': 'Google Authenticator App',
'ver.EMAIL_CODE': 'Email Verification Code',
'ver.PHONE_CODE': 'Phone Verification Code',
'ver.EMAIL': 'Email',
'ver.PHONE': 'Phone',
'ver.PIN_CODE': 'Payment PIN Code',
'ver.code-err': 'Verification code cannot be empty',
'ver.welcome': 'Welcome back',
'res.text1': 'Create Personal Account'
};
