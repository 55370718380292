import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/setupp',
  name: 'Setupp',
  component: DEFAULT_LAYOUT,
  redirect: '/setupp/setIndex',
  meta: {
    locale: 'navbar.index.xg8agy',
    requiresAuth: true,
    icon: 'icon-set',
    order: 8,
    hideInMenu: false,
    activeMenu: 'Setupp',
    title: 'navbar.index.xg8agy',
  },
  children: [
    {
      path: 'setIndex',
      name: 'SetIndex',
      component: () => import('@/views/dashboard/setupp/index.vue'),
      meta: {
        locale: 'menu.overview',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
  ],
};

export default DASHBOARD;
