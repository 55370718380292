import type { Router, LocationQueryRaw } from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import { isLogin, isLoginDev } from '@/utils/auth';
import getPageTitle from '@/utils/get-title';
import getLongToken from '@/utils/entry';
import { useUserStore } from '@/store';

export default function setupUserLoginInfoGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    document.title = getPageTitle(to.meta.title);
    NProgress.start();
    const userStore = useUserStore();
    // if (isLogin() || isLoginDev()) {
    //   userStore.getNotification();
    // }
    if (
      isLogin() ||
      isLoginDev() ||
      to.name === 'RegisterVer' ||
      to.name === 'StepVerification' ||
      to.name === 'ForgetPassword' ||
      to.name === 'ResetPassword' ||
      to.name === 'ForgetVerification' ||
      to.name === 'Resigter' ||
      to.name === 'ServiceAgreement' ||
      to.name === 'PrivacyPolicy' ||
      to.name === 'Home' ||
      to.name === 'Down' ||
      to.name === 'Verifying' ||
      to.name === 'Enforcement' ||
      to.name === 'Disclosure' ||
      to.name === 'WaitingSubmit' ||
      to.name === 'LegalInformation' ||
      to.name === 'QrRouter' ||
      to.name === 'ErmsSimple'
    ) {
      next();
    } else {
      if (to.name === 'Login') {
        next();
        return;
      }
      next({
        name: 'Login',
        query: {
          redirect: to.name,
          ...to.query,
        } as LocationQueryRaw,
      });
    }
  });
}
